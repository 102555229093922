import React, { useState, useEffect ,useMemo} from "react";
import { OverlayTrigger, Popover, Form } from "react-bootstrap";
import { ReactComponent as DotIcon } from "../../../../assets/images/threedots.svg";
import { ReactComponent as AddIcon } from "../../../../assets/images/add.svg";
import { ReactComponent as ConfirmIcon } from "../../../../assets/images/check.svg";
import { ReactComponent as CancelIcon } from "../../../../assets/images/cancel.svg";
import BaseTable from "../../../../components/table/BaseTable";
import BaseInput from "../../../../components/form/BaseInput";
import BaseFilter from "../../../../components/filter/BaseFilter";
import AddOrganizationModal from "../organizaton/AddOrganizationalModal";
import { showToast } from "../../../../util/toasts";
import DeleteModal from "../../../../components/modal/DeleteModal";
import axios from "../../../../util/axios";
import { parseFilterString, applyCondition } from "../../../../util/conditions";
import { adminOrganizationsTabsConfig } from "../../../../util/tabsConfig.js";
import { formatDate } from "../../../../util/formatDate.js";  

const Organizaton = ({ setIsActiveLink, handleOrgName }) => {
  const [popoverId, setPopoverId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState(null);
  const [data, setData] = useState([]);
  const [editedRow, setEditedRow] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showScanModal, setShowScanModal] = useState(false);
  const [scanId, setScanId] = useState(null);
  const [allColumnsObj, setAllColumnsObj] = useState([]);
  const [loadingScanId, setLoadingScanId] = useState(null);
  const initialColumns = [
    { header: "Organization", key: "org_name", visibility: true,default: true },
    { header: "Type", key: "type", visibility: true ,default: true},
    { header: "User Count", key: "users_count", visibility: true ,default: true},
    { header: "# Used Addresses", key: "usedLimit", visibility: true },
    { header: "IP Limit - License", key: "alert_limit", visibility: true },
    { header: "IP Limit - Block", key: "ip_limit", visibility: true },
    { header: "Last Scanned", key: "last_scanned", visibility: true },
    { header: "Scan Finished", key: "scanFinished", visibility: true },
    { header: "", key: "actions", visibility: true ,default: true}, 
  ];
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const updateTabsWithData = (org) => {
    const organizations = [
      ...new Set(org.filter((el) => el?.org_name).map((el) => el.org_name)),
    ].map((org_name) => ({
      id: org_name,
      name: org_name,
      type: "Organization: " + org_name,
      key: "1",
      active: false,
    }));

    const types = ["Client", "Testing"].map((type) => ({
      id: type,
      name: type,
      type: "Type: " + type,
      key: "2",
      active: false,
    }));

    const usersCount = [
      ...new Set(
        org.filter((el) => el?.users_count).map((el) => el.users_count)
      ),
    ].map((users_count) => ({
      id: users_count,
      name: users_count,
      type: "User Count: " + users_count,
      key: "3",
      active: false,
    }));

    const usedAddress = [
      ...new Set(org.filter((el) => el?.usedLimit).map((el) => el.usedLimit)),
    ].map((usedLimit) => ({
      id: usedLimit,
      name: usedLimit,
      type: "# Used Addresses: " + usedLimit,
      key: "4",
      active: false,
    }));

    const ipLicense = [
      ...new Set(
        org.filter((el) => el?.alert_limit).map((el) => el.alert_limit)
      ),
    ].map((alert_limit) => ({
      id: alert_limit,
      name: alert_limit,
      type: "IP Limit - License: " + alert_limit,
      key: "5",
      active: false,
    }));

    const ipBlock = [
      ...new Set(org.filter((el) => el?.ip_limit).map((el) => el.ip_limit)),
    ].map((ip_limit) => ({
      id: ip_limit,
      name: ip_limit,
      type: "IP Limit - Block: " + ip_limit,
      key: "6",
      active: false,
    }));

    setTabs(
      adminOrganizationsTabsConfig({
        organizations,
        types,
        usersCount,
        usedAddress,
        ipLicense,
        ipBlock,
      })
    );
  };

  const handleShowPopover = (id) => {
    setPopoverId((prevId) => (prevId === id ? null : id));
  };  

  const handleClose = () => {
    setShowModal(false);
    setDeleteId(null);
    setPopoverId(null);
  };

  const handleConfirmDelete = async () => {
    try {
      const { data } = await axios.delete(`organizations?id=${deleteId}`);
      fetchOrganization();
      setShowModal(false);
      showToast("Organization Deleted Successfully", "success");
    } catch (error) {
      setShowModal(false);
      console.error("Error:", error);
    }
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setShowModal(true);
    setPopoverId(null);
  };

  const handleShowAddModal = () => setShowAddModal(true);
  const handleCloseAddModal = () => setShowAddModal(false);

  const handleEdit = (id) => {
    setIsEdit(true);
    setId(id);
    const currentRow = data.find((row) => row.id === id);
    setEditedRow(currentRow);
  };

  const handleFieldChange = (field, value) => {
    setEditedRow((prevRow) => ({ ...prevRow, [field]: value }));
  };

  const handleConfirmEdit = async () => {
    try {
      const { data } = await axios.post("organizations/update", {
        id: editedRow?.id,
        org_name: editedRow?.org_name,
        ip_limit: editedRow?.ip_limit,
        alert_limit: editedRow?.alert_limit,
        type: editedRow?.type,
      });
      fetchOrganization();
      showToast(`Changes Saved Successfully`, "success");
      setIsEdit(false);
      setId(null);
      setEditedRow(null);
    } catch (error) {
      setIsEdit(false);
      setId(null);
      setEditedRow(null);
      console.error("Error:", error);
    }
  };

  const handleCancelEdit = () => {
    setIsEdit(false);
    setId(null);
    setEditedRow(null);
  };

  const fetchOrganization = async () => {
    try {
      const { data } = await axios.get("organizations", {
        params: { page: 1, pageSize: 1000 },
      });
      if (data?.items) {
        setData(data?.items);
        updateTabsWithData(data?.items);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };
  
  const actionPopover = (id,scanFinished) => {
    const isLoading = loadingScanId === id;
    return (
      <Popover id={`popover-${id}`}>
        <Popover.Body className="admin-popover">
        <div
          className={`popover-item ${
            !scanFinished || isLoading ? "disabled-option" : ""
          }`}
          onClick={() => {
            if (scanFinished && !isLoading) handleScan(id);
          }}
          style={{
            cursor: !scanFinished || isLoading ? "not-allowed" : "pointer",
          }}
        >
          Scan Now
        </div>
          <div className="popover-item" onClick={() => handleEdit(id)}>
            Edit
          </div>
          <div className="popover-item" onClick={() => handleDelete(id)}>
            Delete
          </div>
        </Popover.Body>
      </Popover>
    );
  };
  


   const filteredColumns = useMemo(() => {

  const columns = [
    {
      Header: "Organization",
      key: "org_name",
      accessor: (row) =>
        row && isEdit && id === row.id ? (
          <div className="edit-input">
            <BaseInput
              type="text"
              className="base-input"
              defaultValue={editedRow.org_name}
              onChange={(e) =>
                handleFieldChange("org_name", e.target.value?.trim())
              }
            />
          </div>
        ) : (
          <span>{row ? row.org_name : "-"}</span>
        ),
      isSortable: true,
    },
    {
      Header: "Type",
      key: "type",
      isSortable: true,
      accessor: (row) =>
        row && isEdit && id === row.id ? (
          <div
            className="edit-input"
            style={{
              width: 130,
            }}
          >
            <Form.Select
              defaultValue={editedRow.type}
              style={{ border: "1px solid white", height: "40px" }}
              className="base-input"
              onChange={(e) => handleFieldChange("type", e.target.value)}
            >
              <option value="Client">Client</option>
              <option value="Testing">Testing</option>
            </Form.Select>
          </div>
        ) : (
          <span>{row ? row.type : "-"}</span>
        ),
    },
    {
      Header: "User Count",
      key: "users_count",
      isSortable: true,
      accessor: (row) => (
        <span
          style={{
            color: "#93C2FF",
            textDecoration: row?.users_count > 0 ? "underline" : "initial",
            cursor: row?.users_count > 0 ? "pointer" : "initial",
          }}
          onClick={() => {
            if (row?.users_count) {
              setIsActiveLink("Users");
              handleOrgName(row.org_name);
            }
          }}
        >
          {row?.users_count ? row?.users_count : "0"}
        </span>
      ),
    },
    {
      Header: "# Used Addresses",
      accessor: "usedLimit",
      key:"usedLimit",
      isSortable: true,
    },
    {
      Header: "IP Limit - License",
      key: "alert_limit",
      isSortable: true,
      accessor: (row) =>
        row && isEdit && id === row.id ? (
          <div
            className="edit-input"
            style={{
              width: 140,
            }}
          >
            <BaseInput
              type="number"
              className="base-input"
              defaultValue={editedRow.alert_limit}
              onChange={(e) => handleFieldChange("alert_limit", e.target.value)}
            />
          </div>
        ) : (
          <span>{row ? row.alert_limit : ""}</span>
        ),
    },
    {
      Header: "IP Limit - Block",
      key: "ip_limit",
      isSortable: true,
      accessor: (row) =>
        row && isEdit && id === row.id ? (
          <div
            className="edit-input"
            style={{
              width: 140,
            }}
          >
            <BaseInput
              type="number"
              className="base-input"
              defaultValue={editedRow.ip_limit}
              onChange={(e) => handleFieldChange("ip_limit", e.target.value)}
            />
          </div>
        ) : (
          <span>{row ? row.ip_limit : ""}</span>
        ),
    },
    {
      Header: "Last Scanned",
      key: "last_scanned",
      isSortable: true,
      visibility: true,
      accessor: (row) => (
        <span>
          {row?.lastScanned
            ? formatDate(new Date(row.lastScanned), "MMM D yyyy, hh:mm a")
            : "-"}
        </span>
      ),
    },
    {
      Header: "Scan Finished",
      key: "scanFinished",
      isSortable: true,
      visibility: true,
      accessor: (row) => (
        <span>{row.scanFinished ? "Yes" : "No"}</span>
      ),
    },  
    {
      Header: "",
      key:"actions",
      accessor: (row) =>
        row && isEdit && id === row.id ? (
          <div className="d-flex gap-2">
            <span className="editicon" onClick={handleConfirmEdit}>
              <ConfirmIcon height={18} className="cursor-pointer " />
            </span>
            <span className="editicon" onClick={handleCancelEdit}>
              <CancelIcon height={15} className="cursor-pointer" />
            </span>
          </div>
        ) : (
          row && (
            <OverlayTrigger
            trigger="click"
            placement="left"
            overlay={actionPopover(row.id, row.scanFinished)}
            show={popoverId === row.id}
            rootClose
          >
            <div
              className="text-end"
              onClick={(event) => {
                event.stopPropagation();
                handleShowPopover(row.id);
              }}
            >
              <DotIcon className="cursor-pointer" />
            </div>
          </OverlayTrigger>
          
          
          )
        ),
    },
  ];
  return columns.filter((col) => {
    const found = allColumnsObj.find((sc) => sc.key === col.key);
    return found ? found.visibility : false;
  });
}, [
  allColumnsObj,popoverId,isEdit,id,editedRow
]);

useEffect(() => {
  const fetchManageColumns = async () => {
    try {
      const { data } = await axios.get("userSettings?table_name=admin_organization");
      if (data?.columns && data.table_name === "admin_organization") {
        const updatedColumns = initialColumns.map((col) => ({
          ...col,
          visibility: data.columns.includes(col.key),
        }));
        setAllColumnsObj(updatedColumns);
      } else {        
        setAllColumnsObj(initialColumns);
      }
    } catch (error) {
      console.error(error);
      setAllColumnsObj(initialColumns);
    }
  };

  fetchManageColumns();
}, []);

// Update column visibility settings to backend
useEffect(() => {
  const updateColumnSettings = async () => {
    try {
      const visibleColumns = allColumnsObj
        .filter((col) => col.visibility)
        .map((col) => col.key)
        .join(",");
      await axios.post("updateUserSettings", {
        table_name: "admin_organization",
        columns: visibleColumns,
      });
    } catch (error) {
      console.error(error);
    }
  };

  if (allColumnsObj.length > 0) {
    updateColumnSettings();
  }
}, [allColumnsObj]);
  

 const handleCloseScanModal = () => {
  setShowScanModal(false);
  setScanId(null);
};

const handleConfirmScan = async () => {
  try {
    const payload = {
      id: scanId, 
    };    
    const { data } = await axios.post("organizations/scan-now", payload);
    showToast(data.msg, "success");
    setShowScanModal(false);
  } catch (error) {
    showToast(error?.response?.data?.msg, "error");
    setShowScanModal(false);
  }
};


const handleScan = (id) => {
  setScanId(id);
  setShowScanModal(true);
};
useEffect(() => {
  const handleClickOutside = () => {
    if (popoverId) {
      setPopoverId(null);
    }
  };
  document.addEventListener("click", handleClickOutside);
  return () => {
    document.removeEventListener("click", handleClickOutside);
  };
}, [popoverId]);


  useEffect(() => {
    fetchOrganization();
  }, []);

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const filteredOrganizations = data.filter((org) => {
    const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
      return groupedFilters[eventKey].some((filter) => {
        if (eventKey === "1") {
          return (
            org.org_name &&
            org.org_name?.toLowerCase() === filter.name?.toLowerCase()
          );
        } else if (eventKey === "2") {
          return (
            org.type && org.type?.toLowerCase() === filter.name?.toLowerCase()
          );
        } else if (eventKey === "3") {
          return org.users_count && org.users_count === filter.name;
        } else if (eventKey === "4") {
          return org.usedLimit && org.usedLimit === filter.name;
        } else if (eventKey === "5") {
          return org.alert_limit && org.alert_limit === filter.name;
        } else if (eventKey === "6") {
          return org.ip_limit && org.ip_limit === filter.name;
        } else if (eventKey === "advanced-filter") {
          const parsedFilters = parseFilterString(filter.name);
          return parsedFilters.every((ol) => {
            const { column, condition, value } = ol;
            switch (column) {
              case "org_name":
                return applyCondition(
                  org.org_name?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "type":
                return applyCondition(
                  org.type?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "users_count":
                return applyCondition(`${org.users_count}`, condition, value);
              case "usedLimit":
                return applyCondition(`${org.usedLimit}`, condition, value);
              case "alert_limit":
                return applyCondition(`${org.alert_limit}`, condition, value);
              case "ip_limit":
                return applyCondition(`${org.ip_limit}`, condition, value);
              default:
                return false;
            }
          });
        }
        return false;
      });
    });

    const matchesSearch =
      searchValue === "" ||
      (org?.org_name &&
        org?.org_name.toLowerCase().includes(searchValue.toLowerCase()));

    return matchesFilters && matchesSearch;
  });


  return (
    <>
      <div className="base-filter-container">
        <div className="left-filter">
          <BaseFilter
            tabs={tabs}
            className="mb-3"
            totalRecords={filteredOrganizations.length}
            onFilterChange={handleFilterChange}
            activeFilters={activeFilters}
            removeFilter={removeFilter}
            showExport={false}
            searchValue={searchValue}
            onSearchChange={handleSearchChange}
            isSearch={true}
            tableData={filteredOrganizations}
            isManageColumn={true} // Enable column management
            allColumns={allColumnsObj} // Pass all columns for visibility management
            setAllColumns={setAllColumnsObj} // Setter for column visibility updates
          />
        </div>
        <div
          className="add-button d-flex align-items-center mb-2"
          onClick={handleShowAddModal}
        >
          <span className="mb-1">
            <AddIcon />
          </span>
          <span className="add-organization ms-1">Add Organization</span>
        </div>
      </div>

      <div className="assets-discovery-table  custom-x-scroll-table">
        <BaseTable
          className="mt-3 mb-3"
          columns={filteredColumns}
          data={filteredOrganizations}
          selectable={true}
          showCheckboxes={false}
          isStickyScroll
        />
      </div>

      <AddOrganizationModal
        show={showAddModal}
        handleClose={handleCloseAddModal}
        fetchOrganization={fetchOrganization}
      />

      <DeleteModal
        showModal={showModal}
        handleClose={handleClose}
        handleConfirmDelete={handleConfirmDelete}
        title={"Are you sure you want to delete this organization?"}
        subTitle={"Deleting the organization is irreversible"}
        showCheckbox={true}
      />
         <DeleteModal
        showModal={showScanModal}
        handleClose={handleCloseScanModal}
        handleConfirmDelete={handleConfirmScan}
        title={"Are You Sure You Want To Start Scanning Now?"}
        subTitle={""}
        showCheckbox={false}
        isDelete={false}
      /> 

    </>
  );
};

export default Organizaton;
