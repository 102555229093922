import React, { useState, useContext, useRef, useEffect } from "react";
import { ReactComponent as Arrow } from "../../assets/images/port-arrow.svg";
import { ReactComponent as EmptyIcon } from "../../assets/images/asset-empty.svg";
import { ReactComponent as GlobeIcon } from "../../assets/images/globe-check.svg";
import { ReactComponent as CancelIcon } from "../../assets/images/cancel-white.svg";
import { ReactComponent as SearchDecreaseIcon } from "../../assets/images/asset-search.svg";
import { ReactComponent as RefreshIcon } from "../../assets/images/refresh-scan.svg";
import BaseCheckbox from "../../components/form/BaseCheckbox";
import ScanDomains from "./ScanDomains";
import ConfirmationModal from "./ConfirmationModal";
import BaseFilter from "../../components/filter/BaseFilter";
import DomainScanTable from "./DomainScanTable";
import DomainModal from "./DomainModal";
import BulkSelection from "../../components/bulk-selection/BulkSelection.js";
import { useParams } from "react-router-dom";
import { ThreeCircles } from "react-loader-spinner";
import { ScannerContext } from "../../components/ScannerContext";
import axios from "../../util/axios";
import { formatScannerName } from "../../helpers/formatScannerName.js";
import moment from "moment";
import { newAssetDiscoveryTabsConfig } from "../../util/tabsConfig.js";
import { getAssetDiscoveryFilteredData } from "../../util/genericFunctions.js";
import { showToast } from "../../util/toasts";
import { formatDate } from "../../util/formatDate.js";

const AssetDiscovery = ({ setScanStatus, scanStatus ,isUser}) => {
  const routeParams = useParams();
  const { dropdownItems } = useContext(ScannerContext);
  const [isDatainProcess, setIsDataInProcess] = useState(false);
  const [isDataisPartiallyProcessing, setIsDataisPartiallyProcessing] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeFilters, setActiveFilters] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [domainToScan, setDomainToScan] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedSubDomains, setSelectedSubDomains] = useState([]);
  const [isTableModalOpen, setIsTableModalOpen] = useState(false);
  const [domainToScanTable, setDomainToScanTable] = useState(null);
  const [isRejectMode, setIsRejectMode] = useState(false);
  const [showDomain, setShowDomain] = useState(true);
  const [assetsDiscovery, setAssetsDiscovery] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [allDomains, setAllDomains] = useState([]);
  const [isDomainLoading, setIsDomainLoading] = useState(false);
  const [showQuickDomainApproval, setShowQuickDomainApproval] = useState(true);

  const fetchScanStatus = async () => {
    try {
      const { data } = await axios.get(`/scans/${routeParams?.id}`);
      if (data?.scanInfo?.status == -1) {
        setScanStatus(4);
      } else {
        setScanStatus(data?.servicesStatus?.[0].status);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error("Error:", err);
    }
  };

  const handleActionSelect = (action) => {
    setIsRejectMode(action.name == "Reject" ? true : false);
    setIsTableModalOpen(true);
    setShowDomain(false);

    if (selectedSubDomains.length === 1) {
      setDomainToScanTable(selectedSubDomains[0]);
    } else {
      setDomainToScanTable(selectedSubDomains);
    }
  };

  const handleClearAllSelections = () => {
    setSelectedSubDomains([]);
  };

  const handleCloseTableModal = () => {
    setIsTableModalOpen(false);
    setSelectedSubDomains([]);
    setDomainToScanTable(null);
    setIsRejectMode(false);
  };

  const handleConfirmAction = (domainsToDisplay, isReject, isDomain) => {
    const matchingIds = [];
    if (isDomain) {
      assetsDiscovery
        .filter((item) => domainsToDisplay.includes(item.host))
        .map((item) => {
          matchingIds.push(item.id);
        });
    } else {
      assetsDiscovery.flatMap((entry) => {
        return entry.subdomains
          .filter((subdomain) =>
            domainsToDisplay.includes(subdomain.host || subdomain.ip)
          )
          .map((subdomain) => {
            matchingIds.push(subdomain.id);
          });
      });
    }
    handleDomainActions(
      matchingIds,
      isReject ? -1 : 1,
      isDomain ? "domain" : "host"
    );
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleCheckboxChange = (domain) => {
    if (selectedDomains.includes(domain)) {
      setSelectedDomains(selectedDomains.filter((d) => d !== domain));
    } else {
      setSelectedDomains([...selectedDomains, domain]);
    }
  };

  const handleSelectAll = () => {
    if (selectedDomains.length === allDomains.length) {
      setSelectedDomains([]);
    } else {
      setSelectedDomains(allDomains);
    }
  };

  const handleOpenModal = (domain) => {
    setDomainToScan(domain);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDomainActions = async (ids, status, type) => {
    setIsDomainLoading(true);
    try {
      const { data } = await axios.post(`/assets/${routeParams?.id}/decide`, {
        ids,
        status,
        type,
      });
      setIsModalOpen(false);
      setIsTableModalOpen(false);
      setIsDomainLoading(false);
      fetchAssetsDiscovery();
      setSelectedDomains([]);
      setDomainToScan(null);
      setSelectedSubDomains([]);
      setDomainToScanTable(null);
      let title = "";
      let subTitle = "";
      if (status == -1) {
        if (type == "domain") {
          title = "The Domain was successfully rejected";
        } else {
          if (ids?.length == 1) {
            title = "The hostname was successfully rejected";
          } else {
            title = "The hostnames were successfully rejected";
          }
        }
      } else {
        if (type == "domain") {
          if (ids?.length == 1) {
            title = "The domain was successfully added to the asset inventory";
            subTitle = "The domain will be scanned in the next scan cycle";
          } else {
            title =
              "The domains were successfully added to the asset inventory";
            subTitle = "The domains will be scanned in the next scan cycle";
          }
        } else {
          if (ids?.length == 1) {
            title =
              "The hostname was successfully added to the asset inventory";
            subTitle = "The hostname will be scanned in the next scan cycle";
          } else {
            title =
              "The hostnames were successfully added to the asset inventory";
            subTitle = "The hostnames will be scanned in the next scan cycle";
          }
        }
      }
      showToast(
        <div style={{ textWrap: "nowrap" }}>
          <b>{title}</b>
          {subTitle && <br />}
          {subTitle}
        </div>,
        "success",
        "top-center",
        "light",
        {
          style: { width: "fit-content", alignItems: "center" },
          autoClose: 3000,
        },
        false
      );
    } catch (err) {
      console.error("Error:", err);
      setIsTableModalOpen(false);
      setIsModalOpen(false);
      setIsDomainLoading(false);
    }
  };

  const handleConfirmScan = (domainsToDisplay) => {
    const matchingIds = assetsDiscovery
      .filter((item) => domainsToDisplay.includes(item.host))
      .map((item) => item.id);
    handleDomainActions(matchingIds, 1, "domain");
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };
  const getExportedRows = (dataForExport) => {  
    const body = [];
  
    // Check if any subdomains are selected
    if (selectedSubDomains.length > 0) {
      // Export only the selected subdomains
      dataForExport.forEach((entry) => {
        if (entry.subdomains?.length > 0) {
          entry.subdomains.forEach((el) => {
            if (selectedSubDomains.includes(el.subdomain)) {
              const row = [];
              row.push(entry.root_domain || "-");
              row.push(
                el.subdomain || "-",
                el.ip || "-",
                el.hosting || "-",
                el.cdn || "-",
                el.first_detected ? formatDate(el.first_detected) : "-"
              );
              body.push(row);
            }
          });
        }
      });
    } else {
      // Export all data if no subdomains are selected
      dataForExport.forEach((entry) => {
        if (entry.subdomains?.length > 0) {
          entry.subdomains.forEach((el) => {
            const row = [];
            row.push(entry.root_domain || "-");
            row.push(
              el.subdomain || "-",
              el.ip || "-",
              el.hosting || "-",
              el.cdn || "-",
              el.first_detected ? formatDate(el.first_detected) : "-"
            );
            body.push(row);
          });
        } else {
          const row = [];
          row.push(entry.root_domain || "-");
          row.push("-", "-", "-", "-", "-");
          body.push(row);
        }
      });
    }
  
    return body;
  };
  

  const handleSelectionAll = () => {
    const allSelected = assetsDiscovery.flatMap((domain) =>
      domain.subdomains.map((sub) => sub.subdomain || sub.ip)
    );
    setSelectedSubDomains(allSelected);
  };

  const updateTabsWithData = (data) => {
    const domains = Array.from(new Set(data.map((el) => el.root_domain)))
      .filter((el) => el)
      .map((el) => ({
        id: el,
        name: el,
        type: "Domain: " + el,
        key: "1",
        active: false,
      }));
    const hostnames = Array.from(
      new Set(data.flatMap((el) => el.subdomains.map((p) => p.subdomain)))
    )
      .filter((el) => el)
      .map((el) => ({
        id: el,
        name: el,
        type: "Hostname: " + el,
        key: "2",
        active: false,
      }));
    const ips = Array.from(
      new Set(data.flatMap((el) => el.subdomains.map((p) => p.ip)))
    )
      .filter((el) => el)
      .map((el) => ({
        id: el,
        name: el,
        type: "IP Address: " + el,
        key: "3",
        active: false,
      }));
    setTabs(
      newAssetDiscoveryTabsConfig({
        domains,
        hostnames,
        ips,
      })
    );
  };

  const fetchAssetsDiscovery = async () => {
    try {
      const { data } = await axios.get(`assets/${routeParams?.id}/pending`);
      const modifiedAllDomains = [];
      const modifiedData = [];
      Object.values(data)?.map((el) => {
        const item = {
          ...el,
          root_domain: el.host,
        };
        item["subdomains"] = el.subdomains?.map((ol) => {
          const subItem = {
            ...ol,
            hosting: ol?.hosting_provider,
            subdomain: ol?.host,
            first_detected: ol?.created_at,
          };
          return subItem;
        });
        if (el?.host && !el?.is_blocked) {
          modifiedAllDomains.push(el.host);
        }
        modifiedData.push(item);
      });
      setAssetsDiscovery(modifiedData);
      updateTabsWithData(modifiedData);
      setAllDomains(modifiedAllDomains);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const fetchIntervalRef = useRef();
  useEffect(() => {
    clearInterval(fetchIntervalRef.current);
    if (scanStatus === 0) {
      setIsLoading(false);
      setIsDataInProcess(true);
    } else {
      setIsDataInProcess(false);
      if (scanStatus === 3 || scanStatus === -1 || scanStatus === 4) {
        setIsDataisPartiallyProcessing(false);
        fetchAssetsDiscovery();
      } else if (scanStatus === 2 || scanStatus === 1) {
        setIsDataisPartiallyProcessing(true);
        fetchAssetsDiscovery();
        fetchScanStatus();
        fetchIntervalRef.current = setInterval(() => {
          fetchScanStatus();
          fetchAssetsDiscovery();
        }, 5000);
      }
    }
    return () => {
      clearInterval(fetchIntervalRef.current);
    };
  }, [scanStatus, routeParams?.id]);

  useEffect(() => {
    setIsLoading(true);
    fetchScanStatus();
  }, []);

  const filteredData = getAssetDiscoveryFilteredData(
    assetsDiscovery,
    activeFilters,
    searchValue
  );

  if (allDomains.length === 0 && assetsDiscovery.length === 0 && !isLoading) {
    return (
      <div className="empty">
        {isDatainProcess || isDataisPartiallyProcessing ? (
          <div className="empty-state">
            <RefreshIcon height={100} />
            <h4>Scanning for Related Assets </h4>
            <p>
              We are currently scanning for domains and assets related to your
              organization.
            </p>
          </div>
        ) : (
          <div className="empty-state">
            <SearchDecreaseIcon height={100} />
            <h4>No Potentially Related Assets Found</h4>
            <p>No New Assets Found Potentially Linked to Your Organization</p>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="row m-0 py-4 px-4">
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <>
          <div className="col-12 asset-discovery-item p-0">
            <div className="asset-header" onClick={toggleCollapse}>
              <Arrow
                className={
                  isCollapsed ? "arrow-rotated-right" : "arrow-rotated-down"
                }
              />
              <span className="ml-2">
                {allDomains.length > 0
                  ? `${allDomains.length} ${
                      allDomains.length == 1 ? "Domain" : "Domains"
                    } Potentially Linked to Your Organization`
                  : "No New Domains Found Potentially Linked to Your Organization"}
              </span>
            </div>
            {!isCollapsed && allDomains.length === 0 && (
              <div className="no-domains-box">
                <EmptyIcon height={200} />
                <p className="mt-3">No New Domains Found</p>
              </div>
            )}
            {!isCollapsed && allDomains.length > 0 && (
              <div className="asset-content">
                {showQuickDomainApproval && (
                  <div className="quick-main">
                    <div className="quick-domain-approval">
                      <div className="quick-domain-content">
                        <div className="icon-wrapper mb-4">
                          <GlobeIcon />
                        </div>
                        <div className="text-content">
                          <h3 className="mt-2 mb-3">QUICK DOMAIN APPROVAL</h3>
                          <p className="mb-4">
                            Scanning will map their related assets and
                            automatically add them to the asset inventory.
                            Actions taken here will immediately update the table
                            below.
                          </p>
                        </div>
                      </div>
                      <button
                        className="close-button"
                        onClick={() => setShowQuickDomainApproval(false)}
                      >
                        <CancelIcon width={20} height={20} />
                      </button>
                    </div>
                  </div>
                )}

                <div className="row pt-3 py-2 d-flex align-items-center ">
              { isUser && (
                  <div className="col-10">
                    <div className="d-flex align-items-center gap-3">
                      <p className="domain-checkbox">
                        <BaseCheckbox
                          height={18}
                          type="checkbox"
                          name="selectAll"
                          checked={selectedDomains.length === allDomains.length}
                          onChange={handleSelectAll}
                          label=""
                          className="mt-2 base-checkbox"
                        />
                      </p>
                      <p className="select-domains" onClick={handleSelectAll}>
                        Select All Domains
                      </p>
                      {selectedDomains.length >= 0 && (
                        <p
                          className={`domain-clear ${
                            selectedDomains.length > 0
                              ? "text-white"
                              : ""
                          }`}
                          onClick={() => setSelectedDomains([])}
                        >
                          Clear All
                        </p>
                      )}

                      {selectedDomains.length > 0 && (
                        <p className="domain-saperator">|</p>
                      )}
                      {selectedDomains.length > 0 && (
                        <p
                          className="scan-domain"
                          onClick={() => handleOpenModal(null)}
                        >
                          Scan {selectedDomains.length}{" "}
                          {selectedDomains.length === 1 ? "Domain" : "Domains"}
                        </p>
                      )}
                    </div>
                  </div>
              )}

                  <div className="domain-row">
                    {allDomains.map((domain) => (
                      <ScanDomains
                        key={domain?.id}
                        domain={domain}
                        isChecked={selectedDomains.includes(domain)}
                        onCheckboxChange={() => handleCheckboxChange(domain)}
                        onOpenModal={handleOpenModal}
                        isUser={isUser}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <hr className="mt-4" />

          <div className="p-0">
            <BaseFilter
              className="mt-3 p-0"
              tabs={tabs}
              onFilterChange={handleFilterChange}
              activeFilters={activeFilters}
              removeFilter={removeFilter}
              totalRecords={filteredData.length}
              exportHeader={[
                "Domain",
                "Hostname",
                "IP Address",
                "First Detected",
              ]}
              exportTitle={`AssetDiscovery_${formatScannerName(
                dropdownItems.filter((item) => {
                  return item.target_uuid === routeParams?.id;
                })[0]?.title
              )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
              exportRows={getExportedRows(filteredData)}
              isDatainProcess={isDatainProcess || isDataisPartiallyProcessing}
              searchValue={searchValue}
              isSearch={true}
              onSearchChange={handleSearchChange}
              tableData={filteredData}
            />
          </div>
          {selectedSubDomains?.length > 0 && (
            <BulkSelection
              actions={[
                { id: 1, name: "Scan" },
                { id: 2, name: "Reject" },
              ]}
              onActionSelect={handleActionSelect}
              multiSelect={selectedSubDomains.length}
              multiselectLabel="Hostname"
              totalRecords={
                filteredData.flatMap((domain) => domain.subdomains).length
              }
              handleSelectionAll={handleSelectionAll}
              onClearSelectRow={handleClearAllSelections}
            />
          )}
          {filteredData.length > 0 &&
            filteredData.map((domain) => (
              <DomainScanTable
                key={domain.id}
                asset={domain}
                selectedSubDomains={selectedSubDomains}
                setSelectedSubDomains={setSelectedSubDomains}
                setIsModalOpen={setIsTableModalOpen}
                setDomainToScan={setDomainToScanTable}
                setIsRejectMode={setIsRejectMode}
                setShowDomain={setShowDomain}
                isUser={isUser}
              />
            ))}

          {isModalOpen && (
            <ConfirmationModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              onConfirm={handleConfirmScan}
              domain={domainToScan}
              selectedDomains={selectedDomains}
              isDomainLoading={isDomainLoading}
            />
          )}
          {isTableModalOpen && (
            <DomainModal
              isOpen={isTableModalOpen}
              onClose={handleCloseTableModal}
              onConfirm={handleConfirmAction}
              domain={domainToScanTable}
              isRejectMode={isRejectMode}
              selectedSubDomains={selectedSubDomains}
              showDomain={showDomain}
              isDomainLoading={isDomainLoading}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AssetDiscovery;