import React from 'react';
import { ToastContainer } from "react-toastify";


const PublicLayout = (props) => {
    return (
        <>
            {props.children}
            <ToastContainer/>
        </>
    );
}

export default PublicLayout;
