import React, { useState, useEffect } from "react";
import SecurityIssueCard from "../../components/securityissue-card/SecurityIssueCard";
import { ReactComponent as Arrow } from "../../assets/images/port-arrow.svg";
import armorytestedIconSmall from "../../assets/images/armorytestedIconSmall.png";
import armoryWarZoneIcon from "../../assets/images/warTag.png";
import { ReactComponent as HighRiskGroup } from "../../assets/images/HighRiskGroup.svg";
import { ReactComponent as MediumGroupRisk } from "../../assets/images/MediumGroupRisk.svg";
import { ReactComponent as LowRiskGroup } from "../../assets/images/LowRiskGroup.svg";
import dotMenuIcon from "../../assets/images/dotMenuIcon.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ActionPopover from "../../components/actions/ActionPopover";
import { ReactComponent as Elipses } from "../../assets/images/elipses.svg";

const GroupIssuesTitle = ({
  group,
  collapsedGroups,
  toggleCollapse,
  cvssScore,
  actions,
  onSelectionChange,
  onSingleSelectionChange,
  clearSelectedRows,
  handleActionClick,
  type,
  isActivePopover,
  popoverId,
  handleShowPopover,
  setIsActivePopover,
  isChecked,
  setSelectedIssue,
  selectedIssue,
  selectedCves,
  checkedState,
  setCheckedState,
  isUser

}) => {
  const [activePopover, setActivePopover] = useState(null);
  const isCollapsed = collapsedGroups[group.issue_name];
  const isGroupFullySelected = group.assets.every((asset) =>
    selectedCves.some((selected) => selected.id === asset.id)
  );

  const isGroupPartiallySelected =
    group.assets.some((asset) =>
      selectedCves.some((selected) => selected.id === asset.id)
    ) && !isGroupFullySelected;

  const handleGroupCheckboxChange = () => {
    const newCheckedState = !isGroupFullySelected;
    onSelectionChange(
      {
        groupInfo: group.issue_name,
        armory_id: group.armory_id,
        assets: group.assets,
      },
      newCheckedState
    );
    const updatedCheckedState = { ...checkedState };
    group.assets.forEach((asset) => {
      updatedCheckedState[asset.id] = newCheckedState;
    });
    updatedCheckedState[group.issue_name] = newCheckedState; // Update group-level state
    setCheckedState(updatedCheckedState);
  };

  const handlePopoverToggle = (groupId) => {
    setActivePopover(activePopover === groupId ? null : groupId);
  };
  const handleActionSelect = (id, action) => {
    handleActionClick(action.name, group, 1);
    setActivePopover(null);
  };
  const actionPopover = (groupId) => (
    <Popover id={`action-group-${groupId}`} className="p-0">
      <Popover.Body className="p-0">
        <ActionPopover
          rowId={groupId}
          actions={actions}
          onApply={handleActionSelect}
        />
      </Popover.Body>
    </Popover>
  );
  useEffect(() => {
    if (clearSelectedRows) {
      setIsActivePopover(false);
      onSelectionChange(
        {
          groupInfo: group.issue_name,
          armory_id: group.armory_id,
          assets: group.assets,
        },
        false
      );
    }
  }, [clearSelectedRows]);
  const handleSelectionChange = (assetData, isSelected, id) => {
    const updatedCheckedState = { ...checkedState };
    updatedCheckedState[id] = isSelected;
    setCheckedState(updatedCheckedState);
    onSingleSelectionChange(assetData, isSelected);
  };

  const getRiskLevel = (score) => {
    if (score >= 80 && score <= 100) {
      return "primary";
    } else if (score >= 40 && score <= 79) {
      return "secondary";
    } else {
      return "tertiary";
    }
  };

  const cvssRisk = group.assets.reduce((highestRisk, asset) => {
    const assetRisk = getRiskLevel(asset.risk_score);
    if (assetRisk === "primary") return "primary";
    if (assetRisk === "secondary" && highestRisk !== "primary")
      return "secondary";
    if (highestRisk !== "primary" && highestRisk !== "secondary")
      return "tertiary";
    return highestRisk;
  }, "tertiary");

  const hasNewTag = group.assets?.some((asset) => asset.new);
  return (
    <div
      className={`d-flex ${
        collapsedGroups[group.issue_name]
          ? "align-items-start "
          : "align-items-center"
      }`}
    >
      <div
        className={`me-1 issue-checkbox ${
          collapsedGroups[group.issue_name] ? "mt-1" : ""
        }`}
      >
      {
        isUser && (
         <input
         type="checkbox"
         className="security-checkbox"
         checked={isGroupFullySelected}
         onChange={handleGroupCheckboxChange}
         ref={(el) => {
           if (el) {
             el.indeterminate = isGroupPartiallySelected;
           }
         }}
       />
      )
      } 
      </div>
      <div
        className={`ports-list-table d-block w-100 ${
          checkedState[group.issue_name] ? "border-action" : ""
        }`}
      >
        <div
          className="left-ports-container d-flex align-items-baseline"
          onClick={() => toggleCollapse(group.issue_name)}
          style={{ width: collapsedGroups[group.issue_name] ? "auto" : "" }}
        >
          <Arrow
            className={
              collapsedGroups[group.issue_name]
                ? "arrow-rotated-down"
                : "arrow-rotated-up"
            }
          />
          <div className="w-100">
            <div className="d-flex align-items-center justify-content-between">
              <span className="tab-issue-title">{group.issue_name}:</span>
              <div className="section_1_part1">
                <div className="securityissueCard_title">
                  {/* Additional group-specific data can go here */}
                </div>

                <div className="d-flex align-items-center">
                  {group?.is_war_room ? (
                    <div className="section_1_part1_subpart d-flex align-items-center justify-content-center mx-2">
                      <img
                        className="h-100"
                        src={armoryWarZoneIcon}
                        alt="Armory Tested"
                        style={{ marginBottom: "6px" }}
                      />
                      <div className="securityissueCard_subtitleWarGroup">
                        WAR ROOM
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {group?.armory_tested ? (
                    <div className="section_1_part1_subpart d-flex align-items-center justify-content-center">
                      <img
                        className="h-100"
                        src={armorytestedIconSmall}
                        alt="Armory Tested"
                      />
                      <div className="securityissueCard_subtitle">
                        ARMORY TESTED
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {isUser && ( 
                      <OverlayTrigger
                    trigger="click"
                    placement="left"
                    overlay={actionPopover(group.issue_name)}
                    show={isActivePopover && popoverId === group.issue_name}
                    onToggle={() => {
                      if (isActivePopover) {
                        setIsActivePopover(false);
                      }
                    }}
                    rootClose
                  >

                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        handleShowPopover(group?.issue_name);
                      }}
                    >
                      <Elipses className="cves-popup cursor-pointer ms-2" />
                    </div>
                  </OverlayTrigger>
                )}
               
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mt-1">
              {cvssRisk === "primary" && (
                <div>
                  <HighRiskGroup />
                  <label className="ms-1 group-tab-sub">High Risk,</label>
                </div>
              )}
              {cvssRisk === "secondary" && (
                <div>
                  <MediumGroupRisk />
                  <label className="ms-1 group-tab-sub">Medium Risk,</label>
                </div>
              )}
              {cvssRisk === "tertiary" && (
                <div>
                  <LowRiskGroup />
                  <label className="ms-1 group-tab-sub">Low Risk,</label>
                </div>
              )}
              <div className="d-flex align-items-center mx-2">
                <div className="title-text mt-0">
                  <label className="group-tab-value ms-1">
                    {group?.assets.length}
                  </label>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <label className="group-tab-sub ms-1">Assets ,</label>
                </div>
              </div>
              {group.scan_category && (
                <div className="d-flex align-items-center">
                  <div className="group-tab-value mt-0 pe-1">Category:</div>
                  <div className="d-flex align-items-center justify-content-center">
                    <label className="group-tab-sub group-category-value">
                      {group.scan_category},
                    </label>
                  </div>
                </div>
              )}
              <div className="d-flex align-items-center ms-1">
                <div className="group-tab-value mt-0 pe-2">CVE:</div>
                <div
                  className={`cve-tag d-flex align-items-center justify-content-center ${cvssRisk}`}
                >
                  <span>{group.cve ? "Yes" : "No"}</span>
                </div>
              </div>
              {group?.new_count > 0 && hasNewTag && type === "open" && (
                <div className="is-new-tag-grouped">
                  <div className="comma-icon">,</div>
                  <div className="is-secuiryt-new-tag">
                    New: {group?.new_count}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="right-ports-container"
          style={{
            width: collapsedGroups[group.issue_name] ? "auto" : "",
          }}
        >
          {collapsedGroups[group.issue_name] && (
            <div className="port-collapse-table group-issues">
              {group.assets.map((el) => (
                <SecurityIssueCard
                  key={el.id}
                  classNameVariant={cvssRisk}
                  RiskPercentage={cvssScore}
                  data={group}
                  asset={el}
                  isGroup={true}
                  actions={actions}
                  handleActionClick={handleActionClick}
                  onSelectionChange={(assetData, isSelected) =>
                    handleSelectionChange(assetData, isSelected, el.id)
                  }
                  type={type}
                  isActivePopover={isActivePopover}
                  popoverId={popoverId}
                  handleShowPopover={handleShowPopover}
                  setIsActivePopover={setIsActivePopover}
                  criticalityLevel={
                    el.ip ? el.target_ip.criticality : el.asset_criticality_host
                  }
                  setSelectedIssue={setSelectedIssue}
                  selectedIssue={selectedIssue}
                  isChecked={checkedState[el.id] || false}
                  isUser={isUser}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GroupIssuesTitle;
