import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403) &&
      originalRequest.url !== "auth/login"
    ) {
      localStorage.clear();
      console.log("clear");
      window.location = "/auth/login";
    }
    return Promise.reject(error);
  }
);

if (typeof XMLHttpRequest !== "undefined") {
  const originalOpen = XMLHttpRequest.prototype.open;

  XMLHttpRequest.prototype.open = function (...args) {
    this.addEventListener("readystatechange", function () {
      if (this.responseType === "blob" && this.readyState === 4) {
        Object.defineProperty(this, "responseText", {
          get() {
            return "";
          },
        });
      }
    });
    originalOpen.apply(this, args);
  };
}

export default axios;
