import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import { Formik, Field, FieldArray } from "formik";
import { ReactComponent as AddIcon } from "../../../assets/images/add.svg";
import { ReactComponent as CrossIcon } from "../../../assets/images/cancel.svg";
import { ReactComponent as TestedIcon } from "../../../assets/images/tested.svg";
import { ReactComponent as HackerIcon } from "../../../assets/images/hacker.svg";
import axios from "../../../util/axios";
import CustomTagSelector from "./CustomTagSelector";
import { categories } from "../../../util/scans";
import * as Yup from "yup";
import { showToast } from "../../../util/toasts";
import { ThreeCircles } from "react-loader-spinner";
import { decodeUsingDOMParser } from "../../../util/core";

const validationSchema = Yup.object().shape({
  issue_name: Yup.string().required("Issue Name is required"),
  organization_id: Yup.string().required("Organization is required"),
  target_id: Yup.string().required("Target is required"),
  summary: Yup.string().required("Summary is required"),
  explanation: Yup.string().required("Explanation is required"),
  impact: Yup.number()
    .required("Impact is required")
    .typeError("Impact must be a number")
    .min(0, "Impact must be at least 0")
    .max(100, "Impact cannot exceed 100"),
  exploitability: Yup.number()
    .required("Exploitability is required")
    .typeError("Exploitability must be a number")
    .min(0, "Exploitability must be at least 0")
    .max(100, "Exploitability cannot exceed 100"),
  remediations: Yup.array()
    .of(Yup.string().required("Each remediation must be filled"))
    .min(1, "At least one remediation is required"),
});

const AddOrganizationModal = ({
  show,
  handleClose,
  fetchSecurityIssues,
  id,
  setEditId,
}) => {
  const initialFormState = {
    issue_name: "",
    organization_id: "",
    target_id: "",
    summary: "",
    explanation: "",
    impact: "",
    exploitability: "",
    remediations: [""],
    tags: "",
    scan_category_id: "",
    domain: "",
    host: "",
    ip: "",
    port: "",
  };
  const [organizations, setOrganizations] = useState([]);
  const [relatedAssets, setRelatedAssets] = useState([]);
  const [targets, setTargets] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [selectedTarget, setSelectedTarget] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [assetsList, setAssetsList] = useState({
    domains: [],
    hosts: [],
    ips: [],
    ports: [],
  });
  const [initialValues, setInitialValues] = useState(initialFormState);
  const fetchSecurityIssuesDetails = async (id) => {
    try {
      let { data } = await axios.get(`admin/manual-issues/${id}`);
      if (data) {
        setSelectedOrganization(data.organization_id);
        setSelectedTarget(data.target_id);
        fetchTargets(data.organization_id);
        fetchAssets(data.target_id, data?.issues_manual_asset);
        const tags = [];
        if (data?.armory_tested) {
          tags.push("armory_tested");
        }
        if (data?.is_war_room) {
          tags.push("war_room");
        }

        setInitialValues((prevValues) => ({
          ...prevValues,
          issue_name: decodeUsingDOMParser(data.issue_name) || "",
          organization_id: data.organization_id || "",
          target_id: data.target_id || "",
          summary: decodeUsingDOMParser(data.summary) || "",
          explanation: decodeUsingDOMParser(data.explanation) || "",
          impact: data.impact || "",
          exploitability: data.exploitability || "",
          remediations: data.remediation
            ?.split("|")
            ?.map((el) => decodeUsingDOMParser(el)) || [""],
          tags: tags,
          scan_category_id: data.scan_category_id || "",
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePreview = async (validateForm) => {
    if (!isPreviewMode) {
      const errors = await validateForm();
      if (Object.keys(errors).length === 0) {
        setIsPreviewMode(true);
      } else {
        showToast(
          "Please complete all required fields before previewing.",
          "error"
        );
      }
    } else {
      setIsPreviewMode(false);
    }
  };

  const fetchOrganizations = async () => {
    try {
      let { data } = await axios.get("organizations", {
        params: { page: 1, pageSize: 500 },
      });
      setOrganizations(data?.items || []);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTargets = async (organizationId) => {
    try {
      let { data } = await axios.get("targets", {
        params: {
          page: 1,
          pageSize: 500,
          organization_id: organizationId,
        },
      });
      setTargets(data?.items || []);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAssets = async (targetId, manualAssets) => {
    try {
      let { data } = await axios.get(`admin/assets-by-target/${targetId}`);
      const allAssets = {
        ...assetsList,
        domains:
          data?.data?.domains?.map((el) => ({
            value: el?.id,
            label: el?.host,
          })) || [],
        hosts:
          data?.data?.hosts?.map((el) => ({
            value: el?.host?.id,
            label: el?.host?.host,
          })) || [],
        ips:
          data?.data?.ipsAndPorts?.map((el) => ({
            ports: el?.ports,
            value: el?.ip_id,
            label: el?.ip,
          })) || [],
      };
      if (manualAssets?.length > 0) {
        const existingAssets = [];
        manualAssets?.map((el) => {
          let perAsset = {};
          if (el?.domain_id) {
            perAsset["domain"] = allAssets?.domains?.filter(
              (ol) => ol.value == el?.domain_id
            )?.[0];
          }
          if (el?.host_id) {
            perAsset["host"] = allAssets?.hosts?.filter(
              (ol) => ol.value == el?.host_id
            )?.[0];
          }
          if (el?.ip_id) {
            const ip = allAssets?.ips?.filter((ol) => ol.value == el?.ip_id);
            perAsset["ip"] = ip?.[0];
            if (el?.port_id) {
              ip?.[0]?.ports?.filter((ol) => {
                if (ol.port_id == el?.port_id) {
                  perAsset["port"] = {
                    label: ol.port,
                    value: ol.port_id,
                  };
                }
              });
            }
          }
          if (el?.id) {
            perAsset["id"] = el?.id;
          }
          existingAssets.push(perAsset);
        });
        setRelatedAssets(existingAssets);
      }
      setAssetsList(allAssets);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOrganizationChange = (form, e, field) => {
    const selectedValue = e.target.value;
    form.setFieldValue(field.name, selectedValue);
    setSelectedOrganization(selectedValue);
    form.setFieldValue("target_id", "");
    setSelectedTarget("");
    setRelatedAssets([]);
    if (selectedValue) {
      fetchTargets(selectedValue);
    } else {
      setTargets([]);
    }
  };

  const handleAddAssets = (values, setFieldValue) => {
    const { domain, host, ip, port } = values;

    if (!domain && !host && !ip && !port) {
      alert("Please select at least one field (Domain, Host, IP, or Port)");
      return;
    }

    const newAsset = {
      domain: assetsList.domains.find((d) => d.value == domain) || null,
      host: assetsList.hosts.find((h) => h.value == host) || null,
      ip: assetsList.ips.find((i) => i.value == ip) || null,
      port: assetsList.ports.find((p) => p.value == port) || null,
    };

    setRelatedAssets([...relatedAssets, newAsset]);
    setFieldValue("domain", "");
    setFieldValue("host", "");
    setFieldValue("ip", "");
    setFieldValue("port", "");
    setAssetsList({
      ...assetsList,
      ports: [],
    });
  };

  const handleTargetChange = (e, form, field) => {
    const selectedValue = e.target.value;
    form.setFieldValue(field.name, selectedValue);
    setSelectedTarget(selectedValue);
    setRelatedAssets([]);
    if (selectedValue) {
      fetchAssets(selectedValue);
    }
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    const payload = {
      issue_name: values?.issue_name,
      scan_category_id: values?.scan_category_id || null,
      organization_id: values?.organization_id,
      target_id: values?.target_id,
      summary: values?.summary,
      explanation: values?.explanation,
      impact: Number(values?.impact),
      exploitability: Number(values?.exploitability),
      tags: values?.tags || [],
      remediation: values?.remediations?.join(" | ") || "",
    };

    if (relatedAssets?.length > 0) {
      payload["assets"] = relatedAssets?.map((el) => {
        const keys = {};
        if (el?.domain) {
          keys["domain_id"] = el.domain.value;
        }
        if (el?.host) {
          keys["host_id"] = el.host.value;
        }
        if (el?.ip) {
          keys["ip_id"] = el.ip.value;
        }
        if (el?.port) {
          keys["port_id"] = el.port.value;
        }
        if (el?.id) {
          keys["id"] = el.id;
        }
        return keys;
      });
    }

    setIsLoading(true);
    try {
      const endpoint = id ? `admin/manual-issues/${id}` : "admin/manual-issues";
      const method = id ? axios.put : axios.post;
      let { data } = await method(endpoint, payload);
      showToast(
        `Security Issue ${id ? "Updated" : "Created"} Successfully`,
        "success"
      );
      setEditId(null);
      fetchSecurityIssues();
      resetForm();
      setRelatedAssets([]);
      setAssetsList({
        domains: [],
        hosts: [],
        ips: [],
        ports: [],
      });
      setIsLoading(false);
      setIsPreviewMode(false);
      handleClose();
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
      showToast(
        `Failed to ${id ? "update" : "create"} security issue`,
        "error"
      );
    }
  };

  const validate = () => {
    const errors = {};
    if (relatedAssets.length === 0) {
      errors.relatedAssets = "At least one related asset is required.";
    }
    return errors;
  };

  const removeChip = (index) => {
    setRelatedAssets(relatedAssets.filter((_, i) => i !== index));
  };

  const removeRemedation = (index, form) => {
    const updatedRemediations = [...form.values.remediations];
    updatedRemediations.splice(index, 1);
    form.setFieldValue("remediations", updatedRemediations);
  };

  const handleChangeRemediation = (e, index, form) => {
    const updatedRemediations = [...form.values.remediations];
    updatedRemediations[index] = e.target.value;
    form.setFieldValue("remediations", updatedRemediations);
  };

  const handleIpChange = (e, form, field) => {
    const selectedValue = e.target.value;
    form.setFieldValue(field.name, selectedValue);

    const selectedIp = assetsList.ips.find((ip) => ip.value == selectedValue);
    const availablePorts = selectedIp?.ports || [];
    setAssetsList((prevState) => ({
      ...prevState,
      ports:
        availablePorts?.map((el) => ({
          value: el?.port_id,
          label: el?.port,
        })) || [],
    }));
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  useEffect(() => {
    if (id) {
      fetchSecurityIssuesDetails(id);
    } else {
      setInitialValues(initialFormState);
    }
  }, [id]);

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setRelatedAssets([]);
          setIsPreviewMode(false);
          handleClose();
        }}
        centered
        className="custom-modal-width"
      >
        <Modal.Body
          style={{
            backgroundColor: "#252525",
            color: "#fff",
            border: "1px solid #93C2FF",
            borderRadius: "4px",
            padding: "34px",
          }}
        >
          <div className="admin-security-modal">
            <h3>
              {isPreviewMode
                ? "Preview"
                : `${id ? "Edit" : "Add"} New Security Issue`}
            </h3>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validate={validate}
              onSubmit={handleFormSubmit}
              enableReinitialize={true}
            >
              {({
                values,
                handleSubmit,
                setFieldValue,
                errors,
                validateForm,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={12}>
                      <Field name="issue_name">
                        {({ field, meta }) => (
                          <FormGroup className="mt-3" controlId="issue_name">
                            <label className="admin-input-label">
                              Issue Name{" "}
                            </label>
                            <FormControl
                              type="text"
                              size="lg"
                              placeholder="Enter Issue Name"
                              className={`placeholder-custom admin-input-field ${
                                meta.touched && meta.error ? "is-invalid" : ""
                              }`}
                              {...field}
                              disabled={isPreviewMode}
                            />
                            {meta.touched && meta.error && (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            )}
                          </FormGroup>
                        )}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field as="select" name="organization_id">
                        {({ field, meta, form }) => (
                          <FormGroup
                            className="mt-3"
                            controlId="organization_id"
                          >
                            <label className="admin-input-label">
                              Organization
                            </label>
                            <FormControl
                              {...field}
                              as="select"
                              size="lg"
                              value={field.value}
                              className={
                                meta.touched && meta.error
                                  ? "is-invalid custom-select admin-input-field"
                                  : "custom-select admin-input-field"
                              }
                              onChange={(e) => {
                                handleOrganizationChange(form, e, field);
                              }}
                              style={{
                                color: field.value === "" ? "#6F6F6F" : "#fff",
                              }}
                              disabled={isPreviewMode}
                            >
                              <option value={""}>Select Organization</option>
                              {organizations?.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.org_name}
                                </option>
                              ))}
                            </FormControl>
                            {meta.touched && meta.error && (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            )}
                          </FormGroup>
                        )}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field as="select" name="target_id">
                        {({ field, meta, form }) => (
                          <FormGroup className="mt-3" controlId="target_id">
                            <label className="admin-input-label">Target</label>
                            <FormControl
                              {...field}
                              as="select"
                              size="lg"
                              value={field.value}
                              onChange={(e) => {
                                handleTargetChange(e, form, field);
                              }}
                              disabled={!selectedOrganization || isPreviewMode}
                              className={
                                meta.touched && meta.error
                                  ? "is-invalid custom-select admin-input-field"
                                  : "custom-select admin-input-field"
                              }
                              style={{
                                color: field.value === "" ? "#6F6F6F" : "#fff",
                              }}
                            >
                              <option value={""}>Select Target</option>
                              {targets?.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.title}
                                </option>
                              ))}
                            </FormControl>
                            {meta.touched && meta.error && (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            )}
                          </FormGroup>
                        )}
                      </Field>
                    </Col>
                    <Col md={12}>
                      <Field name="summary">
                        {({ field, meta }) => (
                          <FormGroup className="mt-3" controlId="summary">
                            <label className="admin-input-label">
                              Summary{" "}
                            </label>
                            <FormControl
                              as="textarea"
                              size="lg"
                              rows={4}
                              placeholder="Enter text"
                              className={`placeholder-custom admin-text-field ${
                                meta.touched && meta.error ? "is-invalid" : ""
                              }`}
                              {...field}
                              disabled={isPreviewMode}
                            />
                            {meta.touched && meta.error && (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            )}
                          </FormGroup>
                        )}
                      </Field>
                    </Col>
                    <Col md={12}>
                      <Field name="explanation">
                        {({ field, meta }) => (
                          <FormGroup className="mt-3" controlId="explanation">
                            <label className="admin-input-label">
                              Explanation{" "}
                            </label>
                            <FormControl
                              as="textarea"
                              size="lg"
                              rows={4}
                              placeholder="Enter text"
                              className={`placeholder-custom admin-text-field ${
                                meta.touched && meta.error ? "is-invalid" : ""
                              }`}
                              {...field}
                              disabled={isPreviewMode}
                            />
                            {meta.touched && meta.error && (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            )}
                          </FormGroup>
                        )}
                      </Field>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="mt-3" controlId="relatedAssets">
                        <label className="admin-input-label mb-1">
                          Related Assets (Select the assets below to add in
                          here)
                        </label>
                        {relatedAssets?.length > 0 ? (
                          <div
                            className={`d-flex flex-wrap align-items-center admin-asset-chip ${
                              isPreviewMode && "admin-asset-chip-disabled"
                            }`}
                          >
                            {relatedAssets.map((asset, index) => (
                              <div
                                key={
                                  asset.host?.label ||
                                  asset.ip?.label ||
                                  asset.domain?.label
                                }
                                className="admin-asset-chip-box"
                              >
                                <span>
                                  {[
                                    asset.domain?.label,
                                    asset.host?.label,
                                    asset.ip?.label,
                                    asset.port?.label,
                                  ]
                                    .filter(Boolean)
                                    .join(" | ")}{" "}
                                </span>
                                <CrossIcon onClick={() => removeChip(index)} />
                              </div>
                            ))}
                          </div>
                        ) : selectedTarget ? (
                          <div
                            className="text-danger"
                            style={{ fontSize: "0.875rem", marginTop: "8px" }}
                          >
                            {errors.relatedAssets}
                          </div>
                        ) : (
                          ""
                        )}
                      </FormGroup>
                    </Col>
                    {!isPreviewMode && (
                      <Col md={12}>
                        <div className="related-assets-box">
                          <Row>
                            <Col md={6}>
                              <Field as="select" name="domain">
                                {({ field, meta }) => (
                                  <FormGroup
                                    className="mt-3"
                                    controlId="domain"
                                  >
                                    <label className="admin-input-label">
                                      Domain
                                    </label>
                                    <FormControl
                                      {...field}
                                      as="select"
                                      size="lg"
                                      disabled={!selectedTarget}
                                      value={field.value}
                                      className={
                                        meta.touched && meta.error
                                          ? "is-invalid custom-select admin-input-field"
                                          : "custom-select admin-input-field"
                                      }
                                      style={{
                                        color:
                                          field.value === ""
                                            ? "#6F6F6F"
                                            : "#fff",
                                      }}
                                    >
                                      <option value={""}>Select Domain</option>
                                      {assetsList?.domains?.map((option) => (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </option>
                                      ))}
                                    </FormControl>
                                    {meta.touched && meta.error && (
                                      <div className="invalid-feedback">
                                        {meta.error}
                                      </div>
                                    )}
                                  </FormGroup>
                                )}
                              </Field>
                            </Col>
                            <Col md={6}>
                              <Field as="select" name="host">
                                {({ field, meta }) => (
                                  <FormGroup className="mt-3" controlId="host">
                                    <label className="admin-input-label">
                                      Host
                                    </label>
                                    <FormControl
                                      {...field}
                                      as="select"
                                      disabled={!selectedTarget}
                                      size="lg"
                                      value={field.value}
                                      className={
                                        meta.touched && meta.error
                                          ? "is-invalid custom-select admin-input-field"
                                          : "custom-select admin-input-field"
                                      }
                                      style={{
                                        color:
                                          field.value === ""
                                            ? "#6F6F6F"
                                            : "#fff",
                                      }}
                                    >
                                      <option value={""}>Select Host</option>
                                      {assetsList?.hosts?.map((option) => (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </option>
                                      ))}
                                    </FormControl>
                                    {meta.touched && meta.error && (
                                      <div className="invalid-feedback">
                                        {meta.error}
                                      </div>
                                    )}
                                  </FormGroup>
                                )}
                              </Field>
                            </Col>
                            <Col md={6}>
                              <Field as="select" name="ip">
                                {({ field, meta, form }) => (
                                  <FormGroup className="mt-2" controlId="ip">
                                    <label className="admin-input-label">
                                      IP Address
                                    </label>
                                    <FormControl
                                      {...field}
                                      as="select"
                                      disabled={!selectedTarget}
                                      size="lg"
                                      value={field.value}
                                      className={
                                        meta.touched && meta.error
                                          ? "is-invalid custom-select admin-input-field"
                                          : "custom-select admin-input-field"
                                      }
                                      style={{
                                        color:
                                          field.value === ""
                                            ? "#6F6F6F"
                                            : "#fff",
                                      }}
                                      onChange={(e) =>
                                        handleIpChange(e, form, field)
                                      }
                                    >
                                      <option value={""}>
                                        Select IP Address
                                      </option>
                                      {assetsList?.ips?.map((option) => (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </option>
                                      ))}
                                    </FormControl>
                                    {meta.touched && meta.error && (
                                      <div className="invalid-feedback">
                                        {meta.error}
                                      </div>
                                    )}
                                  </FormGroup>
                                )}
                              </Field>
                            </Col>
                            <Col md={6}>
                              <Field as="select" name="port">
                                {({ field, meta }) => (
                                  <FormGroup className="mt-2" controlId="port">
                                    <label className="admin-input-label">
                                      Port
                                    </label>
                                    <FormControl
                                      {...field}
                                      disabled={!selectedTarget}
                                      as="select"
                                      size="lg"
                                      value={field.value}
                                      className={
                                        meta.touched && meta.error
                                          ? "is-invalid custom-select admin-input-field"
                                          : "custom-select admin-input-field"
                                      }
                                      style={{
                                        color:
                                          field.value === ""
                                            ? "#6F6F6F"
                                            : "#fff",
                                      }}
                                    >
                                      <option value={""}>Select Port</option>
                                      {assetsList?.ports?.map((option) => (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </option>
                                      ))}
                                    </FormControl>
                                    {meta.touched && meta.error && (
                                      <div className="invalid-feedback">
                                        {meta.error}
                                      </div>
                                    )}
                                  </FormGroup>
                                )}
                              </Field>
                            </Col>
                            <Col md={12}>
                              <div className="org-button">
                                <Button
                                  variant="secondary"
                                  className="button-hover"
                                  disabled={!selectedTarget}
                                  style={{
                                    height: "40px",
                                    backgroundColor: "#007bff",
                                    color: "white",
                                    borderColor: "#007bff",
                                    margin: "10px 0px 0px 0px",
                                  }}
                                  onClick={() =>
                                    handleAddAssets(values, setFieldValue)
                                  }
                                >
                                  Add
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    )}
                    <Col md={6}>
                      <Field name="impact">
                        {({ field, meta }) => (
                          <FormGroup className="mt-3" controlId="impact">
                            <label className="admin-input-label">Impact</label>
                            <FormControl
                              type="number"
                              size="lg"
                              placeholder="Enter Number"
                              className={`placeholder-custom admin-input-field ${
                                meta.touched && meta.error ? "is-invalid" : ""
                              }`}
                              {...field}
                              disabled={isPreviewMode}
                            />
                            {meta.touched && meta.error && (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            )}
                          </FormGroup>
                        )}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field name="exploitability">
                        {({ field, meta }) => (
                          <FormGroup
                            className="mt-3"
                            controlId="exploitability"
                          >
                            <label className="admin-input-label">
                              Exploitability
                            </label>
                            <FormControl
                              type="number"
                              size="lg"
                              placeholder="Enter Number"
                              className={`placeholder-custom admin-input-field ${
                                meta.touched && meta.error ? "is-invalid" : ""
                              }`}
                              {...field}
                              disabled={isPreviewMode}
                            />
                            {meta.touched && meta.error && (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            )}
                          </FormGroup>
                        )}
                      </Field>
                    </Col>
                    <Col md={12}>
                      <FieldArray name="remediations">
                        {({ form, push }) => (
                          <div className="mt-3">
                            <label className="admin-input-label">
                              Remediation
                            </label>
                            {form.values.remediations.map((_, index) => (
                              <div
                                key={`remediation-${index}`}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <FormGroup style={{ width: "100%" }}>
                                  <FormControl
                                    as="textarea"
                                    size="lg"
                                    rows={4}
                                    placeholder="Enter Remediation"
                                    className={`placeholder-custom admin-text-field ${
                                      form.errors.remediations &&
                                      form.errors.remediations[index] &&
                                      form.touched.remediations &&
                                      form.touched.remediations[index]
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    value={form.values.remediations[index]}
                                    onChange={(e) =>
                                      handleChangeRemediation(e, index, form)
                                    }
                                    disabled={isPreviewMode}
                                  />
                                  {form.errors.remediations &&
                                    form.errors.remediations[index] &&
                                    form.touched.remediations &&
                                    form.touched.remediations[index] && (
                                      <div className="invalid-feedback">
                                        {form.errors.remediations[index]}
                                      </div>
                                    )}
                                </FormGroup>
                                {form.values.remediations?.length > 1 && (
                                  <CrossIcon
                                    onClick={() =>
                                      removeRemedation(index, form)
                                    }
                                    style={{
                                      marginLeft: 12,
                                      cursor: "pointer",
                                    }}
                                  />
                                )}
                              </div>
                            ))}
                            {!isPreviewMode && (
                              <div className="another-user-container">
                                <a
                                  href="#"
                                  className="another-user-link"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    push("");
                                  }}
                                >
                                  <AddIcon /> Add Another
                                </a>
                              </div>
                            )}
                          </div>
                        )}
                      </FieldArray>
                    </Col>

                    <Col md={6}>
                      <div className="mt-3">
                        <label className="admin-input-label">Add Tag</label>
                        <Field
                          name="tags"
                          options={[
                            {
                              value: "armory_tested",
                              label: "Armory Tested",
                              color: "#398FFE",
                              icon: <TestedIcon />,
                            },
                            {
                              value: "war_room",
                              label: "War Room",
                              color: "#3DDCD4",
                              icon: <HackerIcon />,
                            },
                          ]}
                          component={CustomTagSelector}
                          disabled={isPreviewMode}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <Field as="select" name="scan_category_id">
                        {({ field, meta }) => (
                          <FormGroup
                            className="mt-3"
                            controlId="scan_category_id"
                          >
                            <label className="admin-input-label">
                              Category
                            </label>
                            <FormControl
                              as="select"
                              size="lg"
                              value={field.value}
                              onChange={field.onChange}
                              className={
                                meta.touched && meta.error
                                  ? "is-invalid custom-select admin-input-field"
                                  : "custom-select admin-input-field"
                              }
                              style={{
                                color: field.value === "" ? "#6F6F6F" : "#fff",
                              }}
                              {...field}
                              disabled={isPreviewMode}
                            >
                              <option value={""}>Select Category</option>
                              {categories.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.categ_name}
                                </option>
                              ))}
                            </FormControl>
                            {meta.touched && meta.error && (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            )}
                          </FormGroup>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <div className="mt-3 d-flex align-items-end justify-content-end w-100 org-button">
                    <Button
                      variant="secondary"
                      onClick={handleClose}
                      className="button-hover"
                      style={{
                        width: "90px",
                        height: "40px",
                        backgroundColor: "#252525",
                        color: "#007bff",
                        borderColor: "#007bff",
                        marginRight: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="secondary"
                      className="button-hover"
                      onClick={() => handlePreview(validateForm)}
                      style={{
                        width: isPreviewMode ? "153px" : "90px",
                        height: "40px",
                        backgroundColor: "#252525",
                        color: "#007bff",
                        borderColor: "#007bff",
                        marginRight: "10px",
                      }}
                    >
                      {isPreviewMode ? "Cancel Preview" : "Preview"}
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      className="button-hover"
                      style={{
                        width: "74px",
                        height: "40px",
                        backgroundColor: "#252525",
                        color: "#007bff",
                        borderColor: "#007bff",
                      }}
                    >
                      {isLoading ? (
                        <span
                          style={{
                            position: "relative",
                            left: "11px",
                          }}
                        >
                          <ThreeCircles
                            height={18}
                            width={18}
                            color="#007bff"
                          />
                        </span>
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddOrganizationModal;
