import React, { useState } from "react";
import Breadcrumb from "../../components/breadcrumbs/BreadCrumb";
import { Button, ButtonGroup } from "react-bootstrap";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Organization from "../../portal/admin/admin-tabs/organizaton/Organizaton";
import Users from "../../portal/admin/admin-tabs/users/Users";
import Invitations from "../../portal/admin/admin-tabs/invitations/Invitations";
import Reports from "../../portal/admin/admin-tabs/reports/Reports";
import SecurityIssues from "../../portal/admin/security-issues/SecurityIssues";

const AdminDashboard = () => {
  const breadcrumbItems = ["Admin"];
  const [selectedTab, setSelectedTab] = useState("Customer Support");
  const [isActiveLink, setIsActiveLink] = useState("Organizations");
  const [orgName, setOrgName] = useState("");

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    if (tab === "Customer Support") {
      setIsActiveLink("Organizations");
    } else if (tab === "File Uploads") {
      setIsActiveLink("Reports");
    }
  };

  const handleOrgName = (name) => {
    setOrgName(name);
  };

  const handleLinkChange = (link) => {
    setIsActiveLink(link);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
        showScanning={false}
        isLastUpdate={false}
      />
      <div className="admin-dashboard">
        <div className="view-by-container d-flex align-items-center justify-content-start">
          <div className="d-flex align-items-center justify-content-center">
            <span style={{ width: "85px", color: "#fff" }}>View By :</span>
            <ButtonGroup aria-label="Basic example" className="rounded-0">
              <Button
                style={{
                  width: "220px",
                  backgroundColor:
                    selectedTab === "Customer Support" ? "#007bff" : "#0a0a0e",
                  color:
                    selectedTab === "Customer Support" ? "#fff" : "#007bff",
                  borderColor: "#007bff",
                }}
                onClick={() => handleTabChange("Customer Support")}
                className="rounded-end rounded-r-0 px-4 rounded-1"
              >
                Customer Support
              </Button>
              <Button
                style={{
                  width: "180px",
                  backgroundColor:
                    selectedTab === "File Uploads" ? "#007bff" : "#0a0a0e",
                  color: selectedTab === "File Uploads" ? "#fff" : "#007bff",
                  borderColor: "#007bff",
                }}
                onClick={() => handleTabChange("File Uploads")}
                className="rounded-start px-4 rounded-l-0 rounded-1"
              >
                File Uploads
              </Button>
            </ButtonGroup>
          </div>
        </div>

        <Navbar className="border-bottom mt-4">
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto ms-4">
              {selectedTab === "Customer Support" && (
                <>
                  <Link
                    className={`disable-link-color discovery-nav-text text-white ${
                      isActiveLink === "Organizations"
                        ? "discovery-active-nav-text"
                        : ""
                    }`}
                    onClick={() => handleLinkChange("Organizations")}
                  >
                    Organizations
                  </Link>
                  <Link
                    className={`disable-link-color discovery-nav-text text-white mx-4 ${
                      isActiveLink === "Users"
                        ? "discovery-active-nav-text"
                        : ""
                    }`}
                    onClick={() => handleLinkChange("Users")}
                  >
                    Users
                  </Link>
                  <Link
                    className={`disable-link-color discovery-nav-text text-white mx-4 ${
                      isActiveLink === "Invitations"
                        ? "discovery-active-nav-text"
                        : ""
                    }`}
                    onClick={() => handleLinkChange("Invitations")}
                  >
                    Invitations
                  </Link>
                </>
              )}

              {selectedTab === "File Uploads" && (
                <>
                  <Link
                    className={`disable-link-color discovery-nav-text text-white mx-4 ${
                      isActiveLink === "Reports"
                        ? "discovery-active-nav-text"
                        : ""
                    }`}
                    onClick={() => handleLinkChange("Reports")}
                  >
                    Reports
                  </Link>
                  <Link
                    className={`disable-link-color discovery-nav-text text-white mx-4 ${
                      isActiveLink === "SecurityIssues"
                        ? "discovery-active-nav-text"
                        : ""
                    }`}
                    onClick={() => handleLinkChange("SecurityIssues")}
                  >
                    Security Issues
                  </Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <div className="tab-content">
          {selectedTab === "Customer Support" &&
            isActiveLink === "Organizations" && (
              <Organization
                setIsActiveLink={setIsActiveLink}
                handleOrgName={handleOrgName}
              />
            )}
          {selectedTab === "Customer Support" && isActiveLink === "Users" && (
            <Users orgName={orgName} handleOrgName={handleOrgName} />
          )}
          {selectedTab === "Customer Support" &&
            isActiveLink === "Invitations" && <Invitations />}

          {selectedTab === "File Uploads" && isActiveLink === "Reports" && (
            <Reports />
          )}
          {selectedTab === "File Uploads" &&
            isActiveLink === "SecurityIssues" && <SecurityIssues />}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminDashboard;
