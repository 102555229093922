import React, { useState, useEffect } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ReactComponent as DotIcon } from "../../../assets/images/threedots.svg";
import { ReactComponent as AddIcon } from "../../../assets/images/add.svg";
import BaseTable from "../../../components/table/BaseTable";
import BaseFilter from "../../../components/filter/BaseFilter";
import AddSecurityIssuesModal from "./AddSecurityIssuesModal";
import { showToast } from "../../../util/toasts";
import DeleteModal from "../../../components/modal/DeleteModal";
import axios from "../../../util/axios";
import moment from "moment";
import { parseFilterString, applyCondition } from "../../../util/conditions";
import { adminSecurityIssueTabsConfig } from "../../../util/tabsConfig.js";

const SecurityIssues = () => {
  const [popoverId, setPopoverId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [securityIssues, setSecurityIssues] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [activeFilters, setActiveFilters] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [editId, setEditId] = useState(null);

  const updateTabsWithData = (issues) => {
    const organizations = [
      ...new Set(issues.map((issue) => issue.mi_organization?.org_name)),
    ].map((name) => ({
      id: name,
      name: name,
      type: "Organization: " + name,
      key: "1",
      active: false,
    }));

    const targets = [
      ...new Set(issues.map((issue) => issue.mi_target?.title)),
    ].map((name) => ({
      id: name,
      name: name,
      type: "Target: " + name,
      key: "2",
      active: false,
    }));

    const issueNames = [
      ...new Set(issues.map((issue) => issue.issue_name)),
    ].map((name) => ({
      id: name,
      name: name,
      type: "Issue Name: " + name,
      key: "3",
      active: false,
    }));

    const riskScores = [
      ...new Set(issues.map((issue) => issue.risk_score)),
    ].map((name) => ({
      id: name,
      name: name,
      type: "Risk Score: " + name,
      key: "4",
      active: false,
    }));

    setTabs(
      adminSecurityIssueTabsConfig({
        organizations,
        targets,
        issueNames,
        riskScores,
      })
    );
  };

  const fetchSecurityIssues = async () => {
    try {
      let { data } = await axios.get("admin/manual-issues", {
        params: { page: 1, pageSize: 500 },
      });
      setSecurityIssues(data?.items);
      if (data?.items?.length > 0) {
        updateTabsWithData(data?.items);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleShowPopover = (id) => {
    setPopoverId((prevId) => (prevId === id ? null : id));
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleClose = () => {
    setShowModal(false);
    setEditId(null);
    setDeleteId(null);
    setPopoverId(null);
  };

  const handleConfirmDelete = async () => {
    try {
      let { data } = await axios.delete(`admin/manual-issues/${deleteId}`);
      fetchSecurityIssues();
      setDeleteId(null);
      setShowModal(false);
      showToast("Security Issue Deleted Successfully", "success");
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setShowModal(true);
    setPopoverId(null);
  };

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const handleShowAddModal = () => {
    setEditId(null);
    setShowAddModal(true);
  };
  const handleCloseAddModal = () => setShowAddModal(false);

  const columns = [
    {
      Header: "Organization",
      accessor: (row) => {
        return <span>{row?.mi_organization?.org_name}</span>;
      },
      isSortable: true,
      key: "mi_organization.org_name",
    },
    {
      Header: "Target",
      accessor: (row) => {
        return <span>{row?.mi_target?.title}</span>;
      },
      isSortable: true,
      key: "mi_target.title",
    },
    {
      Header: "Issue Name",
      accessor: "issue_name",
      isSortable: true,
    },
    {
      Header: "Risk Score",
      accessor: "risk_score",
      isSortable: true,
    },
    {
      Header: "Date Uploaded",
      accessor: (row) => {
        return (
          <span>
            {row?.created_at ? moment(row.created_at).format("DD/MM/YY") : "-"}
          </span>
        );
      },
      isSortable: true,
      key: "created_at",
    },
    {
      Header: "Issue State",
      accessor: (row) => {
        return <span>{row?.is_active ? "Active" : "Inactive"}</span>;
      },
      isSortable: true,
      key: "is_active",
    },
    {
      Header: "",
      accessor: (row) => (
        <OverlayTrigger
          trigger="click"
          placement="left"
          overlay={actionPopover(row?.id)}
          show={popoverId === row?.id}
          rootClose
        >
          <div
            className="text-end"
            onClick={(event) => {
              event.stopPropagation();
              handleShowPopover(row?.id);
            }}
          >
            <DotIcon className="cursor-pointer" />
          </div>
        </OverlayTrigger>
      ),
    },
  ];

  const actionPopover = (id) => (
    <Popover id={`popover-${id}`}>
      <Popover.Body className="admin-popover">
        <div
          className="popover-item"
          onClick={() => {
            setEditId(id);
            setShowAddModal(true);
          }}
        >
          Edit
        </div>
        <div className="popover-item" onClick={() => handleDelete(id)}>
          Delete
        </div>
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    const handleClickOutside = () => {
      if (popoverId) {
        setPopoverId(null);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [popoverId]);

  useEffect(() => {
    fetchSecurityIssues();
  }, []);

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const filteredIssues = securityIssues.filter((issue) => {
    const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
      return groupedFilters[eventKey].some((filter) => {
        if (eventKey === "1") {
          return (
            issue?.mi_organization?.org_name &&
            issue?.mi_organization?.org_name.toLowerCase() ===
              filter.name?.toLowerCase()
          );
        } else if (eventKey === "2") {
          return (
            issue?.mi_target?.title &&
            issue?.mi_target?.title.toLowerCase() === filter.name?.toLowerCase()
          );
        } else if (eventKey === "3") {
          return (
            issue?.issue_name &&
            issue?.issue_name.toLowerCase() === filter.name?.toLowerCase()
          );
        } else if (eventKey === "4") {
          return issue?.risk_score == filter.name;
        } else if (eventKey === "advanced-filter") {
          const parsedFilters = parseFilterString(filter.name);
          return parsedFilters.every((ol) => {
            const { column, condition, value } = ol;
            switch (column) {
              case "org_name":
                return applyCondition(
                  issue.mi_organization?.org_name?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "target":
                return applyCondition(
                  issue.mi_target?.title?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "issue_name":
                return applyCondition(
                  issue.issue_name?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "risk_score":
                return applyCondition(issue.risk_score, condition, value);
              default:
                return false;
            }
          });
        }
        return false;
      });
    });

    const matchesSearch =
      searchValue === "" ||
      (issue.mi_organization?.org_name &&
        issue.mi_organization?.org_name
          .toLowerCase()
          .includes(searchValue.toLowerCase())) ||
      (issue.mi_target?.title &&
        issue.mi_target?.title
          .toLowerCase()
          .includes(searchValue.toLowerCase())) ||
      (issue.issue_name &&
        issue.issue_name.toLowerCase().includes(searchValue.toLowerCase()));

    return matchesFilters && matchesSearch;
  });

  return (
    <>
      <div className="base-filter-container overflow-hidden">
        <div className="left-filter">
          <BaseFilter
            tabs={tabs}
            isSearch={true}
            totalRecords={filteredIssues.length}
            onFilterChange={handleFilterChange}
            activeFilters={activeFilters}
            removeFilter={removeFilter}
            showExport={false}
            searchValue={searchValue}
            onSearchChange={handleSearchChange}
            tableData={filteredIssues}
          />
        </div>
        <div
          className="add-button d-flex align-items-center mb-2"
          onClick={handleShowAddModal}
        >
          <span className="mb-1">
            <AddIcon />
          </span>
          <span className="add-organization ms-1">Add Security Issue</span>
        </div>
      </div>
      <div className="overflow-hidden">
        <BaseTable
          className="mt-3 mb-3"
          columns={columns}
          data={filteredIssues}
          selectable={true}
          showCheckboxes={false}
        />
      </div>
      {showAddModal && (
        <AddSecurityIssuesModal
          show={showAddModal}
          handleClose={handleCloseAddModal}
          fetchSecurityIssues={fetchSecurityIssues}
          id={editId}
          setEditId={setEditId}
        />
      )}
      <DeleteModal
        showModal={showModal}
        handleClose={handleClose}
        handleConfirmDelete={handleConfirmDelete}
        title={"Are you sure you want to delete the update?"}
        subTitle={"Deleting the Security Issues is irreversible"}
      />
    </>
  );
};

export default SecurityIssues;
