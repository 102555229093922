export const spfMapping = {
  "no spf record found": "arm-mx1",
  "syntax errors": "arm-mx2",
  "more than 10 dns lookups": "arm-mx3",
  "more then 10 dns lookups": "arm-mx3",
  "using multiple records": "arm-mx4",
  "softfail use (~all)": "arm-mx5",
  "using deprecated spf mechanisms": "arm-mx6",
};

export const dmarcMapping = {
  "no dmarc record found": "arm-mx7",
  "incorrect dmarc syntax": "arm-mx9",
  "too lenient policy": "arm-mx10",
  "failure to include reporting tags (rua,ruf)": "arm-mx11",

};

export const getArmoryID = (type, status) => {  
  if (type === "SPF") {
    return spfMapping[status.toLowerCase()] || "";
  } else if (type === "DMARC") {
    return dmarcMapping[status.toLowerCase()] || "";
  }
  return "";
};
