import React from "react";
import Card from "react-bootstrap/Card";
import { ReactComponent as HighRisk } from "../../assets/images/high-risk.svg";
import { ReactComponent as ModerateRisk } from "../../assets/images/moderate-risk.svg";
import { ReactComponent as LowRisk } from "../../assets/images/low-risk.svg";

const HighlightsRisk = ({ highRisk, mediumRisk, lowRisk, onRiskClick }) => {
  return (
    <React.Fragment>
      <Card
        style={{
          width: "100%",
          background:
            "linear-gradient(4.67deg, rgba(90, 90, 91, 0) -25.21%, #5A5A5B 130.89%)",
          border: "1px solid #636363",
          color: "white",
        }}
        className="p-3 security-highlight-card"
      >
        <Card.Title className="text-highlights-title">Highlights</Card.Title>
        <Card.Body
          className="pt-0 justify-contentcenter"
          style={{ display: "flex", height: "109px", padding: "0px 0px" }}
        >
          <div className="HighlightsRisk_main m-0 ">
            <div className="HighlightsRisk_content high-risk-new-container">
              <HighRisk className="mx-2" />
              <div
                className="fs-4 text-highlights-title HighlightsRisk_subtitle text-decoration-underline cursor-pointer"
                onClick={() => onRiskClick("high")}
              >
                High Risk | {highRisk}
              </div>
            </div>
            <div className="HighlightsRisk_content high-risk-new-container">
              <ModerateRisk className="mx-2" />
              <div
                className="fs-4 text-highlights-title text-decoration-underline cursor-pointer"
                onClick={() => onRiskClick("medium")}
              >
                Medium Risk | {mediumRisk}
              </div>
            </div>
            <div className="HighlightsRisk_content high-risk-new-container">
              <LowRisk className="mx-2" />
              <div
                className="fs-4 text-highlights-title text-decoration-underline cursor-pointer"
                onClick={() => onRiskClick("low")}
              >
                Low Risk | {lowRisk}
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default HighlightsRisk;
