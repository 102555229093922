import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import BaseFilter from "../../../../components/filter/BaseFilter";
import WebScanner from "../../../../components/web-scanner/WebScanner";
import WebUpdate from "../../../../components/website-updates/WebUpdate";
import DoughnutChart from "../../../../components/charts/DoughnutChart";
import axios from "../../../../util/axios";
import { useParams, useOutletContext, useLocation } from "react-router-dom";
import { ThreeCircles } from "react-loader-spinner";
import GenericCard from "../../../../components/card/GenericCard.js";
import PortsList from "./PortsList.js";
import NoData from "../../../../components/empty/NoData.js";
import { NoDataAfterScanning } from "../../../../components/empty/NoDataAfterScanning.js";
import { ReactComponent as SortNotSelected } from "../../../../assets/images/SortNotSelected.svg";
import { ReactComponent as SortDown } from "../../../../assets/images/SortDown.svg";
import { ReactComponent as SortUp } from "../../../../assets/images/SortUp.svg";
import { ScannerContext } from "../../../../components/ScannerContext";
import { formatScannerName } from "../../../../helpers/formatScannerName.js";
import moment from "moment";
import BarChart from "../../../../components/charts/BarChart";
import BasePagination from "../../../../components/table/BasePagination.js";
import {
  webUpdatesFilter,
  webDataFillter,
} from "../../../../util/genericFunctions.js";
import { parseFilterString, applyCondition } from "../../../../util/conditions";
import { portsTabsConfig } from "../../../../util/tabsConfig.js";

const Ports = () => {
  const { dropdownItems } = useContext(ScannerContext);
  const { scanningStatus } = useOutletContext();
  const routeParams = useParams();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [isDatainProcess, setIsDataInProcess] = useState(false);
  const [isDataisPartiallyProcessing, setIsDataisPartiallyProcessing] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [noDataDoughnutChart, setNoDataDoughnutChart] = useState(false);
  const [noDataVerticalBarChart, setNoDataVerticalBarChart] = useState(false);
  const [ports, setPorts] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [isSortIconHovered, setIsSortIconHovered] = useState(false);
  const [sortDirection, setSortDirection] = useState("");
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [webUpdatesData, setWebUpdatesData] = useState(null);
  const [tabs, setTabs] = useState([]);

  const colorList = [
    "#398FFE",
    "#41E7DF",
    "#B694FF",
    "#238D88",
    "#1D56C9",
    "#727EF7",
    "#8067DC",
    "#39B9FE",
    "#33BAB3",
    "#38CEC7",
    "#81D3FF",
    "#D6C2FF",
    "#41E7DF",
    "#398FFE",
    "#B694FF",
  ];

  const [doughnutChartType, setDoughnutChartType] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: colorList,
        hoverBackgroundColor: colorList,
        borderColor: "transparent",
        borderWidth: 0,
      },
    ],
  });

  const [verticalBarChartType, setVerticalBarChartType] = useState([]);
  const [
    verticalBarChartVulnerabilityType,
    setVerticalBarChartVulnerabilityType,
  ] = useState([]);

  const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";
            const value = context.raw;
            return `${label}: ${value}`;
          },
        },
        yAlign: "bottom",
        xAlign: "center",
      },

      title: {
        display: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
        borderColor: "transparent",
      },
    },
    onHover: (event, chartElement) => {
      const targetCanvas = event.native ? event.native.target : event.target;
      if (chartElement.length) {
        targetCanvas.style.cursor = "pointer";
      } else {
        targetCanvas.style.cursor = "default";
      }
    },
  };

  const encryptedProtocols = [
    {
      id: 0,
      name: "Yes",
      type: "Encrypted Protocol: Yes",
      key: "5",
      active: false,
    },
    {
      id: 1,
      name: "No",
      type: "Encrypted Protocol: No",
      key: "5",
      active: false,
    },
  ];
  const sortPortsByIP = () => {
    let newSortDirection = "";
    if (sortDirection === "") {
      newSortDirection = "asc";
    } else if (sortDirection === "asc") {
      newSortDirection = "desc";
    } else if (sortDirection === "desc") {
      newSortDirection = "";
    }

    const sortedPorts = [...ports];
    if (newSortDirection !== "") {
      sortedPorts.sort((a, b) => {
        const ipA = a.ip.split(".").map((num) => parseInt(num, 10));
        const ipB = b.ip.split(".").map((num) => parseInt(num, 10));

        for (let i = 0; i < ipA.length; i++) {
          if (ipA[i] - ipB[i] !== 0) {
            return newSortDirection === "asc"
              ? ipA[i] - ipB[i]
              : ipB[i] - ipA[i];
          }
        }
        return 0;
      });
    }

    setPorts(sortedPorts);
    setSortDirection(newSortDirection);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
    setCurrentPage(1);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const updateTabsWithData = (portsData) => {
    const ips = Array.from(new Set(portsData.map((port) => port.ip))).map(
      (ip) => ({
        id: ip,
        name: ip,
        type: "IP Address: " + ip,
        key: "1",
        active: false,
      })
    );
    const portsName = Array.from(
      new Set(portsData.flatMap((port) => port.scan_ports.map((p) => p.port)))
    ).map((port) => ({
      id: port,
      name: port.toString(),
      type: "Port: " + port.toString(),
      key: "2",
      active: false,
    }));

    const serviceName = Array.from(
      new Set(
        portsData.flatMap((port) => port.scan_ports.map((p) => p.service))
      )
    ).map((port) => ({
      id: port,
      name: port.toString(),
      type: "Service: " + port.toString(),
      key: "3",
      active: false,
    }));

    const versionName = Array.from(
      new Set(
        portsData.flatMap((port) =>
          port.scan_ports
            .map((p) => {
              const productName = p.product?.trim() || "";
              const version = p.version?.trim() || "";
              return `${productName} ${version}`.trim();
            })
            .filter((name) => name !== "")
        )
      )
    ).map((port) => ({
      id: port,
      name: port.toString(),
      type: "Name & Version: " + port.toString(),
      key: "4",
      active: false,
    }));

    const domainName = Array.from(new Set(portsData.map((port) => port.domain)))
      .filter((name) => name)
      .map((ip) => ({
        id: ip,
        name: ip,
        type: "Domain: " + ip,
        key: "7",
        active: false,
      }));

    setTabs(
      portsTabsConfig({
        ips,
        portsName,
        serviceName,
        versionName,
        encryptedProtocols,
        domainName,
      })
    );
  };

  const fetchPorts = async () => {
    try {
      const { data } = await axios.get(`scans/${routeParams?.target_id}/ports`);
      if (data?.groups?.changes) {
        setWebUpdatesData(data?.groups?.changes);
      }
      if (data?.groups?.security && data?.groups?.vulnerable) {
        setVerticalBarChartType([
          data?.groups?.security?.false || 0,
          data?.groups?.security?.true || 0,
        ]);
        setVerticalBarChartVulnerabilityType([
          data?.groups?.vulnerable?.false || 0,
          data?.groups?.vulnerable?.true || 0,
        ]);
      }
      if (
        scanningStatus === -1 ||
        scanningStatus === 3 ||
        scanningStatus === 4 ||
        scanningStatus === 1 ||
        scanningStatus === 2
      ) {
        if (data?.groups?.security?.false || data?.groups?.security?.true) {
          setNoDataVerticalBarChart(false);
        } else {
          setNoDataVerticalBarChart(true);
        }
      }
      if (data?.groups?.most_used) {
        let usedPorts = Object.entries(data?.groups?.most_used)
          .sort((a, b) => b[1] - a[1])
          .splice(0, 7);
        setDoughnutChartType({
          labels: usedPorts?.map((el) => el[0]?.toUpperCase()) || [],
          datasets: [
            {
              data: usedPorts?.map((el) => el[1]) || [],
              backgroundColor: colorList,
              hoverBackgroundColor: colorList,
            },
          ],
        });
        if (
          scanningStatus === -1 ||
          scanningStatus === 3 ||
          scanningStatus === 4 ||
          scanningStatus === 1 ||
          scanningStatus ===2 
        ) {
          if (
            usedPorts?.map((el) => el[1])?.every((value) => value === 0) ||
            usedPorts?.map((el) => el[1])?.length === 0
          ) {
            setNoDataDoughnutChart(true);
          } else {
            setNoDataDoughnutChart(false);
          }
        }
      }
      if (data?.ports?.length > 0) {
        let modifiedData = data.ports?.map((el) => {
          return {
            ...el,
            ip: el.ip?.ip,
            scan_ports: el.scan_ports.map((p) => ({
              ...p,
              port: `${p.port}`,
            })),
          };
        });
        setPorts(modifiedData);
        updateTabsWithData(modifiedData);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const getExportedRows = (dataForExport) => {    
    const body = [];
    dataForExport.forEach((entry) => {
      entry.scan_ports.forEach((port) => {
        const row = [];
        row.push(entry.ip);
        row.push(
          port.port,
          port.service,
          `${port.product || ""} ${port.version || ""}`.trim() || "-",
          port.is_encrypted ? "Yes" : "No"
        );
        body.push(row);
      });
    });
    return body;
  };

  const handleWebUpdateSort = (name) => {
    setActiveFilters(webUpdatesFilter(name, activeFilters));
  };

  const statusMap = { new: 1, changed: 2, unchanged: 0 };

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const filteredPorts = ports
    .filter((port) => {
      const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
        return groupedFilters[eventKey].some((filter) => {
          const lowerCaseFilter = filter.name.toLowerCase();
          if (eventKey === "1") {
            return port.ip && port.ip.toLowerCase() === lowerCaseFilter;
          } else if (eventKey === "7") {
            return port.domain && port.domain.toLowerCase() === lowerCaseFilter;
          } else if (eventKey === "2") {
            return port.scan_ports.some(
              (scanPort) => scanPort.port.toString() === lowerCaseFilter
            );
          } else if (eventKey === "3") {
            return port.scan_ports.some(
              (scanPort) =>
                scanPort.service &&
                scanPort.service.toLowerCase() === lowerCaseFilter
            );
          } else if (eventKey === "4") {
            return port.scan_ports.some(
              (scanPort) =>
                `${scanPort.product || ""} ${scanPort.version || ""}`
                  .trim()
                  .toLowerCase() === lowerCaseFilter
            );
          } else if (eventKey === "5") {
            return (
              (lowerCaseFilter === "yes" &&
                port.scan_ports.some((scanPort) => scanPort.is_encrypted)) ||
              (lowerCaseFilter === "no" &&
                port.scan_ports.some((scanPort) => !scanPort.is_encrypted))
            );
          } else if (eventKey === "6") {
            return port.scan_ports.some(
              (scanPort) =>
                scanPort.change_status === statusMap[filter.name.toLowerCase()]
            );
          } else if (eventKey === "advanced-filter") {
            const parsedFilters = parseFilterString(filter.name);
            return parsedFilters.every((ol) => {
              const { column, condition, value } = ol;
              switch (column) {
                case "ip":
                  return applyCondition(port.ip, condition, value);
                case "domain":
                  return applyCondition(port.domain, condition, value);
                case "port":
                  return port.scan_ports.some((scanPort) =>
                    applyCondition(scanPort.port, condition, value)
                  );
                case "service":
                  return port.scan_ports.some((scanPort) =>
                    applyCondition(scanPort.service, condition, value)
                  );
                case "product":
                  return port.scan_ports.some((scanPort) =>
                    applyCondition(
                      `${scanPort.product || ""} ${
                        scanPort.version || ""
                      }`.trim(),
                      condition,
                      value
                    )
                  );
                case "is_encrypted":
                  return (
                    (value?.toLowerCase() === "yes" &&
                      port.scan_ports.some(
                        (scanPort) => scanPort.is_encrypted
                      )) ||
                    (value?.toLowerCase() === "no" &&
                      port.scan_ports.some(
                        (scanPort) => !scanPort.is_encrypted
                      ))
                  );
                case "current_state":
                  return port.scan_ports.some((scanPort) =>
                    applyCondition(
                      scanPort.change_status,
                      condition,
                      statusMap[value.toLowerCase()]
                    )
                  );
                default:
                  return false;
              }
            });
          }
          return false;
        });
      });

      const matchesSearch =
        searchValue === "" ||
        port.ip.toLowerCase().includes(searchValue.toLowerCase()) ||
        port.scan_ports.some(
          (scanPort) =>
            scanPort.port.toString().includes(searchValue.toLowerCase()) ||
            (scanPort.service &&
              scanPort.service
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            `${scanPort.product || ""} ${scanPort.version || ""}`
              .trim()
              .toLowerCase()
              .includes(searchValue.toLowerCase())
        );
      return matchesFilters && matchesSearch;
    })
    .map((port) => {
      let regex =
        /^((?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])[.]){3}(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/;

      const allIPs = activeFilters.every(
        (filter) => regex.test(filter.name) || filter.eventKey == "7"
      );
      return {
        ...port,
        scan_ports: port.scan_ports.filter(
          (scanPort) =>
            activeFilters.length === 0 ||
            allIPs ||
            (groupedFilters["2"] &&
              groupedFilters["2"].some(
                (filter) =>
                  scanPort.port.toString() === filter.name.toLowerCase()
              )) ||
            (groupedFilters["3"] &&
              groupedFilters["3"].some(
                (filter) =>
                  scanPort.service &&
                  scanPort.service.toLowerCase() === filter.name.toLowerCase()
              )) ||
            (groupedFilters["4"] &&
              groupedFilters["4"].some(
                (filter) =>
                  `${scanPort.product || ""} ${scanPort.version || ""}`
                    .trim()
                    .toLowerCase() === filter.name.toLowerCase()
              )) ||
            (groupedFilters["5"] &&
              groupedFilters["5"].some(
                (filter) =>
                  (filter.name.toLowerCase() === "yes" &&
                    scanPort.is_encrypted) ||
                  (filter.name.toLowerCase() === "no" && !scanPort.is_encrypted)
              )) ||
            (groupedFilters["6"] &&
              groupedFilters["6"].some(
                (filter) =>
                  scanPort.change_status ===
                  statusMap[filter.name.toLowerCase()]
              )) ||
            (groupedFilters["advanced-filter"] &&
              groupedFilters["advanced-filter"].some((filter) => {
                const parsedFilters = parseFilterString(filter.name);
                const withoutIpFilter = parsedFilters?.filter(
                  (el) => el.column != "ip" && el.column != "domain"
                );

                return withoutIpFilter.every((ol) => {
                  const { column, condition, value } = ol;
                  switch (column) {
                    case "port":
                      return applyCondition(scanPort.port, condition, value);

                    case "service":
                      return applyCondition(scanPort.service, condition, value);
                    case "product":
                      return applyCondition(
                        `${scanPort.product || ""} ${
                          scanPort.version || ""
                        }`.trim(),
                        condition,
                        value
                      );

                    case "is_encrypted":
                      return (
                        (value?.toLowerCase() === "yes" &&
                          scanPort.is_encrypted) ||
                        (value?.toLowerCase() === "no" &&
                          !scanPort.is_encrypted)
                      );
                    case "current_state":
                      return applyCondition(
                        scanPort.change_status,
                        condition,
                        statusMap[value.toLowerCase()]
                      );
                    default:
                      return false;
                  }
                });
              }))
        ),
      };
    });

  const barChartData = useMemo(
    () => ({
      labels: ["Encryption"],
      datasets: [
        {
          label: "Secured",
          data: [verticalBarChartType?.[1] || 0],
          backgroundColor: ["#3DDC97", "#3DDC97"],
          borderColor: ["rgb(75, 192, 192)"],
          borderWidth: 1,
          maxBarThickness: 70,
          datalabels: {
            anchor: "center",
            align: "center",
            color: "white",
            formatter: (value) => value,
          },
        },
        {
          label: "Unsecured",
          data: [
            verticalBarChartType?.[0] || 0,
            verticalBarChartVulnerabilityType?.[0] || 0,
          ],
          backgroundColor: ["#FF6155", "#FF6155"],
          borderColor: ["rgb(255, 99, 132)"],
          borderWidth: 1,
          maxBarThickness: 70,
          borderRadius: {
            topLeft: 5,
            topRight: 5,
          },
          datalabels: {
            anchor: "center",
            align: "center",
            color: "white",
            formatter: (value) => value,
          },
        },
      ],
    }),
    [verticalBarChartType, verticalBarChartVulnerabilityType]
  );

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "white",
          usePointStyle: true,
          pointStyle: "rectRounded",
          radius: 5,
          boxWidth: 10,
          boxHeight: 10,
          padding: 10,
          font: {
            family: "Kodchasan",
          },
        },
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        padding: {
          top: 20,
          bottom: 20,
          left: 20,
          right: 20,
        },
        callbacks: {
          title: function () {
            return "";
          },
          label: function (context) {
            const label = context.dataset.label || "";
            const value = context.raw;
            return `${label}: ${value}`;
          },
        },
        yAlign: "bottom",
        xAlign: "center",
        titleFont: {
          family: "Kodchasan",
        },
        bodyFont: {
          family: "Kodchasan",
        },
      },
      datalabels: {
        color: "white",
        anchor: "center",
        font: {
          family: "Kodchasan",
        },
        align: "center",
        formatter: (value) => value,
      },
    },
    onHover: (event, elements) => {
      event.native.target.style.cursor = elements.length
        ? "pointer"
        : "default";
    },
    scales: {
      y: {
        beginAtZero: true,
        stacked: true,
        ticks: {
          color: "white",
          font: {
            family: "Kodchasan",
          },
        },
        border: { dash: [4, 4] },

        grid: {
          color: "rgba(255, 255, 255, 0.1)",
          borderDash: [5, 5],
          borderDashOffset: 2,
        },
      },
      x: {
        stacked: true,
        ticks: {
          color: "white",
          font: {
            family: "Kodchasan",
          },
        },
        display: true,
        legend: {
          display: false,
        },
        grid: {
          color: "transparent",
          drawOnChartArea: true,
          borderDash: [10, 10],
          borderDashOffset: function (context) {
            return context.index === context.chart.data.labels.length ? 0 : 10;
          },
        },
        border: {
          color: "#959595",
          width: 1,
        },
      },
    },
    layout: {
      padding: {
        top: 10,
      },
    },
  };
  const handleChartClick = (name) => {
    const [portText, portNumber] = name.split(" ");

    const formattedPortText = /^[a-zA-Z]+$/.test(portText)
      ? portText.toLowerCase()
      : portText;
    const updatedFiltersForPort = webUpdatesFilter(
      portNumber.toString(),
      activeFilters,
      "2",
      "Port"
    );

    const updatedFiltersForService = webUpdatesFilter(
      formattedPortText,
      updatedFiltersForPort,
      "3",
      "Service"
    );
    const updatedPortTabData = tabs[1].data.map((port) => ({
      ...port,
      active: updatedFiltersForPort.some((filter) => filter.name === port.name),
    }));

    const updatedServiceTabData = tabs[2].data.map((service) => ({
      ...service,
      active: updatedFiltersForService.some(
        (filter) => filter.name === service.name
      ),
    }));

    setTabs((prevTabs) => {
      const newTabs = [...prevTabs];
      newTabs[1] = { ...newTabs[1], data: updatedPortTabData };
      newTabs[2] = { ...newTabs[2], data: updatedServiceTabData };
      return newTabs;
    });

    setActiveFilters(updatedFiltersForService);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredPorts.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const totalPages = Math.ceil(filteredPorts.length / recordsPerPage);
  const fetchIntervalRef = useRef();
  useEffect(() => {
    clearInterval(fetchIntervalRef.current);

    if (scanningStatus === 0) {
      setIsLoading(false);
      setIsDataInProcess(true);
    } else {
      setIsDataInProcess(false);
      if (
        scanningStatus === 3 ||
        scanningStatus === -1 ||
        scanningStatus === 4
      ) {
        setIsDataisPartiallyProcessing(false);
        setIsLoading(true);
        fetchPorts();
      } else if (scanningStatus === 2 || scanningStatus === 1) {
        setIsDataisPartiallyProcessing(true);
        setIsLoading(true);
        fetchPorts();
        fetchIntervalRef.current = setInterval(fetchPorts, 5000);
      }
    }
    return () => {
      clearInterval(fetchIntervalRef.current);
    };
  }, [scanningStatus, routeParams?.target_id]);

  const handleBarChartClick = (encryptedProtocol) => {
    const updatedFilters = webUpdatesFilter(
      encryptedProtocol,
      activeFilters,
      "5",
      "Encrypted Protocol"
    );
    setActiveFilters(updatedFilters);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (location?.search) {
      setActiveFilters([
        {
          eventKey: "1",
          name: `${new URLSearchParams(location.search).get("ip")}`,
          type: `IP Address: ${new URLSearchParams(location.search).get("ip")}`,
        },
      ]);
    }
  }, [location?.search]);

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="main_container_style">
          <div className="pb-4 row top-container-main web-updates-container">
            <div className="col-12 col-lg-6 h-100 web-updates-col-7">
              <div className="row gap-4 top-container-main-row">
                <div className="col-12 h-50">
                  <GenericCard
                    children={
                      isDatainProcess ? (
                        <NoData />
                      ) : isDataisPartiallyProcessing && noDataDoughnutChart ? (
                        <NoData />
                      ) : noDataDoughnutChart ? (
                        <NoDataAfterScanning />
                      ) : (
                        <DoughnutChart
                          data={doughnutChartType}
                          options={doughnutChartOptions}
                          onHandleClick={handleChartClick}
                        />
                      )
                    }
                    title={"Most Used Ports"}
                  />
                </div>
                <div className="col-12 h-50">
                  <div className="row h-100">
                    <div className="col-5">
                      <GenericCard
                        children={
                          <WebUpdate
                            data={webUpdatesData}
                            onSort={handleWebUpdateSort}
                            dataInProcess={
                              isDatainProcess || isDataisPartiallyProcessing
                            }
                          />
                        }
                        title={"Updates"}
                      />
                    </div>
                    <div className="col-7">
                      <GenericCard
                        children={
                          isDatainProcess ? (
                            <NoData />
                          ) : isDataisPartiallyProcessing &&
                            noDataDoughnutChart ? (
                            <NoData />
                          ) : noDataVerticalBarChart ? (
                            <NoDataAfterScanning />
                          ) : (
                            <>
                              <BarChart
                                barData={barChartData}
                                optionsData={barChartOptions}
                                onBarClick={handleBarChartClick}
                                isData={
                                  !!(
                                    verticalBarChartType &&
                                    verticalBarChartType[0] !== undefined &&
                                    verticalBarChartType[1] !== undefined
                                  )
                                }
                              />
                            </>
                          )
                        }
                        title={"Port Security"}
                        subtitle={"View Issues"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 h-100 web-updates-col-5">
              <WebScanner title={"Ports, Services & Protocols Scanner"} />
            </div>
          </div>

          <div className=" pb-5">
            <div className="py-4">
              <BaseFilter
                tabs={tabs}
                onFilterChange={handleFilterChange}
                activeFilters={activeFilters}
                removeFilter={removeFilter}
                className="mb-3"
                totalRecords={filteredPorts.length}
                exportHeader={[
                  "IP Address",
                  "Port",
                  "Service",
                  "Name & Version",
                  "Encrypted Protocol",
                ]}
                exportTitle={`PortsServices&Protocols_${formatScannerName(
                  dropdownItems.filter((item) => {
                    return item.target_uuid === routeParams?.target_id;
                  })[0]?.title
                )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
                exportRows={getExportedRows(filteredPorts)}
                isDatainProcess={isDatainProcess || isDataisPartiallyProcessing}
                searchValue={searchValue}
                onSearchChange={handleSearchChange}
                isSearch={true}
                tableData={filteredPorts}
              />
            </div>
            <div>
              <div
                className="port-sort-container d-flex align-items-center"
                onMouseEnter={() => setIsSortIconHovered(true)}
                onMouseLeave={() => setIsSortIconHovered(false)}
                onClick={sortPortsByIP}
              >
                IP Address
                {isSortIconHovered || sortDirection ? (
                  sortDirection === "desc" ? (
                    <SortDown />
                  ) : sortDirection === "asc" ? (
                    <SortUp />
                  ) : (
                    <SortNotSelected />
                  )
                ) : null}
              </div>
              {isDatainProcess ? (
                <div className="data-in-process">Data in Process </div>
              ) : (
                <div>
                  {currentRecords.length > 0 ? (
                    currentRecords.map((ipData, index) => (
                      <PortsList
                        key={`${ipData.ip}-${index}`}
                        port={ipData}
                        ipStatus={ipData.status}
                      />
                    ))
                  ) : (
                    <div className="data-in-process">
                      {isDataisPartiallyProcessing
                        ? "Data in Process"
                        : "No Data Was Found"}
                    </div>
                  )}
                  <BasePagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    recordsPerPage={recordsPerPage}
                    dataLength={filteredPorts.length}
                    handlePageChange={handlePageChange}
                    handelPerPage={(value) => {
                      setCurrentPage(1);
                      setRecordsPerPage(value);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Ports;
