import React from "react";
import "chartjs-plugin-datalabels";
import "chart.js/auto";
import ComparisonChart from "../charts/ComparisonChart";
import moment from "moment";
import { ReactComponent as LdsSpinner } from "../../assets/images/spinner-lds.svg";

const OffenseCard = ({ cardsData }) => {
  const inProcessText = cardsData?.CardTitle?.includes("SSH")
    ? "Locating SSH Services..."
    : cardsData?.CardTitle?.includes("FTP")
    ? "Locating FTP Services..."
    : cardsData?.CardTitle?.includes("Email")
    ? "Analyzing Email Records..."
    : "Finding Subdomains related to the organization...";
  const noServicesText = cardsData?.CardTitle?.includes("SSH")
    ? "SSH"
    : cardsData?.CardTitle?.includes("FTP")
    ? "FTP"
    : cardsData?.CardTitle?.includes("Email")
    ? "Email"
    : "Subdomain";
  const initiatedText =
    cardsData?.CardTitle == "Email Spoofing"
      ? "Email Spoofing Attack"
      : cardsData?.CardTitle;
  return (
    <React.Fragment>
      <div className="offensecard_main">
        {cardsData.image ? (
          <div className="image-card">
            <div className="offensecard_title">
              Attack: <span>{cardsData?.CardTitle || "Unknown Attack"}</span>
            </div>
            <div className="dotted-line-image"></div>
            {!cardsData?.isComingSoon && (
              <div className="offensecard_output_section">
                <div className="offensecard_output_title">Output</div>
                <div className="margindiv"></div>
                <div className="card_subsection">
                  <ul
                    style={{
                      padding: "6px 24px 0px",
                      textAlign: "left",
                      height: "300px",
                    }}
                  >
                    <li className="fw-semibold">
                      [+] [
                      {moment(cardsData.start_time).format("YYYY-MM-DD hh:mm")}]{" "}
                      {cardsData?.CardTitle == "Email Spoofing"
                        ? "Email Spoofing Attack"
                        : cardsData?.CardTitle}{" "}
                      Initiated
                    </li>
                    <li className="greentext my-4">{inProcessText}</li>
                    {!cardsData?.inProcess && (
                      <li className="fw-semibold">
                        [+] [
                        {moment(cardsData.end_time).format("YYYY-MM-DD hh:mm")}]
                        No {noServicesText} Services Detected
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            )}
            {!cardsData?.isComingSoon && (
              <div className="fw-bold text-left">Results</div>
            )}
            <div className="offensecard_image_container">
              {cardsData?.inProcess ? (
                <div>
                  <div className="d-flex justify-content-center">
                    <LdsSpinner />
                  </div>
                  <p className="coming-soon-text">Processing Data...</p>
                </div>
              ) : cardsData?.isComingSoon ? (
                <>
                  <img
                    src={cardsData.image}
                    alt="Card icon"
                    className="offensecard_image"
                  />
                  <p className="coming-soon-text">Coming Soon</p>
                </>
              ) : cardsData?.data?.length > 0 ? (
                <img
                  src={cardsData.image}
                  alt="Card icon"
                  className="offensecard_image"
                />
              ) : (
                <p className="coming-soon-text">No Results Were Found</p>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="offensecard_title">
              Attack: <span>{cardsData?.CardTitle}</span>
            </div>
            <div className="dotted-line"></div>
            <div className="offensecard_output_section">
              <div className="offensecard_output_title">Output</div>
              <div className="margindiv"></div>
              <div className="card_subsection">
                <ul
                  style={{
                    padding: "6px 24px 0px",
                  }}
                >
                  <li className="fw-semibold">
                    [+] [
                    {moment(new Date(cardsData.start_time)).format(
                      "YYYY-MM-DD hh:mm"
                    )}
                    ] {initiatedText} Initiated
                  </li>
                  <br />
                  {cardsData?.CardTitle == "Email Spoofing" && (
                    <li className="fw-semibold">
                      [+] [
                      {moment(new Date(cardsData.start_time)).format(
                        "YYYY-MM-DD hh:mm"
                      )}
                      ] Starting the Attack
                    </li>
                  )}

                  {cardsData?.CardTitle == "Email Spoofing" && <br />}
                  {cardsData?.data?.map((item) => (
                    <>
                      <li className="greentext">{item?.ip}</li>
                      <li className="greentext">{item?.passAuth}</li>
                      <li className="greentext">{item?.dictionary}</li>
                      <li className="greentext">{item?.responses}</li>
                      <li className="">Result: {item?.result}</li>
                      <br />
                    </>
                  ))}
                  <li className="fw-semibold">
                    [+] [
                    {moment(new Date(cardsData.end_time)).format(
                      "YYYY-MM-DD hh:mm"
                    )}
                    ] Attack Completed
                  </li>
                </ul>
              </div>
            </div>
            <ComparisonChart cardsData={cardsData} />
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default OffenseCard;
