import React, { useState, useEffect, useRef } from "react";
import ArmoryTestedModal from "../../components/armorytested-modal/ArmoryTestedModal";
import OffenseCard from "../../components/offense-card/OffenseCard";
import skeletonIcon from "../../assets/images/skeleton.png";
import { useParams } from "react-router-dom";
import axios from "../../util/axios";
import { ThreeCircles } from "react-loader-spinner";
import { UseServices } from "../../hooks/useServices.js";

const Dashboard = () => {
  const routeParams = useParams();
  const { allServices } = UseServices();
  const sshIntervalRef = useRef(null);
  const ftpIntervalRef = useRef(null);
  const subdomainIntervalRef = useRef(null);
  const spoofingIntervalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [cardData, setCardData] = useState([
    {
      CardTitle: "SSH Dictionary Attack",
      image: skeletonIcon,
    },
    {
      CardTitle: "FTP Dictionary Attack",
      image: skeletonIcon,
    },
    {
      CardTitle: "Subdomain Takeover",
      image: skeletonIcon,
    },
    {
      CardTitle: "Email Spoofing",
      image: skeletonIcon,
    },
    {
      CardTitle: "RDP Dictionary Attack",
      image: skeletonIcon,
      isComingSoon: true,
    },
    {
      CardTitle: "Telnet Dictionary Attack",
      image: skeletonIcon,
      isComingSoon: true,
    },
  ]);
  const [ftpStatus, setFtpStatus] = useState(null);
  const [sshStatus, setSshStatus] = useState(null);
  const [subdomainStatus, setSubdomainStatus] = useState(null);
  const [spoofingStatus, setSpoofingStatus] = useState(null);
  const [sshStartTime, setSshStartTime] = useState(null);
  const [sshEndTime, setSshEndTime] = useState(null);
  const [ftpStartTime, setFtpStartTime] = useState(null);
  const [ftpEndTime, setFtpEndTime] = useState(null);
  const [subdomainStartTime, setSubdomainStartTime] = useState(null);
  const [subdomainEndTime, setSubdomainEndTime] = useState(null);
  const [spoofingStartTime, setSpoofingStartTime] = useState(null);
  const [spoofingEndTime, setSpoofingEndTime] = useState(null);

  const processData = (response, serviceType) => {
    if (!response?.data || response?.data[serviceType]?.length === 0)
      return null;
    const dataField = response.data[serviceType];
    const successCount = response.data.groups?.creds?.true || 0;
    const failCount = response.data.groups?.creds?.false || 0;

    const data = dataField.map((item) => {
      let passAuth = "";
      let dictionary = "";
      let responses = "";
      let result = "";
      if (serviceType == "ssh") {
        if (item?.hasPassworAuth) {
          passAuth = "Password Authentication Enabled";
          dictionary = "Dictionary Attack Attempted";
        } else {
          passAuth = "Password Authentication Disabled";
          dictionary = "Dictionary Attack Not Possible";
        }
        if (item?.creds?.length) {
          result = `Attack Succeeded on ${item?.ip?.ip}`;
        } else {
          result = `Attack Failed on ${item?.ip?.ip}`;
        }
      } else if (serviceType === "ftp") {
        if (item?.is_ftp_anon) {
          passAuth = "Anonymous Authentication Enabled";
          dictionary = "Dictionary Attack Attempted";
        } else {
          passAuth = "Anonymous Authentication Disabled";
          dictionary = "Dictionary Attack Attempted";
        }
        if (item?.creds?.length) {
          result = `Attack Succeeded on ${item?.ip?.ip}`;
        } else {
          result = `Attack Failed on ${item?.ip?.ip}`;
        }
      } else if (serviceType === "subdomain") {
        passAuth = "Identifying DNS Records";
        dictionary = "Checking HTTP Status Code and Service Availability";
        responses = "Analyzing Responses";
        if (item?.result) {
          result = `Subdomain ${item?.host} is vulnerable to subdomain takeover`;
        } else {
          result = `Subdomain ${item?.host} is not vulnerable to subdomain takeover`;
        }
      } else if (serviceType === "spoofing") {
        passAuth = item?.spf_secure
          ? "SPF Record is Secured"
          : "SPF Record is Unsecured";
        if (!item?.dmarc_secure) {
          dictionary = "DMARC Record is Unsecured";
          responses = "Email Spoofing Attack Attempted";
          if (item?.result) {
            result = `Attack Succeeded, and email received from ${item?.host}`;
          } else {
            result = `Attack Failed, email rejected by ${item?.host}`;
          }
        } else {
          dictionary = "DMARC Record is Secured";
          responses = "Email Spoofing Attack Could Not Be Performed";
          result = `Email spoofing attack prevented by ${item?.host}`;
        }
      }
      return {
        id: item?.id,
        ip:
          serviceType == "spoofing"
            ? `Domain Analyzed: ${item?.host}`
            : `${
                serviceType === "subdomain"
                  ? "Subdomain"
                  : serviceType?.replace("_", " ").toUpperCase()
              } Detected on ${
                serviceType === "subdomain" ? item?.host : item?.ip?.ip
              }`,
        passAuth,
        dictionary,
        responses,
        result,
      };
    });
    return {
      CardTitle:
        serviceType == "subdomain"
          ? "Subdomain Takeover"
          : serviceType == "spoofing"
          ? "Email Spoofing"
          : `${serviceType?.replace("_", " ").toUpperCase()} Dictionary Attack`,
      data,
      start_time: response.data.start_end_time[0].service_start_time,
      end_time: response.data.start_end_time[0].service_end_time,
      success: successCount,
      fail: failCount,
      serviceType: serviceType,
      data1: [
        {
          value: failCount,
          color: "#FF6155",
        },
      ],
      data2: [
        {
          value: successCount,
          color: "#3DDC97",
        },
      ],
    };
  };

  const fetchSSHData = async () => {
    try {
      const response = await axios.get(`scans/${routeParams?.id}/ssh`);
      const sshData = processData(response, "ssh");
      const inProcess = sshStatus === 0 || sshStatus === 1 || sshStatus === 2;
      setCardData((prevData) => [
        sshData || {
          CardTitle: "SSH Dictionary Attack",
          image: skeletonIcon,
          inProcess: inProcess,
          start_time: sshStartTime,
          end_time: sshEndTime,
        },
        ...prevData.slice(1),
      ]);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    allServices?.forEach((service) => {
      if (service.service_name === "default_credential") {
        setSshStatus(service.status);
        setSshStartTime(service.created_at);
        setSshEndTime(service.updated_at);
      }
      if (service.service_name === "ftp_credential") {
        setFtpStatus(service.status);
        setFtpStartTime(service.created_at);
        setFtpEndTime(service.updated_at);
      }
      if (service.service_name === "subdomain_takeover") {
        setSubdomainStatus(service.status);
        setSubdomainStartTime(service.created_at);
        setSubdomainEndTime(service.updated_at);
      }
      if (service.service_name === "email_security") {
        setSpoofingStatus(service.status);
        setSpoofingStartTime(service.created_at);
        setSpoofingEndTime(service.updated_at);
      }
    });
  }, [allServices]);

  const fetchFTPData = async () => {
    try {
      const response = await axios.get(`scans/${routeParams?.id}/ftp`);
      const ftpData = processData(response, "ftp");
      const inProcess = ftpStatus === 0 || ftpStatus === 1 || ftpStatus === 2;

      setCardData((prevData) => [
        ...prevData.slice(0, 1),
        ftpData || {
          CardTitle: "FTP Dictionary Attack",
          image: skeletonIcon,
          inProcess: inProcess,
          start_time: ftpStartTime,
          end_time: ftpEndTime,
        },
        ...prevData.slice(2),
      ]);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const fetchSubzyData = async () => {
    try {
      const subzyResponse = await axios.get(
        `scans/${routeParams?.id}/subzylogs`
      );
      const updateSubzyResponse = subzyResponse?.data?.data?.length
        ? {
            ...subzyResponse,
            data: {
              subdomain: subzyResponse?.data?.data,
              groups: {
                creds: {
                  true: subzyResponse?.data?.countGroup?.success || 0,
                  false: subzyResponse?.data?.countGroup?.failed || 0,
                },
              },
              start_end_time: [
                {
                  service_start_time: subzyResponse?.data?.data[0].created_at,
                  service_end_time:
                    subzyResponse?.data?.data[
                      subzyResponse?.data?.data?.length - 1
                    ].created_at,
                },
              ],
            },
          }
        : null;
      const subzyData = processData(updateSubzyResponse, "subdomain");
      const inProcess =
        subdomainStatus === 0 || subdomainStatus === 1 || subdomainStatus === 2;

      setCardData((prevData) => [
        ...prevData.slice(0, 2),
        subzyResponse?.data?.data?.length
          ? subzyData
          : {
              CardTitle: "Subdomain Takeover",
              image: skeletonIcon,
              inProcess: inProcess,
              start_time: subdomainStartTime,
              end_time: subdomainEndTime,
            },
        ...prevData.slice(3),
      ]);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const fetchSoofingData = async () => {
    try {
      const spoofingResponse = await axios.get(
        `scans/${routeParams?.id}/spoofinglogs`
      );
      const updateSpoofingResponse = spoofingResponse?.data?.data?.length
        ? {
            ...spoofingResponse,
            data: {
              spoofing: spoofingResponse?.data?.data,
              groups: {
                creds: {
                  true: spoofingResponse?.data?.countGroup?.success || 0,
                  false: spoofingResponse?.data?.countGroup?.failed || 0,
                },
              },
              start_end_time: [
                {
                  service_start_time:
                    spoofingResponse?.data?.data[0].created_at,
                  service_end_time:
                    spoofingResponse?.data?.data[
                      spoofingResponse?.data?.data?.length - 1
                    ].created_at,
                },
              ],
            },
          }
        : null;
      const spoofingData = processData(updateSpoofingResponse, "spoofing");
      const inProcess =
        spoofingStatus === 0 || spoofingStatus === 1 || spoofingStatus === 2;

      setCardData((prevData) => [
        ...prevData.slice(0, 3),
        spoofingResponse?.data?.data?.length
          ? spoofingData
          : {
              CardTitle: "Email Spoofing",
              image: skeletonIcon,
              inProcess: inProcess,
              start_time: spoofingStartTime,
              end_time: spoofingEndTime,
            },
        ...prevData.slice(4),
      ]);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    clearInterval(sshIntervalRef.current);

    if (sshStatus === 0) {
      setIsLoading(false);
      fetchSSHData();
    } else {
      if (sshStatus === 3 || sshStatus === -1 || sshStatus === 4) {
        fetchSSHData();
      } else if (sshStatus === 2 || sshStatus === 1) {
        fetchSSHData();
        sshIntervalRef.current = setInterval(fetchSSHData, 5000);
      } else {
        setIsLoading(false);
      }
    }

    return () => {
      clearInterval(sshIntervalRef.current);
    };
  }, [sshStatus, routeParams?.id]);

  useEffect(() => {
    clearInterval(ftpIntervalRef.current);

    if (ftpStatus === 0) {
      setIsLoading(false);
      fetchFTPData();
    } else {
      if (ftpStatus === 3 || ftpStatus === -1 || ftpStatus === 4) {
        fetchFTPData();
      } else if (ftpStatus === 2 || ftpStatus === 1) {
        fetchFTPData();
        ftpIntervalRef.current = setInterval(fetchFTPData, 5000);
      } else {
        setIsLoading(false);
      }
    }

    return () => {
      clearInterval(ftpIntervalRef.current);
    };
  }, [ftpStatus, routeParams?.id]);

  useEffect(() => {
    clearInterval(subdomainIntervalRef.current);
    if (subdomainStatus === 0) {
      setIsLoading(false);
      fetchSubzyData();
    } else {
      if (
        subdomainStatus === 3 ||
        subdomainStatus === -1 ||
        subdomainStatus === 4
      ) {
        fetchSubzyData();
      } else if (subdomainStatus === 2 || subdomainStatus === 1) {
        fetchSubzyData();
        subdomainIntervalRef.current = setInterval(fetchSubzyData, 5000);
      } else {
        setIsLoading(false);
      }
    }

    return () => {
      clearInterval(subdomainIntervalRef.current);
    };
  }, [subdomainStatus, routeParams?.id]);

  useEffect(() => {
    clearInterval(spoofingIntervalRef.current);
    if (spoofingStatus === 0) {
      setIsLoading(false);
      fetchSoofingData();
    } else {
      if (
        spoofingStatus === 3 ||
        spoofingStatus === -1 ||
        spoofingStatus === 4
      ) {
        fetchSoofingData();
      } else if (spoofingStatus === 2 || spoofingStatus === 1) {
        fetchSoofingData();
        spoofingIntervalRef.current = setInterval(fetchSoofingData, 5000);
      } else {
        setIsLoading(false);
      }
    }

    return () => {
      clearInterval(spoofingIntervalRef.current);
    };
  }, [spoofingStatus, routeParams?.id]);

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <div>
          <ArmoryTestedModal />
          <div className="d-flex flex-wrap align-items-center justify-content-center gap-4 mb-5">
            {cardData.map((card, index) => (
              <OffenseCard key={index} cardsData={card} />
            ))}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Dashboard;
