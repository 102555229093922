import { OverlayTrigger, Popover } from "react-bootstrap";
import { ReactComponent as CopyIcon } from "../../../../assets/images/copy.svg";
import { toast } from "react-toastify";
import DecisionToggle from "../../../../components/badge/DecisionToggle";
import Moment from "react-moment";
import HostnamesList from "./HostNameList";
import moment from "moment";
import CurrentStatus from "../../../../components/status/CurrentStatus.js";
import { showToast } from "../../../../util/toasts.js";
import ExpiredColumn from "./ExpiredColumn";
import CertificateNumberReduction from "./CertificateNumberReduction";
import { formatDate } from "../../../../util/formatDate.js";

export const CreateBarChartByType = (certGroups) => {
  const data = [0, 0, 0, 0, 0]; // Initialize data array for bar chart

  for (let serial in certGroups) {
    const cert = certGroups[serial];
    const daysLeft = cert.days;

    if (daysLeft < 0) {
      // Expired certificates
      data[0] += 1;
    } else if (daysLeft <= 30) {
      // Certificates expiring in less than or equal to 30 days
      data[1] += 1;
    } else if (daysLeft <= 60) {
      // Certificates expiring in less than or equal to 60 days
      data[2] += 1;
    } else if (daysLeft <= 90) {
      // Certificates expiring in less than or equal to 90 days
      data[3] += 1;
    } else {
      // Certificates expiring in more than 90 days
      data[4] += 1;
    }
  }
  return data;
};

export const CreateBarChartData = (barChartType) => ({
  labels: [""],
  datasets: [
    {
      label: "Expired",
      data: [barChartType[0]],
      backgroundColor: ["#C61003"],
      borderWidth: 1,
      maxBarThickness: 70,
      borderRadius: {
        topLeft: 5,
        topRight: 5,
      },
    },
    {
      label: "<30 days",
      data: [barChartType[1]],
      backgroundColor: ["#FF392B"],
      borderWidth: 1,
      maxBarThickness: 70,
      borderRadius: {
        topLeft: 5,
        topRight: 5,
      },
    },
    {
      label: "<60 days",
      data: [barChartType[2]],
      backgroundColor: ["#FF6155"],
      borderWidth: 1,
      maxBarThickness: 70,
      borderRadius: {
        topLeft: 5,
        topRight: 5,
      },
    },
    {
      label: "<90 days",
      data: [barChartType[3]],
      backgroundColor: ["#FF8981"],
      borderWidth: 1,
      borderRadius: {
        topLeft: 5,
        topRight: 5,
      },
    },
    {
      label: ">90 days",
      data: [barChartType[4]],
      backgroundColor: ["#3DDC97"],
      borderWidth: 1,
      borderRadius: {
        topLeft: 5,
        topRight: 5,
      },
    },
  ],
});

export const barChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        color: "white",
        fillStyle: "none",
        usePointStyle: true,
        pointStyle: "rectRounded",
        boxSize: 0,
        boxWidth: 0,
        font: {
          family: "Kodchasan",
        },
      },
    },
    tooltip: {
      backgroundColor: "white",
      titleColor: "black",
      bodyColor: "black",
      padding: 20,
      yPadding: 90,
      callbacks: {
        title: () => "",
        label: (context) => {
          const label = context.dataset.label || "";
          const value = context.raw;
          return `${label}: ${value}`;
        },
      },
      yAlign: "bottom",
      xAlign: "center",
      titleFont: {
        family: "Kodchasan",
      },
      bodyFont: {
        family: "Kodchasan",
      },
    },
    datalabels: {
      color: "white",
      anchor: "end",
      align: "top",
      formatter: (value) => value,
      font: {
        family: "Kodchasan",
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        color: "white",
        precision: 0,
        font: {
          family: "Kodchasan",
        },
      },
      border: { dash: [4, 4] },

      grid: {
        color: "rgba(255, 255, 255, 0.1)",
        borderDash: [10, 10],
      },
    },
    x: {
      ticks: {
        color: "white",
        font: {
          family: "Kodchasan",
        },
      },
      display: true,
      grid: {
        color: "transparent",
        drawOnChartArea: true,
        borderDash: [10, 10],
        borderDashOffset: (context) =>
          context.index === context.chart.data.labels.length ? 0 : 10,
      },
      border: {
        color: "#959595",
        width: 1,
      },
    },
  },
  layout: {
    padding: {
      top: 30,
      bottom: 10, // Adjust padding to your liking
    },
  },
  onHover: (event, elements) => {
    event.native.target.style.cursor = elements.length ? "pointer" : "default";
  },
};

// Horizontal Bar Chart Data
export const CreateHorizontalBarChartData = (horizontalBarChartType) => ({
  labels: [
    "Assets With Unsecured Protocols",
    "Assets With Weak Ciphers",
    "Secured Certificates",
  ],
  datasets: [
    {
      label: [
        "Assets With Unsecured Protocols",
        "Assets With Weak Ciphers",
        "Secured Certificates",
      ],
      data: Object.values(horizontalBarChartType),
      backgroundColor: ["#FF6155", "#FF6155", "#3DDC97"],
      hoverOffset: 4,
      borderColor: "transparent",
      borderWidth: 0,
      width: 50,
      borderAlign: "center",
    },
  ],
});

export const horizontalBarChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  plugins: {
    legend: {
      display: false,
      position: "bottom",
      labels: {
        color: "white",
      },
      font: {
        family: "Kodchasan",
      },
    },
    tooltip: {
      backgroundColor: "white",
      titleColor: "black",
      bodyColor: "black",
      padding: 20,
      yPadding: 90,
      callbacks: {
        title: () => "",
        label: (context) => {
          const labelIndex = context.dataIndex;
          const labels = context.dataset.label;
          const value = context.raw;
          return `${labels[labelIndex]}: ${value}`;
        },
      },
      titleFont: {
        family: "Kodchasan",
      },
      bodyFont: {
        family: "Kodchasan",
      },
      yAlign: "bottom",
      xAlign: "center",
    },
    title: {
      display: false,
      text: "Most Used Ports",
      color: "white",
      font: {
        size: 24,
      },
    },
    datalabels: {
      anchor: "end",
      align: "end",
      formatter: Math.round,
      font: {
        weight: "bold",
        family: "Kodchasan",
        size: 12,
      },
      color: "white",
      padding: {
        right: 0,
      },
      clip: false,
      cursor: "pointer",
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        color: "white",
        font: {
          family: "Kodchasan",
          weight: 400,
          size: 10,
        },
      },
      border: { dash: [4, 4] }, // for the grid lines

      grid: {
        color: "rgba(255, 255, 255, 0)",
      },
    },
    x: {
      ticks: {
        precision: 0,
        color: "white",
      },
      border: { dash: [4, 4] }, // for the grid lines

      grid: {
        color: "rgba(255, 255, 255, 0.1)",
      },
      beginAtZero: true,
    },
  },
  layout: {
    padding: {
      right: 20,
    },
  },
  onHover: (event, elements) => {
    event.native.target.style.cursor = elements.length ? "pointer" : "default";
  },
};

export const baseColumns = [
  {
    Header: "Serial Number",
    // accessor: "serial",
    isSortable: true,
    key: "serial",
    accessor: (row) => (
      <div className="d-flex align-items-center">
        <OverlayTrigger
          placement="top"
          overlay={
            <Popover
              id={`tooltip-${row.serial}`}
              className="custom-popover-arrow"
            >
              <Popover.Body className="comming-tool-body">
                <label className="comming-text-tool">{row.serial}</label>
              </Popover.Body>
            </Popover>
          }
        >
          <span>
            {row.serial?.length > 10
              ? `${row.serial.slice(0, 10)}...`
              : row.serial}
          </span>
        </OverlayTrigger>
        <CopyIcon
          className=" ml-2 copy-icon"
          onClick={() => {
            navigator.clipboard.writeText(row.serial);

            showToast("Copied!", "success", "top-center", "light", {
              autoClose: 3000,
            });
          }}
          style={{ cursor: "pointer", marginLeft: "5px" }}
        />
      </div>
    ),
  },
  {
    Header: "Common Name",
    isSortable: true,
    key: "common_name",
    accessor: (row) => (
      <div>
        {row.common_name ? (
          <a
            className=" fs-6"
            href={`https://${row.common_name}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {row.common_name}
          </a>
        ) : (
          "-"
        )}
      </div>
    ),
  },
  {
    Header: "Expired",
    // accessor: "is_expired",
    isSortable: true,
    key: "is_expired",
    accessor: (row) => <ExpiredColumn data={row} id={row.id} />,
  },
  { Header: "Issuer", accessor: "issuer", isSortable: true },
  {
    Header: "Valid From",
    isSortable: true,
    key: "valid_from",
    accessor: (row) => (
      <div>{row.valid_from ? formatDate(row.valid_from) : "-"}</div>
    ),
  },
  {
    Header: "Expired on",
    isSortable: true,
    key: "valid_to",
    accessor: (row) => (
      <div>{row.valid_to ? formatDate(row.valid_to) : "-"}</div>
    ),
  },
  {
    Header: "Days left",
    key: "days",
    isSortable: true,
    accessor: (row) => (
      <CertificateNumberReduction data={row} isNumbers={true} />
    ),
  },
  {
    Header: "Supported Ciphers",
    key: "ciphers",
    isSortable: false,
    accessor: (row) => (
      <CertificateNumberReduction data={row} isUnsecuredCiphers={true} />
    ),
  },
  {
    Header: "Supported Protocol Versions",
    key: "protocols",
    isSortable: false,
    accessor: (row) => (
      <CertificateNumberReduction data={row} isSecureProtocolsLength={true} />
    ),
  },
  {
    Header: "First Detected",
    key: "first_detected",
    isSortable: true,
    accessor: (row) => {
      return (
        <div>{row?.first_detected ? formatDate(row?.first_detected) : ""}</div>
      );
    },
  },
  {
    Header: "Current State",
    key: "current_state",
    isSortable: true,
    accessor: (row) => {
      return (
        <div>
          {row?.change_status != null ? (
            <CurrentStatus
              status={row?.change_status}
              tooltipInfo={row?.changes}
              headerKeys={{
                ip: "IP Address",
                host: "Hostname",
                service: "Service",
                serial: "Serial Number",
                common_name: "Common Name",
                is_expired: "Expired",
                issuer: "Issuer",
                valid_from: "Valid From",
                valid_to: "Expired on",
                days: "Days left",
                ciphers: "Supported Ciphers",
                protocols: "Supported Protocol Versions",
                first_detected: "First Detected",
                change_status: "Current State",
              }}
            />
          ) : (
            ""
          )}
        </div>
      );
    },
  },
];

export const webSpecificColumns = [
  {
    Header: "Hostname",
    isSortable: true,
    key: "host",
    accessor: (row) => (
      <div>
        {(row.host && row.host.length > 0) || (row.ip && row.ip.length > 0) ? (
          <HostnamesList
            hostname={row.host}
            ip={{ ip: row.ip, port: row.scan_ports?.port }}
          />
        ) : (
          "-"
        )}
      </div>
    ),
  },
];

export const networkSpecificColumns = (openModal) => [
  {
    Header: "IP Address",
    accessor: (row) => {
      return (
        <span onClick={() => openModal(row?.ip)} className="asset-link">
          {row?.ip}
        </span>
      );
    },
    isSortable: true,
  },
  {
    Header: "Service",
    isSortable: true,
    accessor: (row) => (
      <div>{row?.scan_ports ? <div>{row.scan_ports.service}</div> : "-"}</div>
    ),
  },
];
