import React, { useState, useEffect, useMemo } from "react";
import { Button, Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import GenericCard from "../../components/card/GenericCard";
import { useParams, useNavigate } from "react-router-dom";
import { ReactComponent as RightArrowImage } from "../../assets/images/chevron-right.svg";
import { ReactComponent as CancelIcon } from "../../assets/images/cancel-white.svg";
import { ReactComponent as CalendarIcon } from "../../assets/images/calendar.svg";
import { ReactComponent as CriticalIcon } from "../../assets/images/critical.svg";
import { ReactComponent as ProviderIcon } from "../../assets/images/provider.svg";
import { ReactComponent as BlueIconMedium } from "../../assets/images/blue-signal.svg";
import { ReactComponent as BlueIconLow } from "../../assets/images/blue-signal-low.svg";
import { ReactComponent as BlueIconHigh } from "../../assets/images/blue-signal-high.svg";
import { ReactComponent as RedIcon } from "../../assets/images/red-signal.svg";
import { ReactComponent as YellowIcon } from "../../assets/images/yellow-signal.svg";
import { ReactComponent as GreenIcon } from "../../assets/images/green-signal.svg";
import { ReactComponent as ZoneIcon } from "../../assets/images/zone.svg";
import { ReactComponent as CategoryIcon } from "../../assets/images/category.svg";
import { ReactComponent as HostNameIcon } from "../../assets/images/host-name.svg";
import { ReactComponent as LocationIcon } from "../../assets/images/location.svg";
import { ReactComponent as CdnIcon } from "../../assets/images/cdn.svg";
import { ReactComponent as GlobalIcon } from "../../assets/images/global.svg";
import { ReactComponent as CmsIcon } from "../../assets/images/cms.svg";
import { ReactComponent as LoginIcon } from "../../assets/images/login-icon.svg";
import { ReactComponent as SquareIcon } from "../../assets/images/square-up.svg";
import { ReactComponent as Loader } from "../../assets/images/loader.svg";
import { ReactComponent as Spinner } from "../../assets/images/processing-spinner.svg";
import { UseAssetsModal } from "../../hooks/useAssetsModal";
import { ipRegex, ipWithPortRegex } from "../../util/constants";
import axios from "../../util/axios";
import { transformKeys } from "../../util/core";
import { UseServices } from "../../hooks/useServices";
import moment from "moment";

const AssetsDetailModal = ({ showAssetModal }) => {
  const navigate = useNavigate();
  const { allServices } = UseServices();
  const routeParams = useParams();
  const [asset, setAsset] = useState(null);
  const { closeModal, modalState } = UseAssetsModal();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [details, setDetails] = useState(null);
  const [cardData, setCardData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const maxVisibleBadges = 15;

  const serviceStatusMap = useMemo(() => {
    if (!allServices) return {};
    return allServices.reduce((acc, service) => {
      acc[service.id] = service.status;
      return acc;
    }, {});
  }, [allServices]);

  const handleSecurityNavigation = (score) => {
    closeModal();
    navigate(
      `/security-issues/${routeParams?.target_id || routeParams?.id}?${
        ipRegex.test(asset) ? "ip" : "host"
      }=${asset}&risk_score=${score}`
    );
  };

  const fetchAssets = async (assetIp) => {
    setIsLoading(true);
    try {
      let endpoint = ipRegex.test(assetIp)
        ? `assets/${
            routeParams?.target_id || routeParams?.id
          }/asset/ip/${assetIp}`
        : `assets/${
            routeParams?.target_id || routeParams?.id
          }/asset/host/${assetIp}`;
      const { data } = await axios.get(endpoint);
      const assetData = transformKeys(data);
      setDetails(assetData);
      let isIp = false;
      if (ipRegex.test(asset)) {
        isIp = true;
      }
      const ipData = [
        {
          id: 1,
          icon: <CalendarIcon className="mb-2" />,
          title: "First Discovered",
          desc: assetData?.first_discovered
            ? moment(assetData?.first_discovered).format("DD/MM/YY")
            : "-",
        },
        {
          id: 2,
          icon: <CriticalIcon className="mb-2" />,
          title: "Asset Criticality",
          desc:
            assetData?.asset_criticality == 1
              ? "Low"
              : assetData?.asset_criticality == 2
              ? "Medium"
              : assetData?.asset_criticality == 3
              ? "High"
              : "-",
          subIconLeft:
            assetData?.asset_criticality == 1 ? (
              <BlueIconLow width={14} />
            ) : assetData?.asset_criticality == 2 ? (
              <BlueIconMedium width={14} />
            ) : assetData?.asset_criticality == 3 ? (
              <BlueIconHigh width={14} />
            ) : (
              false
            ),
        },

        {
          id: 3,
          icon: <ProviderIcon className="mb-2" />,
          title: "Hosting provider",
          desc: assetData?.hosting_provider,
          service_id: 7,
        },
        {
          id: 4,
          icon: <ZoneIcon className="mb-2" />,
          title: "Network Zone",
          desc:
            assetData?.network_zone === 1
              ? "On-Premise"
              : assetData?.network_zone === 2
              ? "Cloud-Hosted"
              : null,
          service_id: 3,
        },
        {
          id: 5,
          icon: <CategoryIcon className="mb-2" />,
          title: "Service Category",
          scan_categories: assetData?.service_category,
          tag: true,
          multiCategories: true,
          service_id: 3,
        },
      ];
      if (!isIp) {
        ipData.splice(2, 0, {
          id: 7,
          icon: <CdnIcon className="mb-2" />,
          title: "CDN",
          desc: assetData?.cdn,
          service_id: 9,
        });
        ipData.unshift({
          id: 6,
          icon: <LocationIcon className="mb-2" />,
          title: "IP",
          desc: assetData?.ip,
          ip: true,
          onClick: () => handleChangeAsset(assetData?.ip),
        });
        ipData.splice(
          7,
          0,
          {
            id: 8,
            icon: <CmsIcon className="mb-2" />,
            title: "CMS",
            desc: assetData?.cms,
            service_id: 9,
          },
          {
            id: 9,
            icon: <GlobalIcon className="mb-2" />,
            title: "Web Interface",
            desc: assetData?.web_interface,
            subIconRight: assetData?.web_interface ? (
              <SquareIcon width={14} />
            ) : (
              false
            ),
            ip: true,
            onClick: () => {
              if (assetData?.web_interface) {
                const url =
                  assetIp.startsWith("http://") ||
                  assetIp.startsWith("https://")
                    ? assetIp
                    : `https://${assetIp}`;
                window.open(url, "_blank");
              }
            },
          },
          {
            id: 10,
            icon: <LoginIcon className="mb-2" />,
            title: "Login-page",
            desc:
              assetData?.login_page?.length > 0 ? assetData?.login_page : null,
            isTooltip: assetData?.login_page?.length > 0 ? true : false,
            service_id: 21,
          }
        );
      }
      setCardData(ipData);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleChangeAsset = (host) => {
    setAsset(host);
    if (breadcrumbs.includes(host)) {
      const updatedBreadcrumbs = [];
      for (const el of breadcrumbs) {
        updatedBreadcrumbs.push(el);
        if (el === host) {
          break;
        }
      }
      setBreadcrumbs(updatedBreadcrumbs);
    } else {
      setBreadcrumbs([...breadcrumbs, host]);
    }
  };

  useEffect(() => {
    if (asset) {
      fetchAssets(asset);
    }
  }, [asset]);

  useEffect(() => {
    if (modalState?.value) {
      const formattedAsset = ipWithPortRegex.test(modalState.value)
        ? modalState.value.split(":")[0]
        : modalState.value;
      handleChangeAsset(formattedAsset);
    }
  }, [modalState?.value]);

  const remainingBadges = details?.hostnames?.length - maxVisibleBadges;
  const visibleBadges = details?.hostnames?.slice(0, maxVisibleBadges);
  const hiddenBadges = details?.hostnames?.slice(maxVisibleBadges);

  const allServicesProcessed = !allServices?.every(
    (service) =>
      service.status === 3 || service.status === -1 || service.status === null
  );

  return (
    <Modal
      show={showAssetModal}
      onHide={closeModal}
      centered
      className="assets-detail-modal"
    >
      <Modal.Body
        style={{
          backgroundColor: "#252525",
          color: "#fff",
          border: "1px solid #93C2FF",
          borderRadius: "4px",
          padding: "20px 30px",
        }}
      >
        <div className="asset-body-footer">
          <div className="asset-modal-container">
            <div className="asset-header-section">
              <div className="modal-breadcrum-container">
                {breadcrumbs?.map((el, index) => {
                  return (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="host-name"
                        onClick={() => handleChangeAsset(el)}
                      >
                        {ipRegex.test(el) ? "IP" : "Hostname"}:
                        <span className="host-link">{el}</span>
                      </div>
                      {index != breadcrumbs?.length - 1 && (
                        <div>
                          <RightArrowImage className="arrow-right" />
                        </div>
                      )}
                    </span>
                  );
                })}
              </div>
              <div className="cancel-section" onClick={closeModal}>
                <CancelIcon />
              </div>
            </div>
            {isLoading ? (
              <div className="asset-load-container">
                <Loader className="asset-load-icon" height={20} width={20} />
              </div>
            ) : (
              <div>
                {details?.hostnames?.length > 0 && (
                  <div className="host-tag-icon-section">
                    <div>
                      <HostNameIcon />
                    </div>
                    <div className="tag-host-header">Hostname:</div>
                    <div className="assets-tags-section">
                      {visibleBadges?.map((el) => (
                        <div
                          className="assets-host-tags"
                          onClick={() => handleChangeAsset(el)}
                        >
                          {el}
                        </div>
                      ))}
                      {remainingBadges > 0 && (
                        <OverlayTrigger
                          placement="bottom"
                          trigger={"click"}
                          overlay={
                            <Tooltip className="light-theme-filter-tooltip">
                              <ul className="badge-container-chip asset-tooltip-scroll">
                                {hiddenBadges.map((el) => (
                                  <li key={el}>
                                    <div
                                      className="assets-host-tags"
                                      onClick={() => handleChangeAsset(el)}
                                      style={{
                                        background: "transparent",
                                        color: "black",
                                      }}
                                    >
                                      {el}
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </Tooltip>
                          }
                        >
                          <div className="assets-host-tags">
                            +{remainingBadges}
                          </div>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                )}
                <div className="mb-4">
                  <div className="assets-card-row">
                    {cardData?.map((el) => {
                      const isProcessing =
                        serviceStatusMap[el?.service_id] === 0 ||
                        serviceStatusMap[el?.service_id] === 1 ||
                        serviceStatusMap[el?.service_id] === 2;
                      return (
                        <div className="assets-card-col data-leaks">
                          <GenericCard
                            children={
                              <div
                                className="flex-col gap-1 flex align-items-center"
                                key={el?.id}
                              >
                                {el?.icon}
                                <div
                                  className={`asset-card-title ${
                                    el.multiCategories || el.isTooltip
                                      ? "sub-categories-title-scroll"
                                      : ""
                                  }`}
                                >
                                  {el?.title}
                                </div>
                                <div
                                  className={`asset-subicon-desc ${
                                    el.multiCategories || el.isTooltip
                                      ? "sub-categories-scroll"
                                      : ""
                                  }`}
                                  style={{
                                    width:
                                      el.multiCategories || el.isTooltip
                                        ? "100%"
                                        : "auto",
                                  }}
                                >
                                  {el?.subIconLeft && (
                                    <div
                                      style={{
                                        position: "relative",
                                        top: -2,
                                      }}
                                    >
                                      {el?.subIconLeft}
                                    </div>
                                  )}
                                  {el?.isTooltip && el?.desc ? (
                                    <div>
                                      {el.desc?.map((ol) => {
                                        return (
                                          <span
                                            onClick={() => {
                                              if (ol?.login_pages?.url) {
                                                const url =
                                                  ol?.login_pages?.url.startsWith(
                                                    "http://"
                                                  ) ||
                                                  ol?.login_pages?.url.startsWith(
                                                    "https://"
                                                  )
                                                    ? ol?.login_pages?.url
                                                    : `https://${ol?.login_pages?.url}`;
                                                window.open(url, "_blank");
                                              }
                                            }}
                                          >
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip
                                                  className="light-theme-tooltip"
                                                  id={ol?.login_pages?.url}
                                                >
                                                  {ol?.login_pages?.url || "-"}
                                                </Tooltip>
                                              }
                                            >
                                              <div
                                                style={{
                                                  marginTop:
                                                    el.desc?.length == 1
                                                      ? "-5px"
                                                      : "5px",
                                                }}
                                                className="assets-card-tags"
                                                key={ol?.login_pages?.url}
                                              >
                                                {ol?.login_pages?.url}
                                                <SquareIcon
                                                  width={14}
                                                  style={{
                                                    marginLeft: 5,
                                                  }}
                                                />
                                              </div>
                                            </OverlayTrigger>
                                          </span>
                                        );
                                      })}
                                    </div>
                                  ) : el.multiCategories ? (
                                    el.scan_categories?.length > 0 ? (
                                      el.scan_categories?.map((ol) => (
                                        <div
                                          className="assets-card-tags"
                                          index={ol?.id}
                                        >
                                          {ol?.service_category?.title}
                                        </div>
                                      ))
                                    ) : isProcessing ? (
                                      <div
                                        className="processing-spinner"
                                        style={{
                                          position: "relative",
                                          top: "-6px",
                                        }}
                                      >
                                        <Spinner />
                                        Processing Data...
                                      </div>
                                    ) : (
                                      <span
                                        style={{
                                          fontWeight: 700,
                                          fontSize: "16px",
                                          bottom: "7px",
                                          position: "relative",
                                        }}
                                      >
                                        -
                                      </span>
                                    )
                                  ) : (
                                    <div
                                      className={` ${
                                        el?.tag
                                          ? "assets-card-tags"
                                          : el?.ip
                                          ? "ip-desc"
                                          : "asset-card-desc"
                                      }`}
                                      onClick={() => {
                                        if (el?.onClick) {
                                          el?.onClick();
                                        }
                                      }}
                                    >
                                      {el?.desc ? (
                                        el?.desc
                                      ) : isProcessing ? (
                                        <div className="processing-spinner">
                                          <Spinner />
                                          Processing Data...
                                        </div>
                                      ) : (
                                        "-"
                                      )}
                                    </div>
                                  )}

                                  {el?.subIconRight && (
                                    <div>{el?.subIconRight}</div>
                                  )}
                                </div>
                              </div>
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div>
                  <div className="security-header">Security issues:</div>

                  {allServicesProcessed && (
                    <div
                      className="processing-spinner"
                      style={{
                        margin: "35px 0px 20px",
                      }}
                    >
                      <Spinner />
                      Processing Data...
                    </div>
                  )}
                  <div className="signals-risk-section">
                    <div className="risk-icon-section">
                      <div>
                        <RedIcon width={17} />
                      </div>
                      <div
                        className="risk-asset"
                        onClick={() => handleSecurityNavigation("80-100")}
                      >
                        High Risk | {details?.securityissues?.high || 0}
                      </div>
                    </div>
                    <div className="risk-icon-section">
                      <div>
                        <YellowIcon width={17} />
                      </div>
                      <div
                        className="risk-asset"
                        onClick={() => handleSecurityNavigation("40-79")}
                      >
                        Medium Risk | {details?.securityissues?.medium || 0}{" "}
                      </div>
                    </div>{" "}
                    <div className="risk-icon-section">
                      <div>
                        <GreenIcon width={17} />
                      </div>
                      <div
                        className="risk-asset"
                        onClick={() => handleSecurityNavigation("0-39")}
                      >
                        Low Risk | {details?.securityissues?.low || 0}{" "}
                      </div>
                    </div>
                  </div>
                  {details?.harvestsources?.length > 0 && (
                    <div>
                      <div className="not-found">
                        Not only Armory Found this asset, this asset can also be
                        found in:
                      </div>
                      <div className="assets-tags-section">
                        {details?.harvestsources?.map((el) => (
                          <div className="assets-tags no-hover-asset">{el}</div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="btn-section" onClick={closeModal}>
            <Button className="close-btn">Close</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AssetsDetailModal;
