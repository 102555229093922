import React, { useState } from "react";
import NoUpdate from "../empty/NoUpdate";
import "./WebUpdate.scss";
import { OverlayTrigger } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import { ReactComponent as InfoIcon } from "../../assets/images/circle-info.svg";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import NoData from "../../components/empty/NoData.js";

const WafUpdate = ({ data, onSort, dataInProcess }) => {
  const navigate = useNavigate();
  const { target_id } = useParams();
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
  return data &&
    (data.changed > 0 ||
      data.new > 0 ||
      data.constant > 0 ||
      data.removed > 0) ? (
    <div className="updates-grid">
      <div className="updates-item">
        <div className="">New</div>
        <div
          className={`updates-value  ${
            data?.new ? "underline cursor-pointer" : ""
          }`}
        >
          <OverlayTrigger
            placement="top"
            delay={{ show: 2000 }}
            overlay={
              <Popover id={`tooltip-New`} className="custom-popover-arrow">
                <Popover.Body className="comming-tool-body">
                  <label className="comming-text-tool">Filter Results</label>
                </Popover.Body>
              </Popover>
            }
          >
            <div onClick={() => data?.new > 0 && onSort("New")}>
              {data?.new || 0}
            </div>
          </OverlayTrigger>
        </div>
      </div>
      <div className="updates-item">
        <div className="">Changed</div>
        <div
          className={`updates-value  ${
            data?.changed ? "underline cursor-pointer" : ""
          }`}
        >
          <OverlayTrigger
            placement="top"
            delay={{ show: 2000 }}
            overlay={
              <Popover id={`tooltip-Changed`} className="custom-popover-arrow">
                <Popover.Body className="comming-tool-body">
                  <label className="comming-text-tool">Filter Results</label>
                </Popover.Body>
              </Popover>
            }
          >
            <div onClick={() => data?.changed > 0 && onSort("Changed")}>
              {data?.changed || 0}
            </div>
          </OverlayTrigger>
        </div>
      </div>
      <div
        className="updates-item"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="flex align-items-center">
          <span>Removed</span>
          {data?.removed !== 0 && (
            <OverlayTrigger
              show={showTooltip}
              placement="top"
              overlay={
                <Popover
                  id={`tooltip-Removed`}
                  className="custom-popover-arrow"
                >
                  <Popover.Body
                    className="comming-tool-body"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <label className="comming-text-tool">
                      To view the removed assets in assets discovery{" "}
                      <span
                        className="text-link cursor-pointer"
                        onClick={() =>
                          navigate(`/assets-inventory/${target_id}?tab=1`)
                        }
                      >
                        click here
                      </span>
                    </label>
                  </Popover.Body>
                </Popover>
              }
            >
              <div className="cursor-pointer icon-style">
                <InfoIcon />
              </div>
            </OverlayTrigger>
          )}
        </div>
        <div className="updates-value">
          <div>{data?.removed || 0}</div>
        </div>
      </div>

      <div className="updates-item">
        <div className="">Unchanged</div>
        <div
          className={`updates-value  ${
            data?.constant ? "underline cursor-pointer" : ""
          }`}
        >
          <OverlayTrigger
            placement="top"
            delay={{ show: 2000 }}
            overlay={
              <Popover
                id={`tooltip-Unchanged`}
                className="custom-popover-arrow"
              >
                <Popover.Body className="comming-tool-body">
                  <label className="comming-text-tool">Filter Results</label>
                </Popover.Body>
              </Popover>
            }
          >
            <div onClick={() => data?.constant > 0 && onSort("Unchanged")}>
              {data?.constant || 0}
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  ) : (
    <div className="update-card">
      <div className="d-flex flex-column justify-content-center py-1 px-1 gap-2 h-100">
        {dataInProcess ? <NoData /> : <NoUpdate />}
      </div>
    </div>
  );
};

export default WafUpdate;
