import React from "react";
import { ReactComponent as Export } from "../../assets/images/Export.svg";
import { formatDate } from "../../util/formatDate.js";
import axios from "../../util/axios";

const CyberServicesCard = ({ data }) => {
  const { title, submit_date, doc } = data;

  const downloadFile = async () => {
    try {
      const response = await axios.get(
        `/cyber-services/download/${data.id}?is_seen=true`,
        {
          responseType: "blob",
        }
      );

      if (response.data instanceof Blob) {
        const blob = response.data;
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = doc || "downloaded-file";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      } else {
        console.error("Unexpected response data type, expected Blob.");
      }
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <React.Fragment>
      <div className="cyberservicesCard_Main">
        <div className="cyberservicesCard_submain">
          <div className="cyberservice_container">
            <div className="cyberservice_title">{title}</div>
            <div className="row">
              <div className="col-6 cyber_container_col">
                <div className="mt-3">
                  <div className="cyberservice_header_title">Date</div>
                  <div className="cyberservice_header_text fw-bold">
                    {" "}
                    {submit_date ? formatDate(submit_date) : ""}
                  </div>
                </div>
              </div>
              <div className="col-6 cyber_container_col">
                <div className="mt-3">
                  <div className="cyberservice_header_title">Document</div>
                  <div className="cyberservice_header_text fw-bold  d-flex">
                    <span className="table-export cursor-pointer fs-6 ">
                      {doc ? (
                        <p
                          onClick={downloadFile}
                          className="table-export cursor-pointer fs-6 d-flex align-items-center"
                        >
                          <Export
                            className="export-icon"
                            height={25}
                            width={25}
                            style={{
                              marginLeft: -5,
                            }}
                          />
                          Download
                        </p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CyberServicesCard;
