import moment from "moment";

export const formatDate = (date, format = "MMM DD, YYYY") => {
  return date ? moment(date).format(format) : "";
};

export const localInputFormatDate = (dateStr) => {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};
