import React, { useState, useEffect } from "react";
import BaseFilter from "../../../../components/filter/BaseFilter";
import { ReactComponent as AddIcon } from "../../../../assets/images/add.svg";
import BaseTable from "../../../../components/table/BaseTable";
import { ReactComponent as DotIcon } from "../../../../assets/images/threedots.svg";
import { OverlayTrigger, Popover } from "react-bootstrap";
import AddReportsModal from "../reports/AddReportsModal";
import { showToast } from "../../../../util/toasts";
import DeleteModal from "../../../../components/modal/DeleteModal";
import axios from "../../../../util/axios";
import { parseFilterString, applyCondition } from "../../../../util/conditions";
import { adminReportsTabsConfig } from "../../../../util/tabsConfig.js";
import { formatDate } from "../../../../util/formatDate.js";

const Reports = () => {
  const [popoverId, setPopoverId] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [activeFilters, setActiveFilters] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [editData, setEditData] = useState(null);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const updateTabsWithData = (reports) => {
    const organizations = [
      ...new Set(
        reports
          .filter((user) => user?.organization_name)
          .map((user) => user.organization_name)
      ),
    ].map((org_name) => ({
      id: org_name,
      name: org_name,
      type: "Organization: " + org_name,
      key: "1",
      active: false,
    }));

    const reportNames = [
      ...new Set(
        reports
          .filter((user) => user?.report_name)
          .map((user) => user.report_name)
      ),
    ].map((report_name) => ({
      id: report_name,
      name: report_name,
      type: "Report Name: " + report_name,
      key: "2",
      active: false,
    }));

    const seenNames = ["Yes", "No"].map((seen) => ({
      id: seen,
      name: seen,
      type: "Seen: " + seen,
      key: "3",
      active: false,
    }));

    setTabs(
      adminReportsTabsConfig({
        organizations,
        reportNames,
        seenNames,
      })
    );
  };

  const fetchReports = async () => {
    try {
      const orgResponse = await axios.get("organizations", {
        params: { page: 1, pageSize: 1000 },
      });

      const reportsResponse = await axios.get("get-reports");
      if (reportsResponse.data?.items?.length > 0) {
        const modifiedData = reportsResponse.data?.items?.map((el) => ({
          ...el,
          organization_name: orgResponse.data?.items?.filter(
            (ol) => ol?.id == el.organization_id
          )?.[0]?.org_name,
          report_name: el?.title,
          file_name: el?.doc,
          date_uploaded: el?.submit_date,
          seen: el?.is_seen,
        }));
        setData(modifiedData);
        updateTabsWithData(modifiedData);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const handleShowAddModal = () => {
    setEditData(null);
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => setShowAddModal(false);

  const handleShowPopover = (id) => {
    setPopoverId((prevId) => (prevId === id ? null : id));
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setShowDeleteModal(true);
    setPopoverId(null);
  };

  const handleConfirmDelete = async () => {
    try {
      const { data } = await axios.delete(`delete-report/${deleteId}`);
      fetchReports();
      setShowDeleteModal(false);
      showToast("Update Deleted Successfully", "success");
    } catch (error) {
      setShowDeleteModal(false);
      console.error("Error:", error);
    }
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteId(null);
  };

  const handleClickOutside = () => {
    if (popoverId) {
      setPopoverId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [popoverId]);

  const actionPopover = (row) => (
    <Popover id={`popover-${row?.id}`}>
      <Popover.Body className="admin-popover">
        <div
          className="popover-item"
          onClick={() => {
            handleShowAddModal();
            setEditData(row);
            setPopoverId(null);
          }}
        >
          Edit
        </div>
        <div className="popover-item" onClick={() => handleDelete(row?.id)}>
          Delete
        </div>
      </Popover.Body>
    </Popover>
  );

  const handlePreviewFile = async (row) => {
    try {
      const response = await axios.get(
        `/cyber-services/download/${row.id}?is_seen=true`,
        {
          responseType: "blob",
        }
      );

      if (response.data instanceof Blob) {
        const blob = response.data;
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = row?.doc || "downloaded-file";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      } else {
        console.error("Unexpected response data type, expected Blob.");
      }
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const handleContinueSubmit = async (values, resetForm, setIsLoading) => {
    const endpoint = editData?.id
      ? `edit-report/${editData?.id}`
      : "upload-report";
    const method = editData?.id ? axios.put : axios.post;
    const formData = new FormData();
    formData.append("report_name", values?.report_name);
    formData.append("selected_date", values?.selected_date);
    formData.append("organization_id", values?.organization_id);
    formData.append("doc", values?.file);
    try {
      const { data } = await method(endpoint, formData);
      fetchReports();
      resetForm();
      setIsLoading(false);
      handleCloseAddModal();
      showToast("Changes Saved Successfully", "success");
      setEditData(null);
    } catch (error) {
      handleCloseAddModal();
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  const columns = [
    { Header: "Organization", accessor: "organization_name", isSortable: true },
    { Header: "Report Name", accessor: "report_name", isSortable: true },
    {
      Header: "File",
      key: "file_name",
      accessor: (row) => {
        return (
          <span className="asset-link" onClick={() => handlePreviewFile(row)}>
            {row?.file_name}
          </span>
        );
      },
      isSortable: true,
    },
    {
      Header: "Date Uploaded",
      key: "date_uploaded",
      accessor: (row) => {
        return <span>{formatDate(row?.date_uploaded)}</span>;
      },
      isSortable: true,
    },
    {
      Header: "Seen",
      key: "seen",
      accessor: (row) => {
        return <span>{row?.seen ? "Yes" : "No"}</span>;
      },
      isSortable: true,
    },
    {
      Header: "",
      accessor: (row) => {
        if (!row || !row.id) return null;
        return (
          <OverlayTrigger
            trigger="click"
            placement="left"
            overlay={actionPopover(row)}
            show={popoverId === row.id}
            rootClose
          >
            <div
              className="text-end"
              onClick={(event) => {
                event.stopPropagation();
                handleShowPopover(row.id);
              }}
            >
              <DotIcon className="cursor-pointer " />
            </div>
          </OverlayTrigger>
        );
      },
    },
  ];

  useEffect(() => {
    fetchReports();
  }, []);

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const filteredReports = data.filter((report) => {
    const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
      return groupedFilters[eventKey].some((filter) => {
        const lowerCaseFilter = filter.name?.toLowerCase();
        if (eventKey === "1") {
          return (
            report.organization_name &&
            report.organization_name?.toLowerCase() === lowerCaseFilter
          );
        } else if (eventKey === "2") {
          return (
            report.report_name &&
            report.report_name?.toLowerCase() === lowerCaseFilter
          );
        } else if (eventKey === "3") {
          return (
            (lowerCaseFilter === "no" && !report.seen) ||
            (lowerCaseFilter === "yes" && report.seen)
          );
        } else if (eventKey === "advanced-filter") {
          const parsedFilters = parseFilterString(filter.name);
          return parsedFilters.every((ol) => {
            const { column, condition, value } = ol;
            switch (column) {
              case "org_name":
                return applyCondition(
                  report.organization_name?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "report_name":
                return applyCondition(
                  report.report_name?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "seen":
                return (
                  (value?.toLowerCase() === "no" && !report.seen) ||
                  (value?.toLowerCase() === "yes" && report.seen)
                );
              default:
                return false;
            }
          });
        }
        return false;
      });
    });

    const matchesSearch =
      searchValue === "" ||
      (report?.organization_name &&
        report?.organization_name
          .toLowerCase()
          .includes(searchValue.toLowerCase())) ||
      (report?.report_name &&
        report?.report_name.toLowerCase().includes(searchValue.toLowerCase()));

    return matchesFilters && matchesSearch;
  });

  return (
    <>
      <div className="base-filter-container overflow-hidden">
        <div className="left-filter">
          <BaseFilter
            tabs={tabs}
            className="mb-3"
            totalRecords={filteredReports.length}
            onFilterChange={handleFilterChange}
            activeFilters={activeFilters}
            removeFilter={removeFilter}
            showExport={false}
            searchValue={searchValue}
            onSearchChange={handleSearchChange}
            isSearch={true}
            tableData={filteredReports}
          />
        </div>
        <div
          className="add-button d-flex align-items-center justify-content-end mb-2 "
          onClick={handleShowAddModal}
        >
          <span className="mb-1">
            <AddIcon />
          </span>
          <span className="add-organization ms-2">Add Report</span>
        </div>
      </div>
      <div>
        <BaseTable
          className="mt-3 mb-3"
          columns={columns}
          data={filteredReports}
          selectable={false}
          showCheckboxes={false}
          action={false}
          isStickyScroll
        />
        {showAddModal && (
          <AddReportsModal
            show={showAddModal}
            handleClose={handleCloseAddModal}
            editData={editData}
            onSubmit={handleContinueSubmit}
          />
        )}
        <DeleteModal
          showModal={showDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleConfirmDelete={handleConfirmDelete}
          title={"Are you sure you want to delete the update?"}
          subTitle={"Deleting the report is irreversible"}
        />
      </div>
    </>
  );
};

export default Reports;
