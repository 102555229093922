import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const SecurityIssueAssetHost = ({ source }) => {
  const hostRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (hostRef.current) {
      requestAnimationFrame(() => {
        if (hostRef.current.scrollWidth > hostRef.current.clientWidth) {
          setShowTooltip(true);
        } else {
          setShowTooltip(false);
        }
      });
    }
  }, [source.host]); // Re-run when `source.host` changes

  const truncatedText = (
    <span
      className="text-ellipsis"
      ref={hostRef}
      style={{
        minWidth: "auto",
        maxWidth: "12vw",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {source.host}
    </span>
  );

  return (
    <div className="d-flex align-items-center text-unwrap tesss">
      {showTooltip ? (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-host`}>{source.host}</Tooltip>}
          delay={{ show: 250, hide: 400 }}
        >
          {truncatedText}
        </OverlayTrigger>
      ) : (
        truncatedText
      )}
    </div>
  );
};

export default SecurityIssueAssetHost;
