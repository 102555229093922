import React, { useState, useMemo } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import BaseFilter from "../../components/filter/BaseFilter";
import armorytestedIconSmall from "../../assets/images/armorytestedIconSmall.png";
import { ReactComponent as Bulb } from "../../assets/images/bulb.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/chevron-green-down.svg";
import { ReactComponent as Close } from "../../assets/images/xmark.svg";
import CveArrow from "../../assets/images/border-cve.png";
import ScannerRedirection from "./ScannerRedirection";
import GenericDataDisplay from "./GenericDataDisplay";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as LinkIcon } from "../../assets/images/external-link-icon.svg";
import { decodeUsingDOMParser } from "../../util/core";
import armoryWarZoneIcon from "../../assets/images/warTag.png";


const CvesPopup = ({
  show,
  header,
  handleClose,
  details,
  isGroup,
  asset,
  criticality,
  riskScore,
  ...props
}) => {
  const [explanationOpen, setExplanationOpen] = useState(true);
  const [relatedAssetsOpen, setRelatedAssetsOpen] = useState(true);
  const [exposeDataInfo, setExposeDataInfo] = useState(false);
  const [remediationOpen, setRemediationOpen] = useState(true);
  const [riskCalculationOpen, setRiskCalculationOpen] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();

  const toggleExplanation = () => {
    setExplanationOpen(!explanationOpen);
  };
  const toggleRelatedAssets = () => {
    setRelatedAssetsOpen(!relatedAssetsOpen);
  };
  const toggleRelatedExposeDataInfo = () => {
    setExposeDataInfo(!exposeDataInfo);
  };
  const toggleRiskCalculation = () => {
    setRiskCalculationOpen(!riskCalculationOpen);
  };
  const toggleRemediation = () => {
    setRemediationOpen(!remediationOpen);
  };
  let color = "";
  if (riskScore >= 80 && riskScore <= 100) {
    color = "#ff6155";
  } else if (riskScore >= 40 && riskScore <= 79) {
    color = "#f3a738";
  } else if (riskScore >= 0 && riskScore <= 39) {
    color = "#3ddc97";
  }
  const renderRelatedAssets = (el) => {
    let assetInfo = [];
    el?.ip && assetInfo.push(el.ip);
    el?.host && assetInfo.push(el.host);
    el?.domain && el.service_id !== 3 && assetInfo.push(el.domain);
    el?.port && assetInfo.push(`Port ${el.port}`);
    return assetInfo.length > 0 ? assetInfo.join(" | ") : "-";
  };
  const levelLabels = {
    1: "Low",
    2: "Medium",
    3: "High",
  };
  const levelLabel = levelLabels[criticality] || "Unknown";

  const exportRows = useMemo(() => {
    return (
      details &&
      [details].map((el) => [
        el.issue_name,
        isGroup && el.assets?.length > 0
          ? el?.assets
              ?.map((asset) =>
                asset?.host
                  ? asset?.host
                  : asset?.domain
                  ? asset?.domain
                  : asset.ip && asset.port
                  ? `${asset.ip} Port: ${asset.port}`
                  : asset.ip
                  ? asset.ip
                  : "-"
              )
              ?.join(" | ")
          : el?.host
          ? el?.host
          : el?.domain
          ? el?.domain
          : el.ip && el.port
          ? `${el.ip} Port: ${el.port}`
          : el.ip
          ? el.ip
          : "-",
        el.scan_category,
        el.impact + "%",
        el?.exploitability + "%",
        el?.cvss,
        el?.epss,
        el.risk_score + "%",
        el.summary ? el.summary.replace(/,/g, "") : "",
        `${el.explanation.replace(/,/g, "") || ""}`,
        el.remediation
          ? el.remediation
              .replace(
                "Please see the references for more resources supporting you with this task.",
                ""
              )
              .replace("Please see the references for more information.", "")
              .replace(/^\s+|\s+$/g, "")
              .split(" | ")
              .filter((i) => i)
              .join(" | ")
          : "No Remediation found.",
      ])
    );
  }, [details, isGroup]);

  let hasGenericData = false;
  let genericDataLabel = "";
  let issuesFor = "";
  let certificatesSerial = [];
  let leaksGenericdata = null;
  let exposeGenericdata = null;
  let emailGenericdata = null;
  let loginPagesSecurityGenericdata = null;
  let spfGenericData = null;
  let dmarcGenericData = null;

  if (details?.service_id === 19) {
    const assets = isGroup ? details?.assets : [details];
    const assetWithGenericData = assets?.find((el) => el?.generic_data1);
    if (assetWithGenericData) {
      hasGenericData = true;
      issuesFor = "dataLeaks";
      leaksGenericdata = assetWithGenericData.generic_data1;
    }
  } else {
    if (!isGroup) {
      hasGenericData = details?.generic_data1 != null;

      // Handle certificates when ungroup items
      if (details?.generic_data1?.certificates) {
        certificatesSerial = certificatesSerial.concat(
          details.generic_data1.certificates
        );
      }
    } else {
      hasGenericData = details?.assets?.some(
        (el) =>
          el?.generic_data1 &&
          (el?.generic_data1?.length > 0 ||
            el?.generic_data1?.pages?.length > 0 ||
            el?.generic_data1?.certificates?.length > 0 ||
            el?.generic_data1?.type === "spf" ||
            el?.generic_data1?.type === "dmarc")
      );

      // Handle certificates when group items
      details?.assets.forEach((entry) => {
        if (entry.id === asset.id && entry.generic_data1?.certificates) {
          certificatesSerial = certificatesSerial.concat(
            entry.generic_data1.certificates
          );
        }
      });
    }
    if (hasGenericData) {
      if (details?.service_id == 16 || details?.service_id == 17) {
        issuesFor = "crediantals";
        genericDataLabel = "Found Credentials";
      } else if (details?.service_id == 15) {
        issuesFor = "login";
      } else if (details?.service_id == 5) {
        issuesFor = "certificates";
        genericDataLabel = "Certificate serial number";
      } else if (details?.service_id === 21) {
        issuesFor = "loginPagesSecurity";
        const assetsSource = isGroup ? details.assets : [details];
        loginPagesSecurityGenericdata =
          assetsSource.find((el) => el?.generic_data1)?.generic_data1 || null;
      } else if (details?.service_id === 22) {
        issuesFor = "exposeDocs";
        const assetsSource = isGroup ? details.assets : [details];
        exposeGenericdata =
          assetsSource.find((el) => el?.generic_data1)?.generic_data1 || null;
      } else if (details?.service_id === 6) {
        const assetsSource = isGroup ? details.assets : [details];
        emailGenericdata =
          assetsSource.find((el) => el?.generic_data1)?.generic_data1 || null;
        const type = emailGenericdata?.type;
        if (type === "spf") {
          issuesFor = "SPF";
          spfGenericData = emailGenericdata.result;
        } else if (type === "dmarc") {
          issuesFor = "DMARC";
          dmarcGenericData = emailGenericdata.result;
        }
      }
    }
  }

  const certificatesDisplay = useMemo(() => {
    return (
      <div className="summary">
        <p className="text-white mb-3 generic-text-link">
          {[...new Set(certificatesSerial)].map((el, index) => (
            <div key={index}>
              {el}
              {index !== [...new Set(certificatesSerial)].length - 1 && <hr />}
            </div>
          ))}
        </p>
      </div>
    );
  }, [certificatesSerial]);
  return (
    <>
      <Offcanvas
        style={{ width: "40%" }}
        show={show}
        onHide={handleClose}
        placement="end"
        {...props}
      >
        <Offcanvas.Header>
          <div className="Cves-popup-header ricks-options justify-content-between align-items-center w-100 position-relative">
            <div className="d-flex flex-column justify-content-between align-items-start popup-left">
              <div className="d-flex justify-content-between align-items-center securityissue-section_3">
                <h3
                  style={{
                    marginTop: details?.scan_category ? "-10px" : "-20px",
                  }}
                >
                  {details?.issue_name}
                </h3>
                {details?.armory_tested && !isGroup ? (
                  <div className="section_1_part1_subpart d-flex align-items-center justify-content-center popup-armory-tested-tag" style={{    minWidth: "131px"}}>
                    <img className="h-100" src={armorytestedIconSmall} />
                    <div className="securityissueCard_subtitle">
                      ARMORY TESTED
                    </div>
                  </div>
                ) : (
                  ""
                )}
                 {details?.is_war_room && !isGroup ? (
                  <div className="section_1_part1_subpart d-flex align-items-center justify-content-center popup-armory-tested-tag" style={{    minWidth: "131px"}}>
                    <img className="h-100" src={armoryWarZoneIcon} style={{width:'13px'}}/>
                    <div className="securityissueCard_subtitlewar">
                    WAR ROOM
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {details?.scan_category && (
                <div className="d-flex align-items-center">
                  <div className="sub-title mt-2">{details?.scan_category}</div>
                  <ScannerRedirection
                    scannerId={details?.service_id}
                    category={details?.scan_category}
                  />
                </div>
              )}
            </div>
            <div className="ricks-options justify-content-between align-items-center popup-right res-mt-2">
              <div className="ricks-options flex-column justify-content-between align-items-center">
                <div className="risk-score-popup-container">
                  <div className="cvss-svore">
                    <h5>Risk Score </h5>
                  </div>
                  <Close onClick={handleClose} className="close-icon "  />
                  <div className="percentage" style={{ color: color }}>
                  {riskScore}
                </div>
                </div>
               
              </div>
            </div>
          </div>
        </Offcanvas.Header>
        <hr className="popup-hr-main" />
        <Offcanvas.Body className="scrollable-issue-side-popup">
          <div className="Cves-popup-main">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{
                marginTop: "-10px",
                marginBottom: "8px",
                height: "53px",
              }}
            >
              <div>
                <h3 className="text-white m-0">Summary</h3>
              </div>
              <div style={{ marginRight: "-27px" }}>
                <BaseFilter
                  showFilter={false}
                  exportHeader={[
                    "Security Issue",
                    "Relevant Assets",
                    "Category",
                    "Impact",
                    "Exploitability",
                    "CVSS",
                    "EPSS",
                    "Risk Score",
                    "Summary",
                    "Explanation",
                    "Remediation",
                  ]}
                  exportTitle={"Issues Findings"}
                  exportRows={exportRows}
                />
              </div>
            </div>

            <div className="summary">
              <p>
                {details?.summary ? decodeUsingDOMParser(details?.summary) : ""}
              </p>
            </div>
            {/* Explanation */}
            <div className="popup-dropdown" onClick={toggleExplanation}>
              <ArrowDown
                height={16}
                width={16}
                className={`base-drop-down-arrow ${
                  explanationOpen ? "open" : ""
                }`}
              />
              <h3 className="text-white">Explanation</h3>
            </div>
            {explanationOpen && (
              <>
                <div className="summary mb-3">
                  <div className="summary mb-3">
                    <p
                      className="text-white mt-3"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {details?.explanation
                        ? decodeUsingDOMParser(details?.explanation)
                        : "N/A"}
                    </p>
                  </div>
                  {details?.cve && (
                    <div className="summary ">
                      {details?.cve?.split(" | ")?.map((cve) => {
                        return <p className="text-white mb-3">{cve}</p>;
                      })}
                    </div>
                  )}
                  {hasGenericData && (
                    <h4 className="text-white">{genericDataLabel}</h4>
                  )}
                  {hasGenericData &&
                    issuesFor == "crediantals" &&
                    details?.assets?.map((el) => {
                      if (el?.generic_data1 && el?.generic_data1?.length > 0) {
                        return (
                          <div className="summary">
                            <p className="text-white mb-3">
                              <div>
                                <b>
                                  {el?.ip} | {el?.port}
                                </b>
                              </div>
                              <hr />

                              {el?.generic_data1?.map((genericData) => (
                                <div>
                                  <GenericDataDisplay
                                    login={genericData?.login}
                                    password={genericData?.password}
                                  />
                                </div>
                              ))}
                            </p>
                          </div>
                        );
                      }
                    })}

                  {hasGenericData &&
                    issuesFor == "certificates" &&
                    certificatesDisplay}
                </div>
                {hasGenericData && issuesFor === "dataLeaks" && (
                  <div className="summary mb-3">
                    <div className="summary mb-3">
                      <p
                        className="text-white mt-3 d-flex align-items-center fw-normal cursor-pointer"
                        style={{ whiteSpace: "pre-line" }}
                        onClick={() => navigate(`/scanner/${id}/data-leaks`)}
                      >
                        <span className="underline"> Data Leaks:</span>
                        <div className="issues-assets-tag ms-2 data-leaks-cve">
                          {leaksGenericdata?.Emails || 0} Emails
                        </div>
                        <div className="issues-assets-tag mx-3 data-leaks-cve">
                          {leaksGenericdata?.Passwords || 0} Passwords
                        </div>{" "}
                        <div className="issues-assets-tag data-leaks-cve">
                          {leaksGenericdata?.Hashes || 0} Hashes
                        </div>
                      </p>
                      <div></div>
                    </div>
                  </div>
                )}
                {issuesFor === "SPF" && spfGenericData && (
                  <div className="summary mb-3">
                    <div className="summary mb-3">
                      <p className="text-white mt-3 d-flex align-items-center fw-normal cursor-pointer">
                        <span> SPF Record:</span>
                        {spfGenericData.map((record, index) => (
                          <div
                            key={index}
                            className="issues-assets-tag ms-2 data-leaks-cve"
                          >
                            {record}
                          </div>
                        ))}
                      </p>
                    </div>
                  </div>
                )}
                {issuesFor === "DMARC" && dmarcGenericData && (
                  <div className="summary mb-3">
                    <div className="summary mb-3">
                      <p className="text-white mt-3 d-flex align-items-center fw-normal cursor-pointer">
                        <span>DMARC Record:</span>
                        {dmarcGenericData.map((record, index) => (
                          <div
                            key={index}
                            className="issues-assets-tag ms-2 data-leaks-cve"
                          >
                            {record}
                          </div>
                        ))}
                      </p>
                    </div>
                  </div>
                )}
              </>
            )}

            {/* Related Assets | 8 */}

            <div className="popup-dropdown " onClick={toggleRelatedAssets}>
              <ArrowDown
                height={16}
                width={16}
                className={`base-drop-down-arrow ${
                  relatedAssetsOpen ? "open" : ""
                }`}
              />
              <h3 className="text-white">Related Asset</h3>
            </div>
            {relatedAssetsOpen &&
              (issuesFor == "login" ? (
                <div className="summary">
                  <p className="text-white mb-3 generic-text-link">
                    {details?.assets?.map((el, index) => {
                      if (el?.generic_data1?.pages?.length > 0) {
                        return (
                          <div>
                            <a
                              href={el?.generic_data1?.pages[0]}
                              target="_blank"
                            >
                              {el?.generic_data1?.pages[0]}
                            </a>
                            {index !== details?.assets?.length - 1 && <hr />}
                          </div>
                        );
                      }
                    })}
                  </p>
                </div>
              ) : (
                <div className="ip-port">
                  {details ? (
                    <div>
                      <div className="  list-group-item d-flex justify-content-between align-items-center">
                        <div className="flex-fill text-white text">
                          {renderRelatedAssets(isGroup ? asset : details)}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="text-white text-center mt-4">-</div>
                  )}
                </div>
              ))}
            {relatedAssetsOpen && (
              <>
                {issuesFor === "loginPagesSecurity" && (
                  <div className="summary mb-3 mt-3">
                    <p
                      className="text-white mb-3 expose-genericdata d-flex align-items-center underline"
                      title={loginPagesSecurityGenericdata}
                      style={{ margin: 0 }}
                    >
                      <div
                        className="link-assets cursor-pointer"
                        onClick={() => {
                          const url = loginPagesSecurityGenericdata;
                          if (url) {
                            window.open(url, "_blank");
                          }
                        }}
                      >
                        <span
                          className="text-content"
                          title={exposeGenericdata}
                          style={{
                            display: "inline-block",
                            maxWidth: "96%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {loginPagesSecurityGenericdata}
                        </span>
                        <LinkIcon
                          className="copy-icon"
                          style={{ cursor: "pointer", marginRight: "5px" }}
                        />
                      </div>
                    </p>
                  </div>
                )}
                {issuesFor === "exposeDocs" && (
                  <div className="summary mt-3">
                    <p
                      className="text-white expose-genericdata align-items-center"
                      style={{ margin: 0 }}
                    >
                      <div className="d-flex">
                        <span
                          className="link-assets cursor-pointer d-flex underline"
                          onClick={() => {
                            exposeGenericdata.url &&
                              window.open(exposeGenericdata.url, "_blank");
                          }}
                        >
                          <span
                            className="text-content"
                            style={{
                              display: "inline-block",
                              maxWidth: "96%",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {exposeGenericdata.url}
                          </span>
                          <LinkIcon
                            className="copy-icon"
                            style={{ cursor: "pointer", marginRight: "5px" }}
                          />
                        </span>
                      </div>
                      <div
                        className="popup-dropdown mt-3 mb-1"
                        onClick={toggleRelatedExposeDataInfo}
                      >
                        <h3>View Details</h3>
                        <ArrowDown
                          height={16}
                          width={16}
                          className={`base-drop-down-arrow ${
                            exposeDataInfo ? "open" : ""
                          }`}
                        />
                      </div>
                      {exposeDataInfo && (
                        <div>
                          <div className="white mt-4 mb-3">
                            <h3>
                              <b>Title</b>
                            </h3>
                            <div
                              className="mt-3"
                              style={{
                                whiteSpace: "pre-line",
                                color: "#BCF4DE",
                              }}
                            >
                              {decodeUsingDOMParser(exposeGenericdata.title)}
                            </div>
                          </div>
                          <div className="white mt-4 mb-3">
                            <h3>
                              {" "}
                              <b>Description</b>
                            </h3>
                            <div
                              className="text-white mt-3"
                              style={{ whiteSpace: "pre-line" }}
                            >
                              {decodeUsingDOMParser(
                                exposeGenericdata.description
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </p>
                  </div>
                )}
              </>
            )}

            {/* Risk Calculation */}
            <div className="popup-dropdown " onClick={toggleRiskCalculation}>
              <ArrowDown
                height={16}
                width={16}
                className={`base-drop-down-arrow ${
                  riskCalculationOpen ? "open" : ""
                }`}
              />
              <h3 className="text-white">Risk Calculation</h3>
            </div>
            {riskCalculationOpen && (
              <div className="d-flex align-items-center risk ">
                <div
                  className="d-flex flex-column gap-3 risk-first-div"
                  style={{ backgroundImage: `url(${CveArrow})` }}
                >
                  <div className="gap-3 ricks-options">
                    <div className="d-flex align-items-center gap-3">
                      <div>Impact:</div>
                      <div>{details?.impact}</div>
                    </div>
                    <div className="d-flex align-items-center gap-3 res-mt-2">
                      <div>Exploitability:</div>
                      <div>{details?.exploitability}</div>
                    </div>
                    <div className="d-flex align-items-center gap-3 res-mt-2">
                      <div>Criticality:</div>
                      <div>{levelLabel} Criticality</div>
                    </div>
                  </div>
                  {details?.cve && (
                    <div className=" cve-popup-container  align-items-center gap-3 ">
                      <div className="cvs-epss-popup-section ">
                      <div className="d-flex  align-items-center gap-3">
                        <div>CVSS: </div>
                        <div>{details?.cvss}</div>
                      </div>
                      <div className="d-flex align-items-center gap-3 epss-popup-container">
                        <div>EPSS: </div>
                        <div>{details?.epss}</div>
                      </div>
                      </div>
                     
                      <div className="d-flex align-items-center gap-3 cisa-kev-popup-container">
                        <div>CISA KEV: </div>
                        <div
                          className="popup-cisa"
                          style={{
                            border:
                              details?.cisa_kev === "yes"
                                ? "1px solid #FF6155"
                                : details?.cisa_kev === "no"
                                ? "1px solid #fff"
                                : "none",
                            color:
                              details?.cisa_kev === "yes"
                                ? "#FF6155"
                                : details?.cisa_kev === "no"
                                ? "#fff"
                                : "#fff",
                          }}
                        >
                          {details?.cisa_kev.charAt(0).toUpperCase() +
                            details?.cisa_kev.slice(1)}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="d-flex align-items-center flex-col flex-wrap justify-content-between risk-second-div score gap-2">
                  <p> Risk Score </p>{" "}
                  <span className="percentage" style={{ color: color }}>
                    {details?.risk_score}
                  </span>
                </div>
              </div>
            )}

            {/* Remediation */}
            <div className="popup-dropdown " onClick={toggleRemediation}>
              <ArrowDown
                height={16}
                width={16}
                className={`base-drop-down-arrow ${
                  remediationOpen ? "open" : ""
                }`}
              />
              <h3 className="text-white">Remediation</h3>
            </div>

            {remediationOpen && (
              <div style={{ whiteSpace: "pre-line" }}>
                {details?.remediation ? (
                  details?.remediation
                    ?.replace(
                      "Please see the references for more resources supporting you with this task.",
                      ""
                    )
                    .replace(
                      "Please see the references for more information.",
                      ""
                    )
                    .replace(/^\s+|\s+$/g, "")
                    .split("|")
                    ?.filter(function (i) {
                      return i;
                    })
                    .map((item, index) => (
                      <div key={index} className="summary mb-3">
                        <p
                          className="text-white mt-3"
                          style={{
                            position: "relative",
                          }}
                        >
                          <Bulb
                            style={{
                              position: "absolute",
                              width: "30px",
                              marginTop: "1px",
                              height: "20px",
                            }}
                          />
                          <div
                            style={{
                              marginLeft: 39,
                            }}
                          >
                            {decodeUsingDOMParser(item)}
                          </div>
                        </p>
                      </div>
                    ))
                ) : (
                  <div className="text-white text-center mt-4">
                    No Remediation found.
                  </div>
                )}
              </div>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
export default CvesPopup;
