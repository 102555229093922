export const CLASS_PREFIX = "dlpd";
export const OUTBOUND_CALLER_ID = 923084425031;
export const GMAP_KEY = "AIzaSyB24CJfVxtwwNaGJgcT2NM7_8f4azr9RuA";
export const urlRegex =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.[^\s]{2,}|[a-zA-Z0-9]+\.[^\s]{2,})$/;
export const ipRegex =
  /^((?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])[.]){3}(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/;
export const ipWithPortRegex =
  /^((25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?):([0-9]{1,5})$/;

export const isAdmin = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.member_level == 9;
};

export const isNormalUser = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.member_level == 0;
};

export const isDistributor = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.member_level == 1;
};

export const isEmpty = (obj) => Object.keys(obj).length === 0;
