import React from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as CheckIcon } from "../../../assets/images/check-circle.svg";

const InvitationModal = ({
  onClose,
  show,
  title = "The invitation was sent successfully",
}) => {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Body
        className="d-flex align-items-center justify-content-center"
        style={{
          borderRadius: "4px",
          backgroundColor: "#252525",
          color: "#fff",
          height: "234px",
          width: "593px",
          border: "1px solid #93C2FF",
        }}
      >
        <div className="text-center">
          <CheckIcon height={40} width={100} />
          <h2 className="mt-3 ">{title}</h2>
          <p className="mt-3">
            You can track the status on the invitation table.
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default InvitationModal;
