import React, { useEffect, useState, cloneElement, Children } from "react";
import Header from "../header/Header";
import { Col, Container, Row } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import TopBarHeader from "../navbar/TopNavbar";
import Sidebar from "../navbar/Sidebar";
import { useLocation } from "react-router-dom";
import { breadcrumbItems } from "../../util/breadcrumb";
import Breadcrumb from "../../components/breadcrumbs/BreadCrumb";
import SplashScreenWrapper from "../../components/SplashScreenWrapper";
import { ReactComponent as ChevronsRight } from "../../assets/images/chevrons-right.svg";
import AssetsDetailModal from "../../components/assets-detail-modal/AssetsDetailModal.js";
import { UseServices } from "../../hooks/useServices";
import { UseAssetsModal } from "../../hooks/useAssetsModal.js";

function ProtectedLayout(props) {
  const { allServices: scannerServices } = UseServices();
  const { modalState } = UseAssetsModal();
  const location = useLocation();
  const [showScanning, setShowScanning] = useState(true);
  const [isMobile, setIsMobile] = useState(
    typeof window !== "undefined" && window.innerWidth < 1024
  );
  const [open, setOpen] = useState(!isMobile);
  const [targetId, setTargetId] = useState(null);

  const getBreadcrumbDetails = () => {
    const pathname = location?.pathname;
    if (pathname?.includes("/scanner")) {
      let key = pathname?.split("/")?.[3];
      return breadcrumbItems[key];
    } else {
      let key = pathname?.split("/")?.[1];
      return breadcrumbItems[key];
    }
  };

  useEffect(() => {
    const pathname = location?.pathname;
    if (pathname?.includes("offense")) {
      setShowScanning(false);
    } else {
      setShowScanning(true);
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (isMobile && open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isMobile, open]);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 1024);
      if (!isMobile) setOpen(true);
    }

    if (typeof window !== "undefined") {
      handleResize();
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const showBreadcrumb =
    location?.pathname?.includes("/scanner") ||
    location?.pathname?.includes("/offense") ||
    location?.pathname?.includes("/cyber-services");

  const scannerRoute = location?.pathname?.includes("/scanner");
  let scanningStatus = null;
  let scanningUpdatedAt = null;
  if (scannerRoute) {
    let key = location?.pathname?.split("/")?.[3];
    const filteredScanner = scannerServices?.filter((el) =>
      el.key?.includes(`/${key}`)
    )?.[0];
    scanningStatus = filteredScanner?.status;
    scanningUpdatedAt = filteredScanner?.updated_at;
  }

  const childrenWithProps = Children.map(props.children, (child) => {
    return cloneElement(child, { scanningStatus });
  });

  return (
    <React.Fragment>
      <Header
        open={open}
        onToggle={() => {
          if (isMobile) {
            setOpen(!open);
          }
        }}
      ></Header>
      <TopBarHeader targetId={targetId} />
      <Container fluid>
        <Row className="flex-nowrap">
          <Sidebar
            open={open}
            targetId={setTargetId}
            onToggle={() => {
              setOpen(!open);
            }}
          />
          {!open && (
            <div style={{ width: "50px" }}>
              <div className="hide-toggle" onClick={() => setOpen(!open)}>
                <ChevronsRight className="chevrons-right" />
                <span> Attack Surface</span>
              </div>
            </div>
          )}
          <Col className={`main-section p-0`}>
            <div>
              {showBreadcrumb && (
                <Breadcrumb
                  breadcrumbItems={getBreadcrumbDetails()}
                  showScanning={
                    location.pathname?.includes("/cyber-services")
                      ? false
                      : showScanning
                  }
                  endTime={scanningUpdatedAt || new Date()}
                  status={scanningStatus}
                  isLastUpdate={
                    location.pathname?.includes("/offense") ? false : true
                  }
                />
              )}
              {childrenWithProps}
            </div>
          </Col>
        </Row>
      </Container>
      {modalState.isOpen && (
        <AssetsDetailModal showAssetModal={modalState.isOpen} />
      )}
      <ToastContainer />
    </React.Fragment>
  );
}

export default SplashScreenWrapper(ProtectedLayout);
