import React, { useState } from "react";
import { ReactComponent as Arrow } from "../../assets/images/port-arrow.svg";
import BaseCheckbox from "../../components/form/BaseCheckbox";
import { ReactComponent as CheckIcon } from "../../assets/images/check-white.svg";
import { ReactComponent as CancelIcon } from "../../assets/images/cancel-white.svg";
import moment from "moment";
import { formatDate } from "../../util/formatDate";

const DomainScanTable = ({
  asset,
  setIsModalOpen,
  setDomainToScan,
  setIsRejectMode,
  setShowDomain,
  selectedSubDomains,
  setSelectedSubDomains,
  isUser
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const handleButtonFocus = () => {
    setIsButtonActive(true);
  };
  const handleButtonBlur = () => {
    setIsButtonActive(false);
  };

  const handleCheckboxChange = (domain) => {
    setSelectedSubDomains((prevSelected) => {
      const newSelected = prevSelected.includes(domain.subdomain || domain.ip)
        ? prevSelected.filter((d) => d !== (domain.subdomain || domain.ip))
        : [...prevSelected, domain.subdomain || domain.ip];
      return newSelected;
    });
  };

  const handleSelectAll = () => {
    const allSubDomains = asset.subdomains.map(
      (sub) => sub.subdomain || sub.ip
    );
    if (allSubDomains.every((sub) => selectedSubDomains.includes(sub))) {
      setSelectedSubDomains((prevSelected) =>
        prevSelected.filter((d) => !allSubDomains.includes(d))
      );
    } else {
      setSelectedSubDomains((prevSelected) => [
        ...prevSelected,
        ...allSubDomains.filter((sub) => !prevSelected.includes(sub)),
      ]);
    }
  };

  const handleOpenModalForScan = (event) => {
    event.stopPropagation();
    setDomainToScan(asset.root_domain);
    setIsModalOpen(true);
    setIsRejectMode(false);
    setShowDomain(true);
  };

  const handleOpenModalForDomain = (domain, event) => {
    event.stopPropagation();
    setDomainToScan(domain.subdomain || domain.ip);
    setIsModalOpen(true);
    setIsRejectMode(false);
    setShowDomain(false);
  };

  const handleOpenModalForReject = (domain, event, showDomain) => {
    event.stopPropagation();
    setDomainToScan(domain);
    setIsModalOpen(true);
    setIsRejectMode(true);
    setShowDomain(showDomain);
  };
  const isAllSubDomainsSelected = asset.subdomains.every((sub) =>
    selectedSubDomains.includes(sub.subdomain || sub.ip)
  );
  return (
    <div className="col-12 domain-scan-table p-0 mt-2">
      <div className="domain-asset-header" onClick={toggleCollapse}>
        <div className="domain-table-heading">
          {asset.subdomains?.length > 0 && (
            <Arrow
              className={
                isCollapsed ? "arrow-rotated-right" : "arrow-rotated-down"
              }
            />
          )}
          <span className="domain-name">{asset.root_domain || "Unknown"}</span>{" "}
          <span>|</span>{" "}
          <span className="domain-header-start">
            {asset.root_domain
              ? asset.is_blocked
                ? "The entire domain could not be scanned to reveal connected assets"
                : "Domain not yet scanned to reveal all connected assets"
              : "IP addresses that cannot be resolved to hostnames"}
          </span>
        </div>
        {asset.root_domain && (
          <div className="domain-header-end">
              
            {!asset.is_blocked && (
              <div 
              className={`${
                !isUser ? "disabled-user-view p-2 rounded-1" : "scan"
              } ${isButtonActive ? "active" : "non-active"}`}
              onFocus={handleButtonFocus}
              onBlur={handleButtonBlur}
              tabIndex="0"
              onClick={isUser && handleOpenModalForScan}
              >
                <CheckIcon height={20}  /> Scan Domain
              </div>
            )}
            <div 
              className={`${
                !isUser ? "disabled-user-view p-2 rounded-1" : "scan"
              } ${isButtonActive ? "active" : "non-active"}`}
              onFocus={handleButtonFocus}
              onBlur={handleButtonBlur}
              tabIndex="0"
              onClick={(event) =>
                isUser &&
                handleOpenModalForReject(asset.root_domain, event, true)
              }
            >
              <CancelIcon height={20}  /> Reject domain
            </div>
          </div>
        )}
      </div>
      {asset.subdomains?.length > 0 && (
        <>
          {!isCollapsed && (
            <div className="domain-asset-content row">
              <div className="port-collapse-table col-10">
                <table>
                  <thead>
                    <tr>
                      {isUser && (<th>

                        <BaseCheckbox
                          checked={isAllSubDomainsSelected}
                          onChange={handleSelectAll}
                          name="selectAll"
                        />


                      </th>
                      )}
                      <th>Hostname</th>
                      <th>IP Address</th>
                      <th>First Detected</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {asset.subdomains.map((domain) => (
                      <tr key={domain?.id}>
                        {isUser && (<td>

                          <BaseCheckbox
                            checked={selectedSubDomains.includes(
                              domain.subdomain || domain.ip
                            )}
                            onChange={() => handleCheckboxChange(domain)}
                            name={`select-${domain.subdomain || domain.ip}`}
                          />


                        </td>
                        )}
                        <td>{domain.subdomain || "-"}</td>
                        <td>{domain.ip || "-"}</td>
                        <td>
                          {domain.first_detected
                            ? formatDate(domain.first_detected)
                            : "-"}
                        </td>
                        <td>
                          <div className="action-icons">
                            <div
                              className={`${
                                !isUser ? "action-disable" : "action-check"
                              } ${isButtonActive ? "active" : "non-active"}`}
                              onFocus={handleButtonFocus}
                              onBlur={handleButtonBlur}
                              onClick={(event) =>
                                isUser && handleOpenModalForDomain(domain, event)
                              }
                              tabIndex={0}
                            >
                              <CheckIcon height={14} />
                            </div>
                         
                            <div
                           className={`${
                            isUser ? "action-cancel" : "action-disable"
                          } ${isButtonActive ? "active" : "non-active"}`}
                            onFocus={handleButtonFocus}
                            onBlur={handleButtonBlur}
                            tabIndex={0}
                              onClick={(event) =>
                                isUser &&
                                handleOpenModalForReject(
                                  domain.subdomain || domain.ip,
                                  event,
                                  false
                                )
                              }
                            >
                              <CancelIcon height={14} />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DomainScanTable;