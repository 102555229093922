import React, { useState } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import { Formik, Field, FieldArray } from "formik";
import { ReactComponent as CancelIcon } from "../../../../assets/images/Xmark-circle.svg";
import { ReactComponent as AddIcon } from "../../../../assets/images/add.svg";
import { ReactComponent as CrossIcon } from "../../../../assets/images/cancel.svg";
import * as Yup from "yup";
import { ThreeCircles } from "react-loader-spinner";
import InvitationModal from "../InvitationModal";
import axios from "../../../../util/axios";
import { showToast } from "../../../../util/toasts";

const validationSchema = Yup.object().shape({
  org_name: Yup.string()
    .trim("No leading or trailing spaces allowed")
    .strict()
    .required("Organization Name is required"),
  type: Yup.string().required("Type is required"),
  alert_limit: Yup.number()
    .required("IP Limit-License is required")
    .typeError("IP Limit-License must be a number"),
  ip_limit: Yup.number()
    .required("IP Limit-Block is required")
    .typeError("IP Limit-Block must be a number"),
  send_invitations: Yup.boolean(),
  users: Yup.lazy((_, options) => {
    const sendInvitations = options?.parent?.send_invitations;
    if (sendInvitations) {
      return Yup.array()
        .of(
          Yup.object().shape({
            email: Yup.string()
              .email("Invalid email")
              .required("Email is required"),
            role: Yup.string().required("Role is required"),
          })
        )
        .min(1, "At least one user is required");
    }
    return Yup.array();
  }),
});

const AddOrganizationModal = ({ show, handleClose, fetchOrganization }) => {
  const initialValues = {
    org_name: "",
    type: "",
    alert_limit: "",
    ip_limit: "",
    send_invitations: false,
    users: [{ email: "", role: "" }],
  };
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleFormSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const { data } = await axios.post("organizations", {
        ...values,
        users: values?.send_invitations ? values?.users : [],
      });
      showToast(`Changes Saved Successfully`, "success");
      fetchOrganization();
      resetForm();
      setIsLoading(false);
      handleClose();
      if (values?.send_invitations) {
        setShowSuccessModal(true);
      }
    } catch (error) {
      handleClose();
      setIsLoading(false);
      setShowErrorModal(true);
    }
  };

  const closeSuccessModal = () => setShowSuccessModal(false);
  const closeErrorModal = () => setShowErrorModal(false);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="custom-modal-width"
      >
        <Modal.Body
          style={{
            backgroundColor: "#252525",
            color: "#fff",
            border: "1px solid #93C2FF",
            borderRadius: "4px",
            padding: "34px",
          }}
        >
          <div className="admin-security-modal">
            <h3>Add New Organization</h3>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleFormSubmit}
              enableReinitialize={true}
            >
              {({
                values,
                handleSubmit,
                setFieldValue,
                errors,
                validateForm,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <Field name="org_name">
                        {({ field, meta, form }) => (
                          <FormGroup className="mt-3" controlId="org_name">
                            <label className="admin-input-label">
                              Organization Name
                            </label>
                            <FormControl
                              {...field}
                              type="text"
                              size="lg"
                              placeholder="Enter Organization Name"
                              className={`placeholder-custom admin-input-field ${
                                meta.touched && meta.error ? "is-invalid" : ""
                              }`}
                            />
                            {meta.touched && meta.error && (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            )}
                          </FormGroup>
                        )}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field as="select" name="type">
                        {({ field, meta }) => (
                          <FormGroup className="mt-3" controlId="type">
                            <label className="admin-input-label">Type</label>
                            <FormControl
                              {...field}
                              as="select"
                              size="lg"
                              value={field.value}
                              onChange={field.onChange}
                              className={
                                meta.touched && meta.error
                                  ? "is-invalid custom-select admin-input-field"
                                  : "custom-select admin-input-field"
                              }
                              style={{
                                color: field.value === "" ? "#6F6F6F" : "#fff",
                              }}
                            >
                              <option value={""}>Select Type</option>
                              {["Client", "Testing"]?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </FormControl>
                            {meta.touched && meta.error && (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            )}
                          </FormGroup>
                        )}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field name="alert_limit">
                        {({ field, meta }) => (
                          <FormGroup className="mt-3" controlId="alert_limit">
                            <label className="admin-input-label">
                              IP Limit-License
                            </label>
                            <FormControl
                              type="number"
                              size="lg"
                              placeholder="Enter Number"
                              className={`placeholder-custom admin-input-field ${
                                meta.touched && meta.error ? "is-invalid" : ""
                              }`}
                              {...field}
                            />
                            {meta.touched && meta.error && (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            )}
                          </FormGroup>
                        )}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field name="ip_limit">
                        {({ field, meta }) => (
                          <FormGroup className="mt-3" controlId="ip_limit">
                            <label className="admin-input-label">
                              IP Limit- Block
                            </label>
                            <FormControl
                              type="number"
                              size="lg"
                              placeholder="Enter Number"
                              className={`placeholder-custom admin-input-field ${
                                meta.touched && meta.error ? "is-invalid" : ""
                              }`}
                              {...field}
                            />
                            {meta.touched && meta.error && (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            )}
                          </FormGroup>
                        )}
                      </Field>
                    </Col>
                    <Col md={12} className="mt-3 mb-1">
                      <Form.Check
                        type="checkbox"
                        label="Send invitations to join the organization"
                        checked={values.send_invitations}
                        onChange={(e) =>
                          setFieldValue("send_invitations", e.target.checked)
                        }
                      />
                    </Col>
                    {values.send_invitations && (
                      <Col md={12}>
                        <FieldArray name="users">
                          {({ form, push, remove }) => (
                            <div className="mt-3">
                              {form.values.users.map((_, index) => (
                                <div
                                  key={`user-${index}`}
                                  style={{
                                    marginBottom: "10px",
                                  }}
                                >
                                  <Row
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <Col md={6}>
                                      <label className="admin-input-label">
                                        Email Address
                                      </label>
                                      <FormGroup>
                                        <Field name={`users[${index}].email`}>
                                          {({ field, meta }) => (
                                            <>
                                              <FormControl
                                                type="email"
                                                placeholder="Enter Email"
                                                className={`placeholder-custom admin-input-field ${
                                                  meta.touched && meta.error
                                                    ? "is-invalid"
                                                    : ""
                                                }`}
                                                {...field}
                                              />
                                              {meta.touched && meta.error && (
                                                <div className="invalid-feedback">
                                                  {meta.error}
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </Field>
                                      </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                      <label className="admin-input-label">
                                        Role
                                      </label>
                                      <FormGroup>
                                        <Field
                                          name={`users[${index}].role`}
                                          as="select"
                                        >
                                          {({ field, meta }) => (
                                            <>
                                              <FormControl
                                                {...field}
                                                as="select"
                                                className={
                                                  meta.touched && meta.error
                                                    ? "is-invalid custom-select admin-input-field"
                                                    : "custom-select admin-input-field"
                                                }
                                                style={{
                                                  color:
                                                    field.value === ""
                                                      ? "#6F6F6F"
                                                      : "#fff",
                                                }}
                                              >
                                                <option value="">
                                                  Select Role
                                                </option>
                                                <option value={9}>Admin</option>
                                                <option value={0}>User</option>
                                                <option value={1}>
                                                  Distributer
                                                </option>
                                              </FormControl>
                                              {meta.touched && meta.error && (
                                                <div className="invalid-feedback">
                                                  {meta.error}
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </Field>
                                      </FormGroup>
                                    </Col>{" "}
                                    {form.values.users.length > 1 && (
                                      <span
                                        style={{
                                          position: "absolute",
                                          right: "-20px",
                                          width: "30px",
                                          height: "30px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          padding: "0px",
                                          top: "41px",
                                        }}
                                      >
                                        <CrossIcon
                                          onClick={() => remove(index)}
                                          style={{ cursor: "pointer" }}
                                        />
                                      </span>
                                    )}
                                  </Row>
                                </div>
                              ))}
                              <div
                                className="another-user-container"
                                style={{ justifyContent: "flex-start" }}
                              >
                                <a
                                  href="#"
                                  className="another-user-link"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    push({ email: "", role: "" });
                                  }}
                                >
                                  <AddIcon /> Another User
                                </a>
                              </div>
                            </div>
                          )}
                        </FieldArray>
                      </Col>
                    )}
                  </Row>
                  <div
                    className="mt-3 d-flex align-items-end justify-content-end w-100 org-button"
                    style={{
                      borderTop: "1px solid #686868",
                      paddingTop: "24px",
                    }}
                  >
                    <Button
                      variant="secondary"
                      onClick={handleClose}
                      className="button-hover"
                      style={{
                        width: "90px",
                        height: "40px",
                        backgroundColor: "#252525",
                        color: "#007bff",
                        borderColor: "#007bff",
                        marginRight: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      className="button-hover"
                      style={{
                        width: "74px",
                        height: "40px",
                        backgroundColor: "#252525",
                        color: "#007bff",
                        borderColor: "#007bff",
                      }}
                    >
                      {isLoading ? (
                        <span
                          style={{
                            position: "relative",
                            left: "11px",
                          }}
                        >
                          <ThreeCircles
                            height={18}
                            width={18}
                            color="#007bff"
                          />
                        </span>
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <InvitationModal show={showSuccessModal} onClose={closeSuccessModal} />
      <Modal show={showErrorModal} onHide={closeErrorModal} centered>
        <Modal.Body
          className="d-flex align-items-center justify-content-center"
          style={{
            borderRadius: "4px",
            backgroundColor: "#252525",
            color: "#fff",
            height: "234px",
            width: "593px",
            border: "1px solid #93C2FF",
          }}
        >
          <div className="text-center">
            <CancelIcon height={40} width={100} />
            <h2 className="mt-3">Something went wrong</h2>
            <p className="mt-3">Error message</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddOrganizationModal;
