import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import { Formik, Field, FieldArray } from "formik";
import { ReactComponent as AddIcon } from "../../../assets/images/add.svg";
import { ReactComponent as CrossIcon } from "../../../assets/images/cancel.svg";
import * as Yup from "yup";
import { ThreeCircles } from "react-loader-spinner";
import InvitationModal from "./InvitationModal";
import axios from "../../../util/axios";
import { showToast } from "../../../util/toasts";

const validationSchema = Yup.object().shape({
  users: Yup.array()
    .of(
      Yup.object().shape({
        email: Yup.string()
          .email("Invalid email")
          .required("Email is required"),
        user_role: Yup.string().required("Role is required"),
        organization_id: Yup.string().required("Organization is required"),
      })
    )
    .min(1, "At least one user is required"),
});

const InviteNewUserModal = ({ show, handleClose, fetchData }) => {
  const initialFormState = {
    users: [{ email: "", user_role: "", organization_id: "" }],
  };
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(initialFormState);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [allOraganizations, setAllOrganizations] = useState([]);

  const fetchOrganization = async () => {
    try {
      const { data } = await axios.get("organizations", {
        params: { page: 1, pageSize: 1000 },
      });
      if (data?.items) {
        setAllOrganizations(data?.items);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const { data } = await axios.post("invite", values);
      if (fetchData) {
        fetchData();
      }
      resetForm();
      setIsLoading(false);
      handleClose();
      setShowSuccessModal(true);
    } catch (error) {
      const message = error?.response?.data?.[0]?.msg;
      if (message) {
        showToast(message, "error");
      } else {
        showToast("Something went wrong!", "error");
      }
      handleClose();
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  const closeSuccessModal = () => setShowSuccessModal(false);

  useEffect(() => {
    fetchOrganization();
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="custom-modal-width"
      >
        <Modal.Body
          style={{
            backgroundColor: "#252525",
            color: "#fff",
            border: "1px solid #93C2FF",
            borderRadius: "4px",
            padding: "34px",
          }}
        >
          <div className="admin-security-modal">
            <h3>Invite new user</h3>
            <p className="modal-text mt-4">
              While waiting for confirmation the user status will be available
              on the invitation screen
            </p>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleFormSubmit}
              enableReinitialize={true}
            >
              {({
                values,
                handleSubmit,
                setFieldValue,
                errors,
                validateForm,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={12}>
                      <FieldArray name="users">
                        {({ form, push, remove }) => (
                          <div className="mt-3">
                            {form.values.users.map((_, index) => (
                              <div
                                key={`user-${index}`}
                                style={{
                                  marginBottom: "10px",
                                }}
                              >
                                <Row
                                  style={{
                                    position: "relative",
                                  }}
                                >
                                  <Col md={4}>
                                    <label className="admin-input-label">
                                      Organization
                                    </label>
                                    <FormGroup>
                                      <Field
                                        name={`users[${index}].organization_id`}
                                        as="select"
                                      >
                                        {({ field, meta }) => (
                                          <>
                                            <FormControl
                                              {...field}
                                              as="select"
                                              className={
                                                meta.touched && meta.error
                                                  ? "is-invalid custom-select admin-input-field"
                                                  : "custom-select admin-input-field"
                                              }
                                              style={{
                                                color:
                                                  field.value === ""
                                                    ? "#6F6F6F"
                                                    : "#fff",
                                              }}
                                            >
                                              <option value="">
                                                Select Organization
                                              </option>
                                              {allOraganizations?.map((el) => (
                                                <option value={el.id}>
                                                  {el?.org_name}
                                                </option>
                                              ))}
                                            </FormControl>
                                            {meta.touched && meta.error && (
                                              <div className="invalid-feedback">
                                                {meta.error}
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </Field>
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <label className="admin-input-label">
                                      Email Address
                                    </label>
                                    <FormGroup>
                                      <Field name={`users[${index}].email`}>
                                        {({ field, meta }) => (
                                          <>
                                            <FormControl
                                              type="email"
                                              placeholder="Enter Email"
                                              className={`placeholder-custom admin-input-field ${
                                                meta.touched && meta.error
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              {...field}
                                            />
                                            {meta.touched && meta.error && (
                                              <div className="invalid-feedback">
                                                {meta.error}
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </Field>
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <label className="admin-input-label">
                                      Role
                                    </label>
                                    <FormGroup>
                                      <Field
                                        name={`users[${index}].user_role`}
                                        as="select"
                                      >
                                        {({ field, meta }) => (
                                          <>
                                            <FormControl
                                              {...field}
                                              as="select"
                                              className={
                                                meta.touched && meta.error
                                                  ? "is-invalid custom-select admin-input-field"
                                                  : "custom-select admin-input-field"
                                              }
                                              style={{
                                                color:
                                                  field.value === ""
                                                    ? "#6F6F6F"
                                                    : "#fff",
                                              }}
                                            >
                                              <option value="">
                                                Select Role
                                              </option>
                                              <option value={9}>Armory Admin</option>
                                              <option value={1}>
                                              Armory Distributer
                                              </option>
                                              <option value={2}>User-Editor</option>
                                              <option value={0}>User-Viewer</option>
                                            </FormControl>
                                            {meta.touched && meta.error && (
                                              <div className="invalid-feedback">
                                                {meta.error}
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </Field>
                                    </FormGroup>
                                  </Col>
                                  {form.values.users.length > 1 && (
                                    <span
                                      style={{
                                        position: "absolute",
                                        right: "-20px",
                                        width: "30px",
                                        height: "30px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "0px",
                                        top: "41px",
                                      }}
                                    >
                                      <CrossIcon
                                        onClick={() => remove(index)}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </span>
                                  )}
                                </Row>
                              </div>
                            ))}
                            <div
                              className="another-user-container"
                              style={{ justifyContent: "flex-start" }}
                            >
                              <a
                                href="#"
                                className="another-user-link"
                                onClick={(e) => {
                                  e.preventDefault();
                                  push({
                                    email: "",
                                    user_role: "",
                                    organization_id: "",
                                  });
                                }}
                              >
                                <AddIcon /> Another User
                              </a>
                            </div>
                          </div>
                        )}
                      </FieldArray>
                    </Col>
                  </Row>
                  <div
                    className="mt-3 d-flex align-items-end justify-content-end w-100 org-button"
                    style={{
                      borderTop: "1px solid #686868",
                      paddingTop: "24px",
                    }}
                  >
                    <Button
                      variant="secondary"
                      onClick={handleClose}
                      className="button-hover"
                      style={{
                        width: "90px",
                        height: "40px",
                        backgroundColor: "#252525",
                        color: "#007bff",
                        borderColor: "#007bff",
                        marginRight: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      className="button-hover"
                      style={{
                        width: "74px",
                        height: "40px",
                        backgroundColor: "#252525",
                        color: "#007bff",
                        borderColor: "#007bff",
                      }}
                    >
                      {isLoading ? (
                        <span
                          style={{
                            position: "relative",
                            left: "11px",
                          }}
                        >
                          <ThreeCircles
                            height={18}
                            width={18}
                            color="#007bff"
                          />
                        </span>
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <InvitationModal show={showSuccessModal} onClose={closeSuccessModal} />
    </>
  );
};

export default InviteNewUserModal;
