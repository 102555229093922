import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

const GenericCard = ({ title, subtitle, children, isCentered }) => {
  return (
    <Card
      style={{
        width: "100%",
        background:
          "linear-gradient(4.67deg, rgba(90, 90, 91, 0) -25.21%, #5A5A5B 130.89%)",
        border: "1px solid #636363",
        color: "white",
      }}
      className="generic-card"
    >
      <Card.Body style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
            className="card-title-container"
        >
          {title && (
            <Card.Title
              style={{
                margin: 0,
                textAlign: isCentered ? "center" : "left",
                width: isCentered ? "100%" : subtitle ? "65%" : "100%",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              {title}
            </Card.Title>
          )}
          {/* {subtitle && (
            <Card.Subtitle
              style={{ margin: 0, textAlign: "right", fontSize: "14px" }}
            >
              <a
                href={"/"}
                style={{ textDecoration: "underline", color: "inherit" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {subtitle}
              </a>
            </Card.Subtitle>
          )} */}
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          {children}
        </div>
      </Card.Body>
    </Card>
  );
};

export default GenericCard;
