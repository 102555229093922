import React, { useState } from "react";
import NoUpdate from "../empty/NoUpdate";
import "./WebUpdatesHorizontal.scss";
import { OverlayTrigger } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import { ReactComponent as InfoIcon } from "../../assets/images/circle-info.svg";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import NoData from "../empty/NoData";

const WebUpdatesHorizontal = ({ data, onSort, dataInProcess }) => {
  const navigate = useNavigate();
  const { target_id } = useParams();
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
  return data &&
    (data.constant > 0 ||
      data.changed > 0 ||
      data.removed > 0 ||
      data.new > 0) ? (
    <div className="updates-horizontal-grid">
      <div className="updates-horizontal-item">
        <div className="">New</div>
        <div  className={`updates-value  ${
            data?.new ? "underline cursor-pointer" : ""
          }`}>
          <OverlayTrigger
            placement="top"
            delay={{ show: 2000 }}
            overlay={
              <Popover id={`tooltip-New`} className="custom-popover-arrow">
                <Popover.Body className="comming-tool-body">
                  <label className="comming-text-tool">Filter Results</label>
                </Popover.Body>
              </Popover>
            }
          >
            <div onClick={() => data?.new > 0 && onSort("New")}>
              {data?.new || 0}
            </div>
          </OverlayTrigger>
        </div>
      </div>
      <div className="updates-horizontal-item">
        <div className="">Changed</div>
        <div className={`updates-value  ${
            data?.changed ? "underline cursor-pointer" : ""
          }`}>
          <OverlayTrigger
            placement="top"
            delay={{ show: 2000 }}
            overlay={
              <Popover id={`tooltip-Changed`} className="custom-popover-arrow">
                <Popover.Body className="comming-tool-body">
                  <label className="comming-text-tool">Filter Results</label>
                </Popover.Body>
              </Popover>
            }
          >
            <div onClick={() => data?.changed > 0 && onSort("Changed")}>
              {data?.changed || 0}
            </div>
          </OverlayTrigger>
        </div>
      </div>
      <div
        className="updates-horizontal-item"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="flex align-items-center">
          Removed{" "}
          {data?.removed !== 0 && (
          <OverlayTrigger
            show={showTooltip}
            placement="top"
            containerPadding={80}
            overlay={
              <Popover
                id={`tooltip-RemovedIcon`}
                className="custom-popover-arrow"
              >
                <Popover.Body
                  className="comming-tool-body"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <label className="comming-text-tool">
                    To view the removed assets in assets discovery{" "}
                    <span
                      className="text-link cursor-pointer"
                      onClick={() =>
                        navigate(`/assets-inventory/${target_id}?tab=1`)
                      }
                    >
                      click here
                    </span>
                  </label>
                </Popover.Body>
              </Popover>
            }
          >
            <InfoIcon className="cursor-pointer current-status-icon" />
          </OverlayTrigger>
          )}
        </div>
        <div className="updates-value">
          <div>{data?.removed || 0}</div>
        </div>
      </div>
      <div className="updates-horizontal-item">
        <div className="">Unchanged</div>
        <div  className={`updates-value  ${
            data?.constant ? "underline cursor-pointer" : ""
          }`}>
          <OverlayTrigger
            placement="top"
            delay={{ show: 2000 }}
            overlay={
              <Popover
                id={`tooltip-Unchanged`}
                className="custom-popover-arrow"
              >
                <Popover.Body className="comming-tool-body">
                  <label className="comming-text-tool">Filter Results</label>
                </Popover.Body>
              </Popover>
            }
          >
            <div onClick={() => data?.constant > 0 && onSort("Unchanged")}>
              {data?.constant || 0}
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  ) : (
    <div className="update-card">
      <div className="d-flex flex-column justify-content-center py-1 px-1 gap-2 h-100">
        {dataInProcess ? <NoData /> : <NoUpdate />}
      </div>
    </div>
  );
};

export default WebUpdatesHorizontal;
