import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import { Formik } from "formik";
import { Field } from "formik";
import { showToast } from "../../../../util/toasts";
import { ReactComponent as Attachment } from "../../../../assets/images/attachment-icon.svg";
import * as Yup from "yup";
import axios from "../../../../util/axios";
import { ThreeCircles } from "react-loader-spinner";
import { localInputFormatDate } from "../../../../util/formatDate";

const validationSchema = Yup.object().shape({
  organization_id: Yup.string().required("Organization is required"),
  selected_date: Yup.string().required("Date Uploaded is required"),
  report_name: Yup.string()
    .required("Report Name is required")
    .min(3, "Report Name must be at least 3 characters"),
  file: Yup.mixed().required("File is required"),
});

const AddReportsModal = ({ show, handleClose, editData, onSubmit }) => {
  const initialFormState = {
    organization_id: "",
    report_name: "",
    selected_date: "",
    file: null,
  };
  const [file, setFile] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [allOraganizations, setAllOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isContinue, setIsContinue] = useState(null);
  const fileInputRef = useRef(null);
  const [initialValues, setInitialValues] = useState(initialFormState);

  const fetchOrganization = async () => {
    try {
      const { data } = await axios.get("organizations", {
        params: { page: 1, pageSize: 1000 },
      });
      if (data?.items) {
        setAllOrganizations(data?.items);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    const file = event.dataTransfer.files[0];
    if (file && file.type === "application/pdf") {
      setFile(file);
    } else {
      showToast("Only PDF files are allowed!", "error");
    }
  };

  const handleFileChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    setIsChanged(false);
    setFieldValue("file", file);
    setFile(file);
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    setIsContinue({
      isOpen: true,
      data: values,
      resetForm,
    });
  };

  const handleContinueSubmit = async (values, resetForm) => {
    setIsLoading(true);
    onSubmit(values, resetForm, setIsLoading);
  };

  const handleModalClose = () => {
    handleClose();
    setFile(null);
    setIsContinue(null);
  };

  const handleViewFile = async (editData) => {
    if (editData?.id) {
      try {
        const response = await axios.get(
          `/cyber-services/download/${editData.id}?is_seen=false`,
          {
            responseType: "blob",
          }
        );

        if (response.data instanceof Blob) {
          const blob = response.data;
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = editData?.doc || "downloaded-file";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
        } else {
          console.error("Unexpected response data type, expected Blob.");
        }
      } catch (error) {
        console.error("Error downloading the file:", error);
      }
    } else {
      const fileURL = URL.createObjectURL(editData);
      window.open(fileURL, "_blank");
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setIsChanged(false);
  };

  const handlChangeFile = () => {
    setIsChanged(true);
  };

  const handleFileIsChange = (e) => {
    e.stopPropagation();
    setIsChanged(false);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const fetchReportDetails = async (values) => {
    try {
      const response = await axios.get(
        `/cyber-services/download/${values.id}?is_seen=true`,
        {
          responseType: "blob",
        }
      );
      const blob = response.data;
      const file = new File([blob], values?.doc || "default-filename", {
        type: blob.type,
      });

      setFile(file);
      setInitialValues({
        organization_id: values?.organization_id,
        report_name: values?.report_name,
        selected_date: values?.submit_date
          ? localInputFormatDate(values?.submit_date)
          : "",
        file: file,
      });
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  useEffect(() => {
    fetchOrganization();
  }, []);

  useEffect(() => {
    if (editData?.id) {
      fetchReportDetails(editData);
    } else {
      setFile(null);
      setInitialValues(initialFormState);
    }
  }, [editData?.id]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleModalClose}
        centered
        className={
          isContinue ? "add-pay-attention-modal" : "Add-User-modal-width"
        }
      >
        <Modal.Body
          style={{
            backgroundColor: "#252525",
            color: "#fff",
            border: "1px solid #93C2FF",
            borderRadius: "4px",
            padding: "34px",
          }}
        >
          {isContinue?.isOpen ? (
            <div className="admin-pay-attention">
              <h3>
                Pay Attention! you are About to Upload{" "}
                {isContinue?.data?.report_name} to{" "}
                {
                  allOraganizations?.filter(
                    (el) => Number(isContinue?.data?.organization_id) == el.id
                  )?.[0]?.org_name
                }
              </h3>
              <h5>
                The reports are categorized as business confidential, pay
                attention that you upload the correct report to the correct
                organization.
              </h5>
              <div className="d-flex align-items-end justify-content-end w-100 org-button mt-4">
                <Button
                  variant="secondary"
                  onClick={handleModalClose}
                  className="button-hover"
                  style={{
                    width: "90px",
                    height: "40px",
                    backgroundColor: "#252525",
                    color: "#007bff",
                    borderColor: "#007bff",
                    marginRight: "10px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  className="button-hover"
                  onClick={() =>
                    handleContinueSubmit(
                      isContinue?.data,
                      isContinue?.resetForm
                    )
                  }
                  style={{
                    width: "100px",
                    height: "40px",
                    backgroundColor: "#252525",
                    color: "#007bff",
                    borderColor: "#007bff",
                  }}
                >
                  {isLoading ? (
                    <span
                      style={{
                        position: "relative",
                        left: "25px",
                      }}
                    >
                      <ThreeCircles height={18} width={18} color="#007bff" />
                    </span>
                  ) : (
                    "Continue"
                  )}
                </Button>
              </div>
            </div>
          ) : (
            <div className="admin-security-modal">
              <h3>{editData ? "Edit" : "Add"} New Report</h3>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
                enableReinitialize={true}
              >
                {({ handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={6}>
                        <Field as="select" name="organization_id">
                          {({ field, meta }) => (
                            <FormGroup
                              className="mt-3"
                              controlId="organization_id"
                            >
                              <label style={{ color: "#B8B8B8" }}>
                                Select Organization
                              </label>
                              <FormControl
                                {...field}
                                as="select"
                                size="lg"
                                value={field.value}
                                onChange={field.onChange}
                                disabled={editData}
                                className={
                                  meta.touched && meta.error
                                    ? "is-invalid custom-select admin-input-field"
                                    : "custom-select admin-input-field"
                                }
                                style={{
                                  color:
                                    field.value === "" ? "#6F6F6F" : "#fff",
                                }}
                              >
                                <option value="">Select Organization</option>
                                {allOraganizations?.map((el) => (
                                  <option value={el.id}>{el?.org_name}</option>
                                ))}
                              </FormControl>
                              {meta.touched && meta.error && (
                                <div className="invalid-feedback">
                                  {meta.error}
                                </div>
                              )}
                            </FormGroup>
                          )}
                        </Field>
                      </Col>
                      <Col md={6}>
                        <Field name="report_name">
                          {({ field, meta }) => (
                            <FormGroup className="mt-3" controlId="report_name">
                              <label style={{ color: "#B8B8B8" }}>
                                Report Name{" "}
                              </label>
                              <FormControl
                                type="text"
                                size="lg"
                                placeholder="Enter Name"
                                className={`placeholder-custom ${
                                  meta.touched && meta.error ? "is-invalid" : ""
                                }`}
                                style={{
                                  color: "#fff",
                                  fontSize: "16px",
                                  height: "50px",
                                  borderColor: "#FFFFFF",
                                  margin: "8px 0px",
                                  borderRadius: 5,
                                }}
                                {...field}
                              />
                              {meta.touched && meta.error && (
                                <div className="invalid-feedback">
                                  {meta.error}
                                </div>
                              )}
                            </FormGroup>
                          )}
                        </Field>
                      </Col>
                      <Col md={12}>
                        <Field name="selected_date">
                          {({ field, meta }) => (
                            <FormGroup
                              className="mt-3"
                              controlId="selected_date"
                            >
                              <label style={{ color: "#B8B8B8" }}>
                                Date Uploaded
                              </label>
                              <FormControl
                                {...field}
                                type="date"
                                size="lg"
                                placeholder="Enter Name"
                                className={`placeholder-custom ${
                                  meta.touched && meta.error ? "is-invalid" : ""
                                }`}
                                style={{
                                  color: "#fff",
                                  fontSize: "16px",
                                  height: "50px",
                                  borderColor: "#FFFFFF",
                                  margin: "8px 0px",
                                  borderRadius: 5,
                                }}
                                onClick={(e) => {
                                  e.target.showPicker();
                                }}
                              />
                              {meta.touched && meta.error && (
                                <div className="invalid-feedback">
                                  {meta.error}
                                </div>
                              )}
                            </FormGroup>
                          )}
                        </Field>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mt-4" controlId="file">
                          <label>Upload PDF File</label>
                          <>
                            <Field name="file">
                              {({ form }) => {
                                return (
                                  <>
                                    {file && !isChanged ? (
                                      <div>
                                        <div className="flex align-items-center gap-2 mt-2">
                                          <Attachment />
                                          <span
                                            style={{
                                              color: "#9FA2A7",
                                            }}
                                          >
                                            {file.name}
                                          </span>

                                          <div
                                            className="button-link"
                                            onClick={() =>
                                              handleViewFile(editData || file)
                                            }
                                          >
                                            View File
                                          </div>
                                          <div
                                            className="button-link"
                                            onClick={handlChangeFile}
                                          >
                                            Change File
                                          </div>
                                          <div
                                            className="button-link"
                                            onClick={() => {
                                              handleRemoveFile();
                                              form.setFieldValue("file", null);
                                            }}
                                          >
                                            Remove File
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        className={`upload-container ${
                                          dragActive ? "drag-active" : ""
                                        }`}
                                        onDragOver={handleDragOver}
                                        onDragLeave={handleDragLeave}
                                        onDrop={(e) => {
                                          handleDrop(e);
                                          form.setFieldValue(
                                            "file",
                                            e.dataTransfer.files[0]
                                          );
                                        }}
                                        onClick={handleClick}
                                      >
                                        <label className="cursor-pointer">
                                          <div className="file-heading cursor-pointer">
                                            Upload File
                                          </div>
                                          {!isChanged ? (
                                            <div
                                              className="cursor-pointer"
                                              style={{
                                                color: "#9FA2A7",
                                              }}
                                            >
                                              You can click here to add or drop
                                              a file
                                            </div>
                                          ) : (
                                            <div className="cursor-pointer">
                                              You can click here to add or drop
                                              a file to replace{" "}
                                              <b>{file?.name}</b>{" "}
                                              <span
                                                style={{
                                                  color: "#398FFE",
                                                  textDecoration: "underline",
                                                  cursor: "pointer",
                                                }}
                                                onClick={handleFileIsChange}
                                              >
                                                Cancel
                                              </span>
                                            </div>
                                          )}
                                        </label>
                                        <input
                                          ref={fileInputRef}
                                          type="file"
                                          onChange={(e) =>
                                            handleFileChange(
                                              e,
                                              form.setFieldValue
                                            )
                                          }
                                          style={{ display: "none" }}
                                          accept=".pdf"
                                        />
                                      </div>
                                    )}
                                    {form.errors.file && form.touched.file && (
                                      <div
                                        className="invalid-feedback"
                                        style={{
                                          display: "block",
                                          marginTop: 5,
                                        }}
                                      >
                                        {form.errors.file}
                                      </div>
                                    )}
                                  </>
                                );
                              }}
                            </Field>
                          </>
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="d-flex align-items-end justify-content-end w-100 org-button mt-5">
                      <Button
                        variant="secondary"
                        onClick={handleModalClose}
                        className="button-hover"
                        style={{
                          width: "90px",
                          height: "40px",
                          backgroundColor: "#252525",
                          color: "#007bff",
                          borderColor: "#007bff",
                          marginRight: "10px",
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        className="button-hover"
                        style={{
                          width: "74px",
                          height: "40px",
                          backgroundColor: "#252525",
                          color: "#007bff",
                          borderColor: "#007bff",
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddReportsModal;
