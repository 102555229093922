import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

const DeleteModal = ({
  showModal,
  handleClose,
  handleConfirmDelete,
  title,
  subTitle,
  showCheckbox = false,
  isDelete=true
}) => {
  return (
    <Modal show={showModal} centered onHide={handleClose} className="w-70">
      <Modal.Header className="admin-target-modal">
        <div className="d-flex flex-column mt-2 align-items-center justify-content-center w-100">
          <Modal.Title>
            <h3>{title}</h3>
            <h5>{subTitle}</h5>
          </Modal.Title>
          {showCheckbox && (
            <div className="mt-3 mb-4 admin-checkbox admin-checkbox-disabled">
              <Form.Check
                type="checkbox"
                checked
                label="No active users in the organization."
                readOnly
                className="text-white"
              />
              <Form.Check
                type="checkbox"
                checked
                label="No active scans in the organization."
                readOnly
                className="text-white"
              />
            </div>
          )}

          <div className="Admin-button mt-4 mb-3">
            <div>
              <Button className="target-button w-100" onClick={handleClose}>
                Cancel
              </Button>
            </div>
            <div>
              <Button
                className="target-button w-100"
                onClick={handleConfirmDelete}
              >
                Yes, {isDelete ? "Delete" : "Scan"}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Header>
    </Modal>
  );
};

export default DeleteModal;
